var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
export var getEmptyTalentReviewLabelAxisEdit = function () { return ({
    axisLabel: "",
    lowLabel: "",
    middleLabel: "",
    highLabel: "",
    lowDescription: "",
    middleDescription: "",
    highDescription: "",
}); };
export var getEmptyTalentReviewLabelBoxEdit = function () { return ({
    name: "",
    boxDescription: "",
}); };
var getEmptyTalentReviewLabelEdit = function () { return ({
    yAxis: getEmptyTalentReviewLabelAxisEdit(),
    xAxis: getEmptyTalentReviewLabelAxisEdit(),
    boxes: new Array(9).fill(getEmptyTalentReviewLabelBoxEdit()),
}); };
/* eslint-disable  @typescript-eslint/no-empty-function */
export var GlobalStateContext = React.createContext({
    getPageStates: function () { },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    clearPageStates: function () { },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    updatePageStates: function (changes) { },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    setHoldUrl: function (url) { },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    getHoldUrl: function () { return undefined; },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    clearTalentReviewLabelEdits: function () { return undefined; },
    setTalentReviewLabelEdits: function (arg) { return undefined; },
    getTalentReviewLabelEdits: function () { return getEmptyTalentReviewLabelEdit(); },
    setTalentReviewVersion: function (arg0) { return undefined; },
    talentReviewVersion: null,
    /* eslint-enable @typescript-eslint/no-unused-vars */
});
export var GlobalStateProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState({}), pageStates = _b[0], setPageStates = _b[1];
    var _c = React.useState(), holdUrl = _c[0], setHoldUrl = _c[1];
    var _d = React.useState(null), talentReviewVersion = _d[0], setTalentReviewVersion = _d[1];
    var _e = React.useState(getEmptyTalentReviewLabelEdit()), talentReviewLabelEdits = _e[0], setTalentReviewLabelEdits = _e[1];
    var value = React.useMemo(function () {
        return {
            getPageStates: function () { return pageStates; },
            clearPageStates: function () {
                setPageStates({});
            },
            setHoldUrl: setHoldUrl,
            getHoldUrl: function () { return holdUrl; },
            updatePageStates: function (changes) {
                setPageStates(function (previous) { return (__assign(__assign({}, previous), changes)); });
            },
            clearTalentReviewLabelEdits: function () {
                setTalentReviewLabelEdits(getEmptyTalentReviewLabelEdit());
            },
            setTalentReviewLabelEdits: setTalentReviewLabelEdits,
            getTalentReviewLabelEdits: function () { return talentReviewLabelEdits; },
            setTalentReviewVersion: setTalentReviewVersion,
            talentReviewVersion: talentReviewVersion,
        };
    }, [
        talentReviewVersion,
        pageStates,
        (holdUrl === null || holdUrl === void 0 ? void 0 : holdUrl.urls) || [],
        holdUrl === null || holdUrl === void 0 ? void 0 : holdUrl.onUrl,
        talentReviewLabelEdits,
    ]);
    return (_jsx(GlobalStateContext.Provider, __assign({ value: value }, { children: children })));
};
