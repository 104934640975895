var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { Box, Typography, Divider, styled } from "@mui/material";
import { Card, Form, Icon, IconName, Stack, TextInput, TextInputWidth, } from "@eightfold.ai/octuple";
import Button from "../Button/Button";
import Dialog from "../Dialog/Dialog";
import FormRequired from "../FormRequired/FormRequired";
import { getClientId, useGetAvailableNineBoxes, deleteNineBoxQuery, createNineBoxQuery, } from "../../store/api/core";
import SpinnerAndErrorer from "../SpinnerAndErrorer";
import { useNavigate } from "../../hooks/navigate";
import { getRoute } from "../../routes/getAllRoutes";
import trStyles from "./TalentReview.module.scss";
var Container = styled(Box)({
    padding: "32px",
});
var MainTitle = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        fontFamily: theme.typography.fontFamily + " Heavy",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "28px",
    });
});
var SecondContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "32px",
});
var DescriptionText = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        fontFamily: theme.typography.fontFamily + " Book",
        color: "#4F5666",
        fontWeight: "400",
        fontSize: "14px",
        LineHeight: "20px",
    });
});
var SettingIconContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "16px",
});
var SecondPartContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
});
var ImageWrapper = styled(Box)({
    margin: "32px 0",
    width: "135px",
    height: "135px",
});
var CreateFirstBoxText = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        fontFamily: theme.typography.fontFamily + " Heavy",
        color: "#1A212E",
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "28px",
    });
});
var CreateNineBoxDialog = function (props) {
    var form = Form.useForm()[0];
    var createNineBox = createNineBoxQuery({
        invalidates: [{ queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] }],
    });
    var navigate = useNavigate();
    var _a = useState(""), formError = _a[0], setFormError = _a[1];
    var _b = useState(""), errorClass = _b[0], setErrorClass = _b[1];
    var onDoneClick = function () {
        form.submit();
    };
    var onFormFinish = function (formValues) {
        createNineBox.mutate({
            boxName: formValues.name,
            clientId: getClientId(),
        }, {
            onSuccess: function (data) {
                navigate(getRoute("TalentReviewNineBox").replace(":id", data.data.id));
                props.onClose();
            },
            onError: function (data) {
                var _a;
                if (data.message == "400") {
                    setFormError((_a = data.cause) === null || _a === void 0 ? void 0 : _a.boxName[0]);
                    setErrorClass(trStyles.formError);
                }
            },
        });
    };
    var CreateNineBoxForm = function () {
        return (_jsxs(_Fragment, { children: [_jsx(FormRequired, {}), _jsxs(Form, __assign({ form: form, layout: "vertical", autoComplete: "off", onFinish: onFormFinish }, { children: [_jsx(Form.Item, __assign({ label: "Name", name: "name", rules: [
                                { required: true, message: "9 Box name is required" },
                                {
                                    type: "string",
                                    max: 30,
                                    message: "9 Box name cannot be more than ${max} characters",
                                },
                            ], style: { marginBottom: "0" } }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill, classNames: errorClass }) })), createNineBox.isError && (_jsx("div", __assign({ role: "alert", className: "form-item-explain-error ".concat(errorClass) }, { children: formError })))] }))] }));
    };
    return (_jsx(Dialog, { header: "Create a new 9 Box", body: _jsx(CreateNineBoxForm, {}), buttons: {
            okButtonProps: {
                text: "Done",
                onClick: onDoneClick,
                loading: createNineBox.isPending,
            },
            cancelButtonProps: {
                text: "Cancel",
            },
        }, visible: props.visible, onClose: props.onClose, firstFocusableSelector: "input" }));
};
var NoNineBoxes = function (props) { return (_jsxs(SecondPartContainer, { children: [_jsx(ImageWrapper, { children: _jsx("img", { src: getRoute("EmptyIcon"), width: "100%" }) }), _jsx(Box, __assign({ sx: { marginBottom: "32px" } }, { children: _jsx(CreateFirstBoxText, { children: "You don't have any 9 Boxes. Create your first one." }) })), _jsx(Box, { children: _jsx(Button, { text: "Create 9 Box", onClick: function () {
                    props.setCreateDialogVisible(true);
                } }) })] })); };
var BoxLine = function (_a) {
    var path = _a.path, description = _a.description, text = _a.text;
    return (_jsxs(Stack, __assign({ direction: "horizontal", flexGap: "xxs", style: {
            color: "#69717F",
            fontFamily: "Avenir Medium",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
        } }, { children: [_jsx(Icon, { path: path }), _jsx("div", __assign({ style: { marginLeft: "4px", paddingTop: "4px" } }, { children: description })), _jsx("div", __assign({ style: { color: "#1A212E", paddingTop: "4px" } }, { children: text }))] })));
};
var BoxNameLine = function (_a) {
    var name = _a.name, boxId = _a.boxId;
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var deleteBoxId = deleteNineBoxQuery({
        substitutions: { boxId: boxId },
        invalidates: [{ queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] }],
    });
    var handleDeleteBox = function () {
        deleteBoxId.mutate({}, { onSuccess: function () { return setVisible(false); } });
    };
    var props = {
        header: "Are you sure you want to delete this 9 Box? This action cannot be undone.",
        body: "",
        buttons: {
            okButtonProps: {
                text: "Confirm and Delete",
                onClick: handleDeleteBox,
                loading: deleteBoxId.isPending,
            },
            cancelButtonProps: {
                text: "Cancel",
            },
        },
    };
    return (_jsxs(Stack, __assign({ justify: "space-between", fullWidth: true, "data-testid": "nine-box-".concat(boxId) }, { children: [_jsx("div", __assign({ style: {
                    color: "#1A212E",
                    fontFamily: "Avenir Medium",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: 400,
                } }, { children: name })), _jsx("div", __assign({ "data-testid": "nine-box-delete", style: {
                    width: "36px",
                    height: "36px",
                    backgroundColor: "#F6F7F8",
                    padding: "8px",
                    borderRadius: "24px",
                    cursor: "pointer",
                }, onClick: function () { return setVisible(true); } }, { children: _jsx(Icon, { path: IconName.mdiDelete }) })), visible &&
                createPortal(_jsx(Dialog, __assign({}, props, { visible: visible, onClose: function () { return setVisible(false); } })), document.body)] })));
};
var NineBoxSummary = function (_a) {
    var userFullName = _a.createUser.userFullName, id = _a.id, boxName = _a.boxName, lastUpdatedTs = _a.lastUpdatedTs, boxId = _a.boxId;
    var navigate = useNavigate();
    return (_jsx(Card, __assign({ bordered: true, height: 220, style: {
            width: "33%",
            minWidth: "440px",
            padding: "20px 24px",
        } }, { children: _jsxs(Stack, __assign({ direction: "vertical", flexGap: "l", fullWidth: true }, { children: [_jsx(BoxNameLine, { name: boxName, boxId: boxId }), _jsxs(Stack, __assign({ direction: "vertical", flexGap: "xxs" }, { children: [_jsx(BoxLine, { path: IconName.mdiAccountOutline, description: "Created By:", text: userFullName }), _jsx(BoxLine, { path: IconName.mdiCalendarBlankOutline, description: "Last Edited:", text: lastUpdatedTs === null || lastUpdatedTs === void 0 ? void 0 : lastUpdatedTs.toLocaleString(undefined, {
                                month: "short",
                                day: "2-digit",
                                year: "numeric",
                            }) })] })), _jsx(Button, { text: "View 9 Box", style: {
                        backgroundColor: "#B0F3FE",
                        width: "100px",
                        color: "#054D7B",
                    }, onClick: function () {
                        return navigate(getRoute("TalentReviewNineBox").replace(":id", id));
                    } })] })) })));
};
var NineBoxSummaries = function (_a) {
    var children = _a.children;
    return (_jsx(Stack, __assign({ "data-testid": "NineBoxes", direction: "horizontal", wrap: "wrap", flexGap: "l", style: { marginTop: "32px" }, justify: "flex-start", align: "center" }, { children: children })));
};
var TalentReview = function () {
    var _a;
    var _b = React.useState(false), createDialogVisible = _b[0], setCreateDialogVisible = _b[1];
    var nineBoxes = useGetAvailableNineBoxes({});
    var cards = (((_a = nineBoxes === null || nineBoxes === void 0 ? void 0 : nineBoxes.data) === null || _a === void 0 ? void 0 : _a.data) || []).map(function (box) { return _createElement(NineBoxSummary, __assign({}, box, { key: box.id })); });
    return (_jsxs(Container, __assign({ "data-testid": "TalentReview" }, { children: [_jsx(Box, __assign({ sx: { marginBottom: "8px" } }, { children: _jsx(MainTitle, { children: "Talent Review" }) })), _jsxs(SecondContainer, { children: [_jsx(Box, __assign({ sx: { width: "68%" } }, { children: _jsx(DescriptionText, { children: "Talent review provides a structured, objective process by which to evaluate and generate a holistic view of your talent, enabling informed decisions about development, succession planning, and strategic talent management. To get started, please select an existing talent review or initiate a new review." }) })), _jsxs(SettingIconContainer, { children: [_jsx(Box, { sx: { cursor: "pointer" } }), _jsx(Box, { children: _jsx(Button, { text: "Create new", onClick: function () { return setCreateDialogVisible(true); } }) })] })] }), _jsx(Divider, {}), _jsx(SpinnerAndErrorer, __assign({ conditions: [nineBoxes] }, { children: cards.length == 0 ? (_jsx(NoNineBoxes, { setCreateDialogVisible: setCreateDialogVisible })) : (_jsx(NineBoxSummaries, { children: cards })) })), createDialogVisible &&
                createPortal(_jsx(CreateNineBoxDialog, { visible: createDialogVisible, onClose: function () { return setCreateDialogVisible(false); } }), document.body)] })));
};
export default TalentReview;
