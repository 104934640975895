var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useRef } from "react";
import { FilterContext } from "../../providers/FilterProvider";
import { useGetRoleProfileListQuery } from "../../store/api/core";
import { EndpointPaginatingTable, ValidSortDirections, } from "../Table/Table";
import styles from "./Table.module.scss";
import { getDisplayableTimestamp } from "../../common/datetime";
import { LookupField, useLookupField } from "../LookupField/LookupField";
import { Link } from "react-router-dom";
import { useEndpointPagination } from "../../hooks/endpointPagination";
var Cell = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ className: styles.cell }, { children: children })));
};
export var Table = function (_a) {
    var _b;
    var clientId = _a.clientId, route = _a.route;
    var _c = useContext(FilterContext), getFilters = _c.getFilters, setFilters = _c.setFilters;
    var filters = getFilters();
    if (filters.clientId !== clientId) {
        setFilters({ clientId: clientId });
    }
    var matchedStatementsColumn = useRef();
    if (((_b = filters.statement) === null || _b === void 0 ? void 0 : _b.length) && matchedStatementsColumn.current) {
        matchedStatementsColumn.current();
    }
    var lookups = useLookupField({ clientId: clientId });
    var columnDescriptions = [
        {
            header: "Role",
            endpointKey: "name",
            defaultSortOrderKey: "name",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var _b;
                var _c = _a.data, name = _c.name, roleProfileId = _c.roleProfileId;
                return (_jsx(Cell, { children: _jsx(Link, __assign({ className: styles.cell, to: (_b = route === null || route === void 0 ? void 0 : route.replace(":roleProfileId", roleProfileId)) === null || _b === void 0 ? void 0 : _b.replace(":clientId", clientId) }, { children: name })) }));
            },
            valueTransformer: function (row) { return ({
                sort: row === null || row === void 0 ? void 0 : row.name,
                data: { name: row === null || row === void 0 ? void 0 : row.name, roleProfileId: row === null || row === void 0 ? void 0 : row.id },
            }); },
        },
        {
            header: "Function",
            defaultSortDirection: ValidSortDirections.ASC,
            isUnsortable: true,
            formatter: function (_a) {
                var data = _a.data;
                return (_jsx(Cell, { children: _jsx(LookupField, { code: data, clientId: clientId }) }));
            },
            valueTransformer: function (row) { return ({
                sort: lookups[row === null || row === void 0 ? void 0 : row.functionCode],
                data: row === null || row === void 0 ? void 0 : row.functionCode,
            }); },
        },
        {
            header: "Sub-Function",
            isUnsortable: true,
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return (_jsx(Cell, { children: _jsx(LookupField, { code: data, clientId: clientId }) }));
            },
            valueTransformer: function (row) { return ({
                sort: lookups[row === null || row === void 0 ? void 0 : row.subFunctionCode],
                data: row === null || row === void 0 ? void 0 : row.subFunctionCode,
            }); },
        },
        {
            header: "Level",
            endpointKey: "levelCode",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return (_jsx(Cell, { children: _jsx(LookupField, { code: data, clientId: clientId }) }));
            },
            valueTransformer: function (row) { return ({
                sort: lookups[row === null || row === void 0 ? void 0 : row.levelCode],
                data: row === null || row === void 0 ? void 0 : row.levelCode,
            }); },
        },
        {
            header: "Last Updated",
            endpointKey: "lastUpdatedTs",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(Cell, { children: data });
            },
            valueTransformer: function (row) { return ({
                sort: row === null || row === void 0 ? void 0 : row.lastUpdatedTs,
                data: getDisplayableTimestamp(row === null || row === void 0 ? void 0 : row.lastUpdatedTs),
            }); },
        },
        {
            sortOn: matchedStatementsColumn,
            header: "# selected requirements that match to role",
            endpointKey: "matchedStatementIds",
            defaultSortOrderKey: "matchedStatementIds",
            defaultSortDirection: ValidSortDirections.DESC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(Cell, { children: data });
            },
            valueTransformer: function (row) {
                var _a, _b;
                return ({
                    sort: row === null || row === void 0 ? void 0 : row.matchedStatementIds,
                    data: ((row === null || row === void 0 ? void 0 : row.matchedStatementIds) && ((_a = filters.statement) === null || _a === void 0 ? void 0 : _a.length)) || 0
                        ? "".concat(row.matchedStatementIds.length, " of ").concat(((_b = filters.statement) === null || _b === void 0 ? void 0 : _b.length) || 0)
                        : "--",
                });
            },
        },
    ];
    var useData = useEndpointPagination({
        useHook: useGetRoleProfileListQuery,
        parameters: { clientId: clientId, filters: getFilters() },
    });
    var showingInfo = function (_a) {
        var count = _a.count, total = _a.total;
        return count && total ? (_jsx("div", __assign({ className: styles.showingInfo }, { children: "Showing ".concat(count, " of ").concat(total, " result").concat(total == 1 ? "" : "s") }))) : undefined;
    };
    return (_jsx(EndpointPaginatingTable, { title: "", showingInfo: showingInfo, defaultSortKeyOrder: ["name"], columnDescriptions: columnDescriptions, useData: useData }));
};
