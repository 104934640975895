var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from "@mui/material";
import React from "react";
import { ScoreTitles } from "../../common/action";
import { sortActionables } from "../ActionCircle/ActionCircle";
import AdditionalRiskCard, { Breakdown, LeaderCount, } from "../AdditionalRiskCard/AdditionalRiskCard";
import { getRoute } from "../../routes/getAllRoutes";
import { useNavigate } from "../../hooks/navigate";
import { createSearchParams } from "react-router-dom";
var asScoreData = function (payload) {
    var _a, _b, _c, _d;
    return ({
        overall: payload === null || payload === void 0 ? void 0 : payload.state,
        capability: (_a = payload === null || payload === void 0 ? void 0 : payload.capability) === null || _a === void 0 ? void 0 : _a.state,
        potential: (_b = payload === null || payload === void 0 ? void 0 : payload.potential) === null || _b === void 0 ? void 0 : _b.state,
        functional: (_c = payload === null || payload === void 0 ? void 0 : payload.functional) === null || _c === void 0 ? void 0 : _c.state,
        organizational: (_d = payload === null || payload === void 0 ? void 0 : payload.organizational) === null || _d === void 0 ? void 0 : _d.state,
        leaderCount: payload === null || payload === void 0 ? void 0 : payload.leaders,
    });
};
var ClickableBreakdown = function (_a) {
    var action = _a.action, text = _a.text, clickUrl = _a.clickUrl;
    var navigate = useNavigate();
    return (_jsx(Breakdown, { action: action, text: text, onClick: function () {
            return console.log("clicking", { clickUrl: clickUrl }) || navigate(clickUrl);
        } }));
};
var getTeamRoute = function (assessment, code, location) {
    return "".concat(getRoute(assessment)
        .replace(":role", !code ? "all" : code[0] == "L" ? "all" : code)
        .replace(":level", !code ? "all" : code[0] == "L" ? code : "all")).concat(!location ? "" : "?".concat(createSearchParams({ location: location })));
};
export var getTeamRiskCardProps = function (teams, headerTeamsTitle) {
    return {
        title: headerTeamsTitle,
        emptyMessage: "There are no high risk teams to highlight",
        rows: (teams || []).map(function (_a) {
            var title = _a.title, leader = _a.leader, onClick = _a.onClick, code = _a.code, location = _a.location;
            var _b = asScoreData(leader), capability = _b.capability, organizational = _b.organizational, functional = _b.functional, potential = _b.potential;
            return {
                title: title,
                onClick: onClick,
                rows: __spreadArray([
                    _jsx(LeaderCount, { count: leader === null || leader === void 0 ? void 0 : leader.leaders })
                ], sortActionables([
                    {
                        action: capability,
                        text: ScoreTitles.capability,
                        clickUrl: getTeamRoute("Capability", code, location),
                    },
                    {
                        action: functional,
                        text: ScoreTitles.functional,
                        clickUrl: getTeamRoute("FunctionalImpact", code, location),
                    },
                    {
                        action: organizational,
                        text: ScoreTitles.organizational,
                        clickUrl: getTeamRoute("OrganizationalImpact", code, location),
                    },
                    {
                        action: potential,
                        text: ScoreTitles.potential,
                        clickUrl: getTeamRoute("Potential", code, location),
                    },
                ]).map(function (action) { return _jsx(ClickableBreakdown, __assign({}, action)); }), true),
            };
        }),
    };
};
var getIndividualRoute = function (assessment, leaderId, role, level, location) {
    return "".concat(getRoute(assessment)
        .replace(":role", encodeURIComponent(role))
        .replace(":level", encodeURIComponent(level)), "?leaderId=").concat(encodeURIComponent(leaderId)).concat(!location ? "" : "&".concat(createSearchParams({ location: location })));
};
export var getIndividualRiskCardProps = function (individuals, headerIndividualTitle) {
    return {
        title: headerIndividualTitle,
        emptyMessage: "There are no leaders with development opportunities to highlight",
        rows: (individuals || []).map(function (_a) {
            var title = _a.title, leaderId = _a.leaderId, role = _a.role, level = _a.level, onClick = _a.onClick, location = _a.location, leader = _a.leader;
            var _b = asScoreData(leader), capability = _b.capability, organizational = _b.organizational, functional = _b.functional, potential = _b.potential;
            return {
                title: title,
                onClick: onClick,
                rows: sortActionables([
                    {
                        action: capability,
                        text: ScoreTitles.capability,
                        clickUrl: getIndividualRoute("Capability", leaderId, role, level, location),
                    },
                    {
                        action: functional,
                        text: ScoreTitles.functional,
                        clickUrl: getIndividualRoute("FunctionalImpact", leaderId, role, level, location),
                    },
                    {
                        action: organizational,
                        text: ScoreTitles.organizational,
                        clickUrl: getIndividualRoute("OrganizationalImpact", leaderId, role, level, location),
                    },
                    {
                        action: potential,
                        text: ScoreTitles.potential,
                        clickUrl: getIndividualRoute("Potential", leaderId, role, level, location),
                    },
                ]).map(function (action) { return _jsx(ClickableBreakdown, __assign({}, action)); }),
            };
        }),
    };
};
var AdditionalRiskCards = function (_a) {
    var teams = _a.teams, individuals = _a.individuals;
    var Container = styled(Box)({
        display: "flex",
        width: "100%",
        alignItems: "stretch",
        marginTop: "24px",
    });
    var Space = styled(Box)({
        width: "20%",
        border: "none",
    });
    return (_jsxs(Container, __assign({ "data-testid": "AdditionalRiskCards" }, { children: [_jsx(Space, {}), _jsx(AdditionalRiskCard, __assign({ sx: { flex: 1 } }, getTeamRiskCardProps(teams))), _jsx(AdditionalRiskCard, __assign({ sx: { flex: 1 } }, getIndividualRiskCardProps(individuals)))] })));
};
export default AdditionalRiskCards;
