import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { FilterContext } from "../../providers/FilterProvider";
import ScoreCard from "../ScoreCard/ScoreCard";
export function useScoreCardComponents(_a) {
    var _b, _c, _d, _e;
    var textGetter = _a.textGetter, scoreInfos = _a.scoreInfos, useScores = _a.useScores;
    var _f = useContext(FilterContext), updateFilters = _f.updateFilters, getFilters = _f.getFilters;
    var scores = useScores({ filters: getFilters() });
    var selectedScoreType = (_b = getFilters()) === null || _b === void 0 ? void 0 : _b.scoreType;
    if (!(scores === null || scores === void 0 ? void 0 : scores.data)) {
        return [];
    }
    var metrics = (((_c = scores === null || scores === void 0 ? void 0 : scores.data) === null || _c === void 0 ? void 0 : _c.data) || {}).metrics;
    var totalCount = ((_e = (_d = scores === null || scores === void 0 ? void 0 : scores.data) === null || _d === void 0 ? void 0 : _d.data.leaders) === null || _e === void 0 ? void 0 : _e.length) || 0;
    return scoreInfos.map(function (_a) {
        var tooltip = _a.tooltip, title = _a.title, scoreType = _a.scoreType;
        return (_jsx(ScoreCard, { title: title || "", score: textGetter(metrics[scoreType].count, metrics[scoreType].value, totalCount) || "", action: metrics[scoreType].state, tooltip: tooltip || "", selected: scoreType == selectedScoreType, onClick: function () {
                updateFilters({
                    scoreType: selectedScoreType != scoreType ? scoreType : null,
                });
            } }, Math.random()));
    });
}
