var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ActBigDark from "./ActBigDark";
import ActBigLight from "./ActBigLight";
import MaintainBigDark from "./MaintainBigDark";
import MaintainBigLight from "./MaintainBigLight";
import WatchBigDark from "./WatchBigDark";
import WatchBigLight from "./WatchBigLight";
import NodataBigDark from "./NodataBigDark";
import NodataBigLight from "./NodataBigLight";
import ActSmall from "./ActSmall";
import MaintainSmall from "./MaintainSmall";
import WatchSmall from "./WatchSmall";
var bigIcons = {
    dark: {
        act: _jsx(ActBigDark, {}),
        watch: _jsx(WatchBigDark, {}),
        maintain: _jsx(MaintainBigDark, {}),
        nodata: _jsx(NodataBigDark, {}),
    },
    light: {
        act: _jsx(ActBigLight, {}),
        watch: _jsx(WatchBigLight, {}),
        maintain: _jsx(MaintainBigLight, {}),
        nodata: _jsx(NodataBigLight, {}),
    },
};
var comparatorWeights = {
    act: 0,
    watch: 1,
    maintain: 2,
    nodata: 3,
};
function sortActionables(input) {
    var sortable = __spreadArray([], input, true);
    sortable.sort(function (a, b) {
        return comparatorWeights[a.action] == comparatorWeights[b.action]
            ? 0
            : comparatorWeights[a.action] > comparatorWeights[b.action]
                ? 1
                : -1;
    });
    return sortable;
}
export { sortActionables };
var ActionCircle = function (_a) {
    var action = _a.action, size = _a.size, tone = _a.tone;
    if (size === "small") {
        return action === "act" ? (_jsx(ActSmall, {})) : action === "watch" ? (_jsx(WatchSmall, {})) : (_jsx(MaintainSmall, {}));
    }
    return bigIcons[tone || "dark"][action];
};
export default ActionCircle;
