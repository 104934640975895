// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D_hF_rkwXrGkSd8R5qgE {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 8px 32px;
}

.yJsn8QzwCXhe28zx5wxA {
  font-weight: 800;
  font-size: 20px;
  padding-bottom: 8px;
  line-height: 28px;
  font-family: Avenir Heavy, sans-serif;
}

.gWyR8ahy2ZDJ4XzZ3M8i {
  font-weight: 350;
  font-size: 18px;
  line-height: 26px;
  font-family: Avenir Book, sans-serif;
  padding: 0 0 16px 32px;
}

.JGtz24FCca_VvRuTXT3F {
  display: flex;
  gap: 8px;
  float: right;
}

.uAjei2TafyoUeBYf3mvS {
  width: 25px;
  height: 4px;
  border-radius: 4px;
  border: 1px solid var(--blue-color-70);
  background-color: var(--blue-color-60);
  margin-top: 8px;
}

.OoH0u9IwjQ840XwcesAp {
  font-weight: 350;
  font-size: 15px;
  line-height: 20px;
  font-family: Avenir Book, sans-serif;
}

.JwNbx6iMJmlL7UQTTokt {
  display: flex;
  gap: 16px;
}

.zu_2wfxV5eJ1SWfGPfT8 {
  display: flex;
  justify-content: "space-between";
  gap: 16px;
  font-size: 14px;
  color: var(--grey-color-70);
  font-family: Avenir Book, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/SuccessProfilesSkillGroup/SkillGroupHeading/SuccessProfile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,2BAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,qCAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,sCAAA;EACA,sCAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,oCAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,gCAAA;EACA,SAAA;EACA,eAAA;EACA,2BAAA;EACA,oCAAA;AACF","sourcesContent":[".skillGroupLayout {\n  display: flex;\n  justify-content: space-between;\n  padding: 32px 32px 8px 32px;\n}\n\n.skillGroupTitle {\n  font-weight: 800;\n  font-size: 20px;\n  padding-bottom: 8px;\n  line-height: 28px;\n  font-family: Avenir Heavy, sans-serif;\n}\n\n.skillGroupDescription {\n  font-weight: 350;\n  font-size: 18px;\n  line-height: 26px;\n  font-family: Avenir Book, sans-serif;\n  padding: 0 0 16px 32px;\n}\n\n.targetHeadingLayout {\n  display: flex;\n  gap: 8px;\n  float: right;\n}\n\n.targetRectangle {\n  width: 25px;\n  height: 4px;\n  border-radius: 4px;\n  border: 1px solid var(--blue-color-70);\n  background-color: var(--blue-color-60);\n  margin-top: 8px;\n}\n\n.targetHeading {\n  font-weight: 350;\n  font-size: 15px;\n  line-height: 20px;\n  font-family: Avenir Book, sans-serif;\n}\n\n.successProfileLegendAndTitleContainer {\n  display: flex;\n  gap: 16px;\n}\n\n.successProfileLegend {\n  display: flex;\n  justify-content: \"space-between\";\n  gap: 16px;\n  font-size: 14px;\n  color: var(--grey-color-70);\n  font-family: Avenir Book, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skillGroupLayout": `D_hF_rkwXrGkSd8R5qgE`,
	"skillGroupTitle": `yJsn8QzwCXhe28zx5wxA`,
	"skillGroupDescription": `gWyR8ahy2ZDJ4XzZ3M8i`,
	"targetHeadingLayout": `JGtz24FCca_VvRuTXT3F`,
	"targetRectangle": `uAjei2TafyoUeBYf3mvS`,
	"targetHeading": `OoH0u9IwjQ840XwcesAp`,
	"successProfileLegendAndTitleContainer": `JwNbx6iMJmlL7UQTTokt`,
	"successProfileLegend": `zu_2wfxV5eJ1SWfGPfT8`
};
export default ___CSS_LOADER_EXPORT___;
