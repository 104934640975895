var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { ButtonIconAlign, ButtonVariant, IconName, Panel, PanelSize, Stack, } from "@eightfold.ai/octuple";
import { DropdownButton } from "../Dropdown/Dropdown";
import { NineBoxFilter } from "../NineBoxFilter/NineBoxFilter";
import styles from "./Additional.module.scss";
import { AvailableFilters } from "./Filters";
import { MultiSelectFilter } from "./MultiSelectFilter";
import { useGetDataFiltersQuery } from "../../store/api/core";
import { usePermissions } from "../../hooks/permissions";
import { haveTalentReviewPages } from "../../common/featureFlags";
var getFilterSet = function (filters) {
    return [
        {
            key: AvailableFilters.RetentionRisk,
            implementation: (_jsx(MultiSelectFilter, { entity: "retentionRisk", label: "Retention Risks", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.RetentionRisk)),
        },
        {
            key: AvailableFilters.CareerInterest,
            implementation: (_jsx(MultiSelectFilter, { entity: "careerInterests", label: "Career Interests", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.CareerInterest)),
        },
        {
            key: AvailableFilters.AssessmentSource,
            implementation: (_jsx(MultiSelectFilter, { entity: "assessmentSource", label: "Assessment Source", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.AssessmentSource)),
        },
        {
            key: AvailableFilters.Age,
            implementation: (_jsx(MultiSelectFilter, { useOptionsQuery: useGetDataFiltersQuery, entity: "ageRange", label: "Age" }, AvailableFilters.Age)),
        },
        {
            key: AvailableFilters.DerailerRisk,
            implementation: (_jsx(MultiSelectFilter, { entity: "derailerRisk", label: "Derailer Risks", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.DerailerRisk)),
        },
        {
            key: AvailableFilters.RelocationPreference,
            implementation: (_jsx(MultiSelectFilter, { entity: "willingToRelocate", label: "Relocation Preferences", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.RelocationPreference)),
        },
        {
            key: AvailableFilters.Gender,
            implementation: (_jsx(MultiSelectFilter, { entity: "gender", label: "Gender", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.Gender)),
        },
        {
            key: AvailableFilters.Ethnicity,
            implementation: (_jsx(MultiSelectFilter, { entity: "ethnicity", label: "Ethnicity", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.Ethnicity)),
        },
        {
            key: AvailableFilters.NineBox,
            implementation: _jsx(NineBoxFilter, {}, AvailableFilters.NineBox),
        },
    ].filter(function (_a) {
        var key = _a.key;
        return filters.has(key);
    });
};
var Header = function () { return (_jsx("div", __assign({ className: styles.panelHeader }, { children: "Additional Filters" }))); };
var Body = function (_a) {
    var filters = _a.filters;
    var _b = usePermissions(), nineBox = _b.nineBox, showDiversitySpecific = _b.showDiversitySpecific;
    var hasPermission = function (_a) {
        var key = _a.key;
        return !((key == AvailableFilters.Ethnicity && !showDiversitySpecific) ||
            (key == AvailableFilters.Gender && !showDiversitySpecific) ||
            (key == AvailableFilters.NineBox && !(nineBox && haveTalentReviewPages())));
    };
    return (_jsx("div", __assign({ className: styles.body }, { children: _jsx(Stack, __assign({ direction: "vertical", flexGap: "s" }, { children: getFilterSet(filters)
                .filter(hasPermission)
                .map(function (_a) {
                var implementation = _a.implementation;
                return implementation;
            }) })) })));
};
var Button = function (_a) {
    var onClick = _a.onClick;
    return (_jsx(DropdownButton, { onClick: onClick, iconOverrideProps: {
            path: IconName.mdiTuneVariant,
        }, buttonProps: {
            variant: ButtonVariant.SystemUI,
            counter: "",
        }, alignIcon: ButtonIconAlign.Left, label: "Additional Filters", buttonOverrideProps: {
            color: "#002136",
            fontFamily: "Avenir Medium",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
        } }));
};
export var Additional = function (_a) {
    var filters = _a.filters;
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var onClick = function () { return setVisible(function (previous) { return !previous; }); };
    return (_jsxs(_Fragment, { children: [visible && (_jsxs(Panel, __assign({ placeholder: null, size: PanelSize.small, visible: true, onClose: function () {
                    setVisible(false);
                } }, { children: [_jsx(Header, {}), _jsx("div", { className: styles.divider }), _jsx(Body, { filters: filters })] }))), _jsx(Button, { onClick: onClick })] }));
};
