var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import SkillGroupHeading from "./SkillGroupHeading";
import SkillGroupAccordion from "./SkillGroupAccordion/SkillGroupAccordion";
import { useImpactDataSource, useCapabilitiesPotentialDataSource, } from "./SuccessProfileDataSource";
var SuccessProfilesSkillGroup = function (props) {
    return (_jsxs("div", __assign({ "data-testid": "skill-group-container" }, { children: [_jsx(SkillGroupHeading, { title: props.title, description: props.description }), _jsx(SkillGroupAccordion, { accordionData: props.skillData })] })));
};
export var SuccessProfilesFunctionalImpact = function (props) {
    var skillGroupData = useImpactDataSource(props.clientId, props.roleProfileId, "Functional Impact");
    return (_jsx(SuccessProfilesSkillGroup, { title: "Functional Impact", description: skillGroupData.description, skillData: skillGroupData.skills }));
};
export var SuccessProfilesOrganizationalImpact = function (props) {
    var skillGroupData = useImpactDataSource(props.clientId, props.roleProfileId, "Organizational Impact");
    return (_jsx(SuccessProfilesSkillGroup, { title: "Organizational Impact", description: skillGroupData.description, skillData: skillGroupData.skills }));
};
export var SuccessProfilesCapabilities = function (props) {
    var skillGroupData = useCapabilitiesPotentialDataSource(props.clientId, props.roleProfileId, "Capabilities");
    return (_jsx(SuccessProfilesSkillGroup, { title: "Capabilities", description: skillGroupData.description, skillData: skillGroupData.skills }));
};
export var SuccessProfilesPotential = function (props) {
    var skillGroupData = useCapabilitiesPotentialDataSource(props.clientId, props.roleProfileId, "Potential");
    return (_jsx(SuccessProfilesSkillGroup, { title: "Potential", description: skillGroupData.description, skillData: skillGroupData.skills }));
};
export default SuccessProfilesSkillGroup;
