import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DropdownButton } from "../Dropdown/Dropdown";
import { ButtonIconAlign, ButtonVariant, IconName, } from "@eightfold.ai/octuple";
var ResetButton = function (_a) {
    var label = _a.label, onClick = _a.onClick;
    return (_jsx(DropdownButton, { onClick: onClick, iconOverrideProps: {
            path: IconName.mdiClose,
        }, buttonProps: {
            variant: ButtonVariant.SystemUI,
            counter: "",
        }, alignIcon: ButtonIconAlign.Left, label: label, buttonOverrideProps: {
            color: "#002136",
            fontFamily: "Avenir Medium",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
        } }));
};
export default ResetButton;
