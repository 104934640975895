var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
export var emptyFilters = {
    location: [],
    location_2: [],
    division: [],
    roleProfileId: [],
    roles: [],
    assigned_box: [],
    business_unit: [],
    ninebox_id: undefined,
    manager: [],
    functionFilter: [],
    subFunctionFilter: [],
    level: [],
    scoreType: undefined,
    dashboardScoreType: undefined,
    roleName: undefined,
    clientId: undefined,
    statement: [],
    retentionRisk: [],
    careerInterests: [],
    assessmentSource: [],
    ageRange: [],
    derailerRisk: [],
    willingToRelocate: [],
    gender: [],
    ethnicity: [],
};
export var FilterContext = React.createContext({
    /* eslint-disable @typescript-eslint/no-empty-function */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    setFilters: function (filters) { },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    getFilters: function () { },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    updateFilters: function (changes) { },
    /* eslint-enable @typescript-eslint/no-unused-vars */
    /* eslint-enable @typescript-eslint/no-empty-function */
});
export var FilterEventName = "filterSetEvent";
var currentFilters;
export var getFilters = function () {
    return currentFilters ? JSON.parse(currentFilters) : {};
};
export var setFilters = function (change) {
    window.dispatchEvent(new CustomEvent(FilterEventName, {
        detail: change,
    }));
};
export var updateFilters = function (change) {
    var changedFilters = __assign(__assign({}, getFilters()), change);
    setFilters(changedFilters);
};
export var filterListeners = new Set();
export var FilterProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(__assign({}, emptyFilters)), rawFilters = _b[0], setRawFilters = _b[1];
    var filters = Object.keys(rawFilters).reduce(function (current, key) {
        var _a;
        return (__assign(__assign({}, current), (_a = {}, _a[key] = Array.isArray(emptyFilters === null || emptyFilters === void 0 ? void 0 : emptyFilters[key]) &&
            (rawFilters === null || rawFilters === void 0 ? void 0 : rawFilters[key]) != null &&
            !Array.isArray(rawFilters === null || rawFilters === void 0 ? void 0 : rawFilters[key])
            ? [rawFilters === null || rawFilters === void 0 ? void 0 : rawFilters[key]]
            : rawFilters === null || rawFilters === void 0 ? void 0 : rawFilters[key], _a)));
    }, {});
    var setFilters = function (newFilters) {
        filterListeners.forEach(function (listener) {
            listener(getFilters(), newFilters);
        });
        setRawFilters(newFilters);
    };
    var eventListener = function (event) {
        setFilters(event.detail);
    };
    React.useEffect(function () {
        window.addEventListener(FilterEventName, eventListener);
        return function () {
            window.removeEventListener(FilterEventName, eventListener);
        };
    }, []);
    var value = React.useMemo(function () {
        currentFilters = JSON.stringify(filters);
        return {
            updateFilters: function (changes) {
                setFilters(__assign(__assign({}, getFilters()), changes));
            },
            setFilters: setFilters,
            getFilters: function () { return filters; },
        };
    }, [filters]);
    return (_jsx(FilterContext.Provider, __assign({ value: value }, { children: children })));
};
