// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A9IUd_5kmVjcPxCMYnxW {
  font-family: "Avenir Heavy", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  overflow-y: auto;
  padding: 50px 0;
  box-sizing: border-box;
}

.A9IUd_5kmVjcPxCMYnxW .ZWJzr7wpZVh1FbHdy6Q2 {
  position: relative;
  margin: auto;
  padding: 20px 0;
}

.A9IUd_5kmVjcPxCMYnxW .ZWJzr7wpZVh1FbHdy6Q2 .HslYP76iFcCJqGYUDDMn {
  margin-bottom: 16px;
  padding: 0 24px;
}

.A9IUd_5kmVjcPxCMYnxW .ZWJzr7wpZVh1FbHdy6Q2 .Oow9GPr9F3RQ_0sXpHX6 {
  font-family: "Avenir Book", sans-serif;
  padding: 16px 0 16px 0;
  margin: 0 32px;
  border-top: solid 1px #d9d9d9;
}

.A9IUd_5kmVjcPxCMYnxW .ZWJzr7wpZVh1FbHdy6Q2 [class^=footer] {
  padding: 0 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/Dialog.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,sCAAA;EACA,sBAAA;EACA,cAAA;EACA,6BAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".dialogWrapper {\n  font-family: \"Avenir Heavy\", sans-serif;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: flex-start;\n  position: fixed;\n  overflow-y: auto;\n  padding: 50px 0;\n  box-sizing: border-box;\n}\n\n.dialogWrapper .dialog {\n  position: relative;\n  margin: auto;\n  padding: 20px 0;\n}\n\n.dialogWrapper .dialog .dialogHeader {\n  margin-bottom: 16px;\n  padding: 0 24px;\n}\n\n.dialogWrapper .dialog .dialogBody {\n  font-family: \"Avenir Book\", sans-serif;\n  padding: 16px 0 16px 0;\n  margin: 0 32px;\n  border-top: solid 1px #d9d9d9;\n}\n\n.dialogWrapper .dialog [class^=\"footer\"] {\n  padding: 0 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogWrapper": `A9IUd_5kmVjcPxCMYnxW`,
	"dialog": `ZWJzr7wpZVh1FbHdy6Q2`,
	"dialogHeader": `HslYP76iFcCJqGYUDDMn`,
	"dialogBody": `Oow9GPr9F3RQ_0sXpHX6`
};
export default ___CSS_LOADER_EXPORT___;
