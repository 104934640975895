/* eslint-enable no-var */
var isJestRunning;
try {
    isJestRunning =
        process.env.JEST_WORKER_ID !== undefined && process.env.NODE_ENV == "test";
}
catch (e) {
    isJestRunning = false;
}
var notStorybook = !process.env.STORYBOOK;
export var isLiveEnvironment = function () {
    return notStorybook && !isJestRunning;
};
export var isStorybookEnvironment = function () {
    return !notStorybook && !isJestRunning;
};
