var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import FilteredOverviewPage from "../../components/FilteredOverviewPage";
import BackToButton from "../../components/BackToButton";
import { useGetLeaderedSuccessionLeaderScoresQuery } from "../../store/api/core";
import { FilterContext } from "../../providers/FilterProvider";
import { ScoreSelectorRow, textLowScorerGetter, } from "../../components/FilteredOverviewPage/FilteredOverviewPage";
import { LeaderedSuccessionTypes } from "../../store/api/types/core";
import { AssessmentTable } from "../AssessmentPage/AssessmentPage";
import { Status } from "../../common/action";
import { ValidSortDirections } from "../../components/Table/Table";
import { ScoreTypes } from "../../components/LevelFunctionMatrix/Variants";
import { getRoute } from "../../routes/getAllRoutes";
import { DefaultFilterSet } from "../../components/Filters/Filters";
var scoreInfos = [
    {
        scoreType: LeaderedSuccessionTypes.MobilityRisk,
        title: "Leaders with mobility risks",
        tooltip: "Leaders who are at risk due to an ambiguous or undefined career pathway.",
    },
    {
        scoreType: LeaderedSuccessionTypes.NoPathForward,
        title: "Leaders with no path forward",
        tooltip: "Leaders who are not currently included in any succession plan or pool.",
    },
    {
        scoreType: LeaderedSuccessionTypes.OnMultiplePipelines,
        title: "Leaders with 3+ pipelines",
        tooltip: "Leaders who are part of three or more succession plans or pools.",
    },
];
var LocalAssessmentTable = function () {
    var _a;
    var getFilters = useContext(FilterContext).getFilters;
    var scoreType = (_a = getFilters()) === null || _a === void 0 ? void 0 : _a.scoreType;
    var sortOns = function (key) {
        var _a;
        return ((_a = {},
            _a[LeaderedSuccessionTypes.MobilityRisk] = [
                {
                    column: "succession",
                    direction: ValidSortDirections.ASC,
                },
                {
                    column: ScoreTypes.Overall,
                    direction: ValidSortDirections.DESC,
                },
            ],
            _a[LeaderedSuccessionTypes.NoPathForward] = [
                {
                    column: ScoreTypes.Overall,
                    direction: ValidSortDirections.DESC,
                },
            ],
            _a[LeaderedSuccessionTypes.OnMultiplePipelines] = [
                {
                    column: "succession",
                    direction: ValidSortDirections.DESC,
                },
                {
                    column: ScoreTypes.Overall,
                    direction: ValidSortDirections.DESC,
                },
            ],
            _a)[key]);
    };
    return (_jsx(AssessmentTable, { useScores: useGetLeaderedSuccessionLeaderScoresQuery, filterFunctor: function (record, scoreType) { var _a; return scoreType == null || ((_a = record === null || record === void 0 ? void 0 : record[scoreType]) === null || _a === void 0 ? void 0 : _a.state) == Status.Act; }, defaultSortOn: sortOns[scoreType] || [0, ValidSortDirections.ASC], sortOns: sortOns }));
};
var MobilityPage = function () {
    var title = "Intelligence - Mobility";
    var availableFilters = new Set(__spreadArray([], Array.from(DefaultFilterSet), true));
    return (_jsx(FilteredOverviewPage, __assign({ availableFilters: availableFilters, title: title, secondaryHeadingTitle: "Leader Mobility", backToButton: _jsx(BackToButton, { title: "Mobility", url: getRoute("DashboardMobility") }), scoreCards: _jsx(ScoreSelectorRow, { textGetter: textLowScorerGetter, initialScoreType: LeaderedSuccessionTypes.MobilityRisk, scoreInfos: scoreInfos, useScores: useGetLeaderedSuccessionLeaderScoresQuery }) }, { children: _jsx(LocalAssessmentTable, {}) })));
};
export default MobilityPage;
