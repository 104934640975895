var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useGetDiversityLeaders } from "../../store/api/core";
import { AssessmentTable, DefaultColoredTotalsCell, } from "../../pages/AssessmentPage/AssessmentPage";
import { styled } from "@mui/system";
import { ValidSortDirections } from "../Table/Table";
import SpinnerAndErrorer from "../SpinnerAndErrorer";
import { usePermissions } from "../../hooks/permissions";
export var DiversityTable = function (_a) {
    var filters = _a.filters;
    var _b = usePermissions(), conditions = _b.conditions, showDiversitySpecific = _b.showDiversitySpecific;
    var ResultDiv = styled("div")({
        fontFamily: "Avenir Book",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
    });
    var ResultFormatter = function (data) { return (_jsx(ResultDiv, { children: data.data })); };
    var filterFunctor = function (leaderScore) {
        return filters.reduce(function (previous, filter) {
            return previous && filter(leaderScore);
        }, true);
    };
    var mutateColumnDescriptions = function (columnDescriptions, scores) {
        var _a, _b, _c;
        var first = columnDescriptions[0];
        first.isHighlighted = false;
        var remaining = columnDescriptions
            .slice(1, 4)
            .map(function (description) { return (__assign(__assign({}, description), { isHighlighted: false })); });
        var match = columnDescriptions[4];
        var totalRows = ["now", "soon", "later"].reduce(function (previous, current) {
            var _a;
            var totalSum = (scores || []).reduce(function (sum, score) { return sum + (score["ready_".concat(current, "_successor")] || 0); }, 0);
            var average = totalSum / (scores === null || scores === void 0 ? void 0 : scores.length);
            return __assign(__assign({}, previous), (_a = {}, _a[current] = Number.isNaN(average) ? 0 : average, _a));
        }, {
            now: 0,
            soon: 0,
            later: 0,
        });
        return __spreadArray(__spreadArray(__spreadArray([
            first
        ], (!showDiversitySpecific
            ? []
            : [
                {
                    header: "Ethnicity",
                    formatter: ResultFormatter,
                    defaultSortOrderKey: "ethnicity",
                    defaultSortDirection: ValidSortDirections.ASC,
                    totalCell: _jsx(ResultDiv, { children: "-" }),
                    valueTransformer: function (row) { return ({
                        sort: row.ethnicity,
                        data: row.ethnicity,
                    }); },
                },
                {
                    header: "Gender",
                    formatter: ResultFormatter,
                    defaultSortOrderKey: "gender",
                    defaultSortDirection: ValidSortDirections.ASC,
                    totalCell: _jsx(ResultDiv, { children: "-" }),
                    valueTransformer: function (row) { return ({
                        sort: row.gender,
                        data: row.gender,
                    }); },
                },
            ]), true), remaining, true), [
            {
                header: "Ready Now",
                formatter: ResultFormatter,
                defaultSortOrderKey: "now",
                defaultSortDirection: ValidSortDirections.ASC,
                totalCell: (_jsx(DefaultColoredTotalsCell, { text: isNaN(totalRows["now"]) ? "--" : "".concat((_a = totalRows["now"]) === null || _a === void 0 ? void 0 : _a.toFixed(1)), score: totalRows["now"] ? totalRows["now"] : 0 })),
                valueTransformer: function (row) { return ({
                    sort: row.ready_now_successor,
                    data: row.ready_now_successor,
                }); },
            },
            {
                header: "Ready Soon",
                formatter: ResultFormatter,
                defaultSortOrderKey: "soon",
                defaultSortDirection: ValidSortDirections.ASC,
                totalCell: (_jsx(DefaultColoredTotalsCell, { text: isNaN(totalRows["soon"])
                        ? "--"
                        : "".concat((_b = totalRows["soon"]) === null || _b === void 0 ? void 0 : _b.toFixed(1)), score: totalRows["soon"] ? totalRows["soon"] : 0 })),
                valueTransformer: function (row) { return ({
                    sort: row.ready_soon_successor,
                    data: row.ready_soon_successor,
                }); },
            },
            {
                header: "Ready Later",
                formatter: ResultFormatter,
                defaultSortOrderKey: "later",
                defaultSortDirection: ValidSortDirections.ASC,
                totalCell: (_jsx(DefaultColoredTotalsCell, { text: isNaN(totalRows["later"])
                        ? "--"
                        : "".concat((_c = totalRows["later"]) === null || _c === void 0 ? void 0 : _c.toFixed(1)), score: totalRows["later"] ? totalRows["later"] : 0 })),
                valueTransformer: function (row) { return ({
                    sort: row.ready_later_successor,
                    data: row.ready_later_successor,
                }); },
            },
            match,
        ], false);
    };
    return (_jsx(SpinnerAndErrorer, __assign({ conditions: conditions }, { children: _jsx("div", __assign({ "data-testid": "DiversityTable" }, { children: _jsx(AssessmentTable, { "data-testid": "DiversityAssessment", useScores: useGetDiversityLeaders, filterFunctor: filterFunctor, mutateColumnDescriptions: mutateColumnDescriptions, sortOns: function () { return [
                    { column: "timeInRole", direction: ValidSortDirections.DESC },
                ]; } }) })) })));
};
