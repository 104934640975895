var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { FilterContext, emptyFilters } from "../../providers/FilterProvider";
import LeaderCount from "../../components/LeaderCount/";
import SuccessionPipelines from "./SuccessionPipelines";
import { useGetDiversityLeaders } from "../../store/api/core";
import { hasPermissionExist } from "../../store/api/permissions";
import SpinnerAndErrorer from "../SpinnerAndErrorer";
import { IconName } from "@eightfold.ai/octuple";
import { IconButton, ButtonVariant } from "../Button/Button";
import PipelineStatusCards from "../PipelineStatusCards";
import { HeadCountDonutChart as DonutChart } from "../DonutChart/DonutChart";
import { DiversityLegend } from "../DiversityLegend";
import CircleProgressBar from "../CircleProgressBar";
import { useNavigate } from "../../hooks/navigate";
import { getRoute } from "../../routes/getAllRoutes";
import { LandingPageFilters } from "../Filters/Filters";
import styles from "./Diversity.module.scss";
import { Filters } from "../Filters/Filters";
var DiversityContent = function () {
    var _a, _b, _c, _d, _e;
    var navigate = useNavigate();
    var _f = useContext(FilterContext), getFilters = _f.getFilters, setFilters = _f.setFilters;
    var _g = getFilters(), location = _g.location, business_unit = _g.business_unit, ninebox_id = _g.ninebox_id, assigned_box = _g.assigned_box;
    var leadersResponse = useGetDiversityLeaders({ filters: getFilters() });
    var leadersCount = (_c = (_b = (_a = leadersResponse === null || leadersResponse === void 0 ? void 0 : leadersResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.leaders) === null || _c === void 0 ? void 0 : _c.length;
    useEffect(function () {
        setFilters(__assign(__assign({}, emptyFilters), { location: location || [], business_unit: business_unit || [], ninebox_id: ninebox_id || undefined, assigned_box: assigned_box || [] }));
    }, []);
    var conditions = [leadersResponse, leadersResponse];
    var leaders = (_e = (_d = leadersResponse === null || leadersResponse === void 0 ? void 0 : leadersResponse.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.leaders;
    useEffect(function () {
        leadersResponse.refetch();
    }, [getFilters]);
    var countsStatus = {
        inPipeline: 0,
        notInPipeline: 0,
        genderInPipelineCount: 0,
        genderNotInPipelineCount: 0,
        ethnicallyInPipelineCount: 0,
        ethnicallyNotInPipelineCount: 0,
        bothInPipelineCount: 0,
        bothNotInPipelineCount: 0,
        genderDiverse: 0,
        genderNotDiverse: 0,
        genderUndecleared: 0,
        ethnicityDiverse: 0,
        ethnicityNotDiverse: 0,
        ethnicityUndecleared: 0,
    };
    var counts = (leaders === null || leaders === void 0 ? void 0 : leaders.reduce(function (counts, _a) {
        var succession_plans_count = _a.succession_plans_count, is_gender_diverse = _a.is_gender_diverse, is_ethnically_diverse = _a.is_ethnically_diverse, gender = _a.gender, ethnicity = _a.ethnicity;
        // Increment overall pipeline counts
        if (succession_plans_count > 0) {
            counts.inPipeline++;
        }
        else {
            counts.notInPipeline++;
        }
        // Increment gender-specific pipeline counts
        if (is_gender_diverse) {
            if (succession_plans_count > 0) {
                counts.genderInPipelineCount++;
            }
            else {
                counts.genderNotInPipelineCount++;
            }
        }
        // Increment ethnically-specific pipeline counts
        if (is_ethnically_diverse) {
            if (succession_plans_count > 0) {
                counts.ethnicallyInPipelineCount++;
            }
            else {
                counts.ethnicallyNotInPipelineCount++;
            }
        }
        // Increment counts for both gender and ethnically diverse pipelines
        if (is_gender_diverse && is_ethnically_diverse) {
            if (succession_plans_count > 0) {
                counts.bothInPipelineCount++;
            }
            else {
                counts.bothNotInPipelineCount++;
            }
        }
        if (is_gender_diverse == true && gender) {
            counts.genderDiverse++;
        }
        else if (is_gender_diverse == false && gender) {
            counts.genderNotDiverse++;
        }
        else {
            counts.genderUndecleared++;
        }
        if (is_ethnically_diverse == true && ethnicity) {
            counts.ethnicityDiverse++;
        }
        else if (is_ethnically_diverse == false && ethnicity) {
            counts.ethnicityNotDiverse++;
        }
        else {
            counts.ethnicityUndecleared++;
        }
        return counts;
    }, countsStatus)) || countsStatus;
    var inPipeline = counts.inPipeline, notInPipeline = counts.notInPipeline, genderInPipelineCount = counts.genderInPipelineCount, genderNotInPipelineCount = counts.genderNotInPipelineCount, ethnicallyInPipelineCount = counts.ethnicallyInPipelineCount, ethnicallyNotInPipelineCount = counts.ethnicallyNotInPipelineCount, bothInPipelineCount = counts.bothInPipelineCount, bothNotInPipelineCount = counts.bothNotInPipelineCount, genderDiverse = counts.genderDiverse, genderNotDiverse = counts.genderNotDiverse, genderUndecleared = counts.genderUndecleared, ethnicityDiverse = counts.ethnicityDiverse, ethnicityNotDiverse = counts.ethnicityNotDiverse, ethnicityUndecleared = counts.ethnicityUndecleared;
    var successPipelines = {
        total: inPipeline + notInPipeline,
        inPipeline: inPipeline,
        notInPipeline: notInPipeline,
    };
    // Helper function to calculate percentage
    var calculatePercentage = function (countInPipeline, countNotInPipeline) {
        var total = countInPipeline + countNotInPipeline;
        var percentage = (countInPipeline / total) * 100;
        return Number.isNaN(percentage) ? 0 : percentage.toFixed(2);
    };
    // Helper function to compare percentages
    var comparePercentages = function (overall, specific) {
        return overall < specific
            ? "Higher"
            : overall > specific
                ? "Lower"
                : "Same";
    };
    // Overall Percentage calculations
    var overallPipelinePer = calculatePercentage(inPipeline, notInPipeline);
    // Gender diverse percentage calculations
    var genderPipelinePer = calculatePercentage(genderInPipelineCount, genderNotInPipelineCount);
    // Ethnically diverse percentage calculations
    var ethnicallyPipelinePer = calculatePercentage(ethnicallyInPipelineCount, ethnicallyNotInPipelineCount);
    // Gender and ethnically diverse percentage calculations
    var genderAndEthnicallyPipelinePer = calculatePercentage(bothInPipelineCount, bothNotInPipelineCount);
    // Compare percentages
    var genderComparisonDesc = comparePercentages(overallPipelinePer, genderPipelinePer);
    var ethnicallyComparisonDesc = comparePercentages(overallPipelinePer, ethnicallyPipelinePer);
    var genderAndEthnicallyComparisonDesc = comparePercentages(overallPipelinePer, genderAndEthnicallyPipelinePer);
    var successLeadersPipelines = [
        {
            title: "Gender-diverse",
            desc: genderComparisonDesc,
            totalLeaders: genderInPipelineCount + genderNotInPipelineCount,
            inPipelineLeaders: genderInPipelineCount,
            notInPipelineLeaders: genderNotInPipelineCount,
        },
        {
            title: "Ethnically-diverse",
            desc: ethnicallyComparisonDesc,
            totalLeaders: ethnicallyInPipelineCount + ethnicallyNotInPipelineCount,
            inPipelineLeaders: ethnicallyInPipelineCount,
            notInPipelineLeaders: ethnicallyNotInPipelineCount,
        },
        {
            title: "Gender & ethnically diverse",
            desc: genderAndEthnicallyComparisonDesc,
            totalLeaders: bothInPipelineCount + bothNotInPipelineCount,
            inPipelineLeaders: bothInPipelineCount,
            notInPipelineLeaders: bothNotInPipelineCount,
        },
    ];
    var diverseStatus = [
        {
            diverse: genderDiverse,
            notDiverse: genderNotDiverse,
            undecleared: genderUndecleared,
        },
        {
            diverse: ethnicityDiverse,
            notDiverse: ethnicityNotDiverse,
            undecleared: ethnicityUndecleared,
        },
    ];
    // Function to calculate diversity legend counts
    function calculateDiversityLegendCounts(leaders, property) {
        var counts = {};
        var undeclaredCount = 0;
        leaders === null || leaders === void 0 ? void 0 : leaders.forEach(function (leader) {
            var value = leader[property];
            if (!value) {
                undeclaredCount++;
            }
            else {
                if (counts[value]) {
                    counts[value]++;
                }
                else {
                    counts[value] = 1;
                }
            }
        });
        var formattedCounts = Object.keys(counts).map(function (key) {
            var legendObject = {
                description: key,
                count: counts[key],
            };
            return legendObject;
        });
        if (undeclaredCount > 0) {
            formattedCounts.push({
                description: "Undeclared",
                count: undeclaredCount,
            });
        }
        return formattedCounts;
    }
    var genderFormattedCounts = calculateDiversityLegendCounts(leaders, "gender");
    var ethnicityFormattedCounts = calculateDiversityLegendCounts(leaders, "ethnicity");
    // Calculate rounded percentages to ensure they sum up to exactly 100%
    function calculateRoundedPercentages(diverse, notDiverse, undecleared, leadersCount) {
        var diverseCal = (diverse / leadersCount) * 100;
        var notDiverseCal = (notDiverse / leadersCount) * 100;
        var undeclearedCal = (undecleared / leadersCount) * 100;
        var roundedDiverse = Math.round(diverseCal);
        var roundedNotDiverse = Math.round(notDiverseCal);
        var roundedUndecleared = Math.round(undeclearedCal);
        return [roundedDiverse, roundedNotDiverse, roundedUndecleared];
    }
    var Demographics = function (_a) {
        var title = _a.title, diverseStatus = _a.diverseStatus, leadersCount = _a.leadersCount, formattedCounts = _a.formattedCounts, diverseUrl = _a.diverseUrl;
        var diverse = diverseStatus.diverse, notDiverse = diverseStatus.notDiverse, undecleared = diverseStatus.undecleared;
        var roundedPercentages = calculateRoundedPercentages(diverse, notDiverse, undecleared, leadersCount);
        var diversePer = Number.isNaN(roundedPercentages[0])
            ? 0
            : roundedPercentages[0];
        var notDiversePer = Number.isNaN(roundedPercentages[1])
            ? 0
            : roundedPercentages[1];
        var undeclearedPer = Number.isNaN(roundedPercentages[2])
            ? 0
            : roundedPercentages[2];
        formattedCounts.sort(function (_a, _b) {
            var aCount = _a.count;
            var bCount = _b.count;
            return (aCount > bCount ? -1 : aCount == bCount ? 0 : 1);
        });
        var countElements = formattedCounts.map(function (_a) {
            var count = _a.count;
            return count;
        });
        return (_jsxs("div", __assign({ className: styles.demographicsContainer }, { children: [_jsxs("div", { children: [_jsx("div", __assign({ style: { marginBottom: "24px" } }, { children: _jsx("div", __assign({ className: styles.demographicsTitle }, { children: _jsxs("p", __assign({ className: styles.allLeadersTitle }, { children: ["All leaders:", " ", _jsxs("span", __assign({ style: { fontFamily: "Avenir Book" } }, { children: ["By ", title] }))] })) })) })), _jsx("div", { children: _jsx("div", { style: {
                                    width: "100%",
                                    height: "1px",
                                    backgroundColor: "#D9DCE1",
                                } }) })] }), _jsxs("div", __assign({ className: styles.circleHeaderWrapper }, { children: [_jsx("div", __assign({ className: styles.donutWrapper }, { children: _jsx(DonutChart, { radius: 150, counts: countElements }) })), _jsx("div", { children: _jsx(DiversityLegend, { elements: formattedCounts }) })] })), _jsx("div", __assign({ className: styles.statusWrapper }, { children: _jsxs("div", __assign({ className: styles.statusContainer }, { children: [_jsx("div", __assign({ style: { flex: 1 } }, { children: _jsxs("p", __assign({ style: { fontFamily: "Avenir Book" } }, { children: [_jsx("span", __assign({ style: { textTransform: "capitalize" } }, { children: title })), " by diversity status"] })) })), _jsx("div", __assign({ style: { flex: 1 } }, { children: _jsx(CircleProgressBar, { percentage: diversePer, value: diverse, descText: "are diverse", secondaryPageUrl: diverseUrl.diverse }) })), _jsx("div", __assign({ style: { flex: 1 } }, { children: _jsx(CircleProgressBar, { percentage: notDiversePer, value: notDiverse, descText: "are not diverse", secondaryPageUrl: diverseUrl.notDiverse }) })), _jsx("div", __assign({ style: { flex: 1 } }, { children: _jsx(CircleProgressBar, { percentage: undeclearedPer, value: undecleared, descText: "are undeclared", secondaryPageUrl: diverseUrl.undeclared }) }))] })) }))] })));
    };
    var openDiversitySettingPage = function (navigate) {
        navigate(getRoute("DiversitySettings"));
    };
    var genderDiverseUrl = {
        diverse: "GenderDiverse",
        notDiverse: "NonGenderDiverse",
        undeclared: "UndeclaredGender",
    };
    var ethnicallyDiverseUrl = {
        diverse: "EthnicallyDiverse",
        notDiverse: "NonEthnicallyDiverse",
        undeclared: "UndeclaredEthnicity",
    };
    var pipelinesRoutes = {
        gender: {
            inPipeline: "GenderDiverseOnPipeline",
            notInPipeline: "GenderDiverseNotOnPipeline",
        },
        ethnically: {
            inPipeline: "EthnicallyDiverseOnPipeline",
            notInPipeline: "EthnicallyDiverseNotOnPipeline",
        },
        genderAndEthnically: {
            inPipeline: "GenderEthnicallyDiverseOnPipeline",
            notInPipeline: "GenderEthnicallyDiverseNotOnPipeline",
        },
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.outerFilterLayout }, { children: _jsx(Filters, { availableFilters: LandingPageFilters, leftTrailer: _jsx("div", __assign({ style: { paddingTop: "2px" } }, { children: _jsx(LeaderCount, { leadersCount: leadersCount, countText: "leader" }) })), rightTrailer: _jsx("div", __assign({ style: {
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            justifyContent: "space-between",
                            marginLeft: "auto",
                            paddingRight: "20px",
                        } }, { children: hasPermissionExist("ORG_DASHBOARD_UI/DIVERSITY/SETTINGS") ? (_jsx(IconButton, { iconPath: IconName.mdiCog, variant: ButtonVariant.Neutral, onClick: function () { return openDiversitySettingPage(navigate); } })) : null })) }) })), _jsxs(SpinnerAndErrorer, __assign({ conditions: conditions }, { children: [_jsxs("div", __assign({ style: { padding: "24px 32px 48px" } }, { children: [_jsx("div", __assign({ className: styles.labelStyle }, { children: _jsx("div", __assign({ className: styles.mainTitle }, { children: "Demographics" })) })), _jsx(Demographics, { title: "gender", diverseStatus: diverseStatus[0], leadersCount: leadersCount, formattedCounts: genderFormattedCounts, diverseUrl: genderDiverseUrl }), _jsx(Demographics, { title: "ethnicity", diverseStatus: diverseStatus[1], leadersCount: leadersCount, formattedCounts: ethnicityFormattedCounts, diverseUrl: ethnicallyDiverseUrl })] })), _jsxs("div", __assign({ style: { padding: "24px 32px 48px" } }, { children: [_jsxs("div", { children: [_jsx("div", __assign({ className: styles.mainTitle }, { children: "What percentage of leaders are in succession pipelines?" })), _jsx(SuccessionPipelines, { successPipelines: successPipelines })] }), _jsx("div", { children: _jsxs("div", __assign({ className: styles.pipelineWrapper }, { children: [_jsx("div", __assign({ style: {
                                                textWrap: "nowrap",
                                                flex: 1,
                                                marginRight: "12px",
                                            } }, { children: _jsx(PipelineStatusCards, { successLeadersPipelines: successLeadersPipelines[0], pipelineUrl: pipelinesRoutes.gender }) })), _jsx("div", __assign({ style: {
                                                textWrap: "nowrap",
                                                flex: 1,
                                                margin: "0 12px",
                                            } }, { children: _jsx(PipelineStatusCards, { successLeadersPipelines: successLeadersPipelines[1], pipelineUrl: pipelinesRoutes.ethnically }) })), _jsx("div", __assign({ style: {
                                                textWrap: "nowrap",
                                                flex: 1,
                                                marginLeft: "12px",
                                            } }, { children: _jsx(PipelineStatusCards, { successLeadersPipelines: successLeadersPipelines[2], pipelineUrl: pipelinesRoutes.genderAndEthnically }) }))] })) })] }))] }))] }));
};
var Diversity = function () {
    return (_jsx(_Fragment, { children: _jsx(DiversityContent, {}) }));
};
export default Diversity;
