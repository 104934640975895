// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PbbhxYGf9uNei3g3D8A5, .yY2Mud7JMPgv3byiMb0T {
  font-family: "Avenir Medium", sans-serif;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  border-radius: 24px;
  padding: 4px 8px;
  text-wrap: nowrap;
}

.yY2Mud7JMPgv3byiMb0T {
  background-color: var(--orange-color-20);
  color: var(--red-color-80);
}

.PbbhxYGf9uNei3g3D8A5 {
  background-color: var(--red-color-10);
  color: var(--red-color-80);
}`, "",{"version":3,"sources":["webpack://./src/components/Pill/Pill.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,eAAA;EACA,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EAEE,wCAAA;EACA,0BAAA;AAAF;;AAGA;EAEE,qCAAA;EACA,0BAAA;AADF","sourcesContent":["%pillBase {\n  font-family: \"Avenir Medium\", sans-serif;\n  font-size: 14px;\n  display: inline-flex;\n  align-items: center;\n  border-radius: 24px;\n  padding: 4px 8px;\n  text-wrap: nowrap;\n}\n\n.pillWarning {\n  @extend %pillBase;\n  background-color: var(--orange-color-20);\n  color: var(--red-color-80);\n}\n\n.pillError {\n  @extend %pillBase;\n  background-color: var(--red-color-10);\n  color: var(--red-color-80);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pillError": `PbbhxYGf9uNei3g3D8A5`,
	"pillWarning": `yY2Mud7JMPgv3byiMb0T`
};
export default ___CSS_LOADER_EXPORT___;
