var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled, Box } from "@mui/material";
import { Empty } from "@eightfold.ai/octuple";
var PermissionNotFound = function () {
    var Container = styled(Box)({
        textAlign: "center",
        padding: "50px 15px",
    });
    return (_jsx(Container, __assign({ "data-testid": "PermissionsNotFound" }, { children: _jsx(Empty, { description: "You need additional permissions to continue. Please contact your administrator to request access.", descriptionClassNames: "permission-no-found-class", mode: "error", title: "Access Restricted" }) })));
};
export default PermissionNotFound;
