var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button, Typography, Modal, Divider, useTheme, } from "@mui/material";
import ActionCircle from "../ActionCircle/ActionCircle";
import { Status } from "../../common/action";
import ClosingX from "../../icons/ClosingX";
import HoverableCircle from "../HoverableCircle";
import { styled } from "@mui/system";
import Question from "../../icons/Question";
var style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "764px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    padding: "24px",
};
var ActionInfoSection = function (_a) {
    var action = _a.action, displayName = _a.displayName, summaryPartText = _a.summaryPartText, theme = _a.theme;
    var content = {
        width: "40px",
        height: "40px",
    };
    var displayNameStyle = {
        fontFamily: "Avenir Medium",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.palette.text.primary,
    };
    var summaryStyle = {
        fontFamily: "Avenir Light",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "24px",
        color: theme.palette.info.dark,
        display: "flex",
    };
    return (_jsxs(Box, __assign({ sx: {
            display: "flex",
            alignItems: "center",
        } }, { children: [_jsx(Box, { children: _jsx(ActionCircle, { sx: content, action: action }) }), _jsxs(Box, __assign({ sx: { display: "flex", flexDirection: "column", marginLeft: "8px" } }, { children: [_jsx(Typography, __assign({ sx: displayNameStyle }, { children: displayName })), _jsx(Typography, __assign({ sx: summaryStyle }, { children: summaryPartText && (_jsx(Typography, __assign({ sx: {
                                fontFamily: "Avenir Light",
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: "16px",
                                lineHeight: "24px",
                            } }, { children: summaryPartText }))) }))] }))] })));
};
var CustomButton = styled(Button)({
    // marginBottom: "8px",
    "&:hover": {
        backgroundColor: "#ecfafb",
        boxShadow: "none",
    },
});
export default function StatusInfoPopUp(_a) {
    var _b, _c;
    var description = _a.description, popupText = _a.popupText;
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    var theme = useTheme();
    var buttonStyle = {
        background: "#b0f3fe",
        borderRadius: "24px",
        color: "#054D7B",
        padding: "8px 12px",
        boxShadow: "none",
        fontWeight: "bold",
    };
    var titleStyle = {
        fontFamily: "Avenir",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "42px",
        color: theme.palette.text.primary,
        flex: 1,
    };
    var dividerStyle = {
        height: "1px",
        marginTop: "16px",
        marginBottom: "16px",
    };
    return (_jsxs(Box, __assign({ "data-testid": "statusinfo" }, { children: [_jsx(Button, __assign({ "aria-label": "show status info", onClick: handleOpen, style: {
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                } }, { children: _jsx(Question, {}) })), _jsx(Modal, __assign({ open: open, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsxs(Box, __assign({ sx: style }, { children: [_jsxs(Box, __assign({ sx: {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            } }, { children: [_jsx(Typography, __assign({ id: "modal-modal-title", style: titleStyle }, { children: "What each status means" })), _jsx(HoverableCircle, __assign({ onClick: handleClose, sx: {
                                        padding: 0,
                                    } }, { children: _jsx(ClosingX, {}) }))] })), _jsx(Box, __assign({ sx: {
                                marginTop: "20px",
                                color: "#334255",
                                font: "16px/24px Avenir",
                            } }, { children: description })), _jsxs(Box, __assign({ id: "modal-modal-description", sx: { marginTop: "24px", marginBottom: "24px" } }, { children: [_jsx(ActionInfoSection, { action: Status.Act, displayName: "Act", summaryPartText: popupText === null || popupText === void 0 ? void 0 : popupText.act, theme: theme }), _jsx(Divider, { style: dividerStyle }), _jsx(ActionInfoSection, { action: Status.Watch, displayName: "Watch", summaryPartText: popupText === null || popupText === void 0 ? void 0 : popupText.watch, theme: theme }), _jsx(Divider, { style: dividerStyle }), _jsx(ActionInfoSection, { action: Status.Maintain, displayName: "Maintain", summaryPartText: popupText === null || popupText === void 0 ? void 0 : popupText.maintain, theme: theme }), _jsx(Divider, { style: dividerStyle }), _jsx(ActionInfoSection, { action: Status.NoData, displayName: ((_b = popupText === null || popupText === void 0 ? void 0 : popupText.noData) === null || _b === void 0 ? void 0 : _b.title) || "No leader data available", summaryPartText: ((_c = popupText === null || popupText === void 0 ? void 0 : popupText.noData) === null || _c === void 0 ? void 0 : _c.desc) ||
                                        "You don't have leader data for this specific function/level", theme: theme })] })), _jsx(CustomButton, __assign({ variant: "contained", "aria-label": "close status ok to close", onClick: handleClose, sx: buttonStyle }, { children: "Ok" }))] })) }))] })));
}
