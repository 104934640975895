// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hxPQ5gkLqEDaVuRves3q {
  color: var(--grey-color-90);
  font-family: Avenir Medium;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 16px 42px;
  position: absolute;
  top: 16px;
  left: 0;
}

.iUjLXirXNiZnQKp86dSn {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin: 0;
  padding: 0;
  width: 150%;
  position: relative;
  right: 50px;
}

.YTKqjccNSw4RTuxlBIjQ {
  width: 100%;
  padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/Additional.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;AACF;;AAEA;EACE,cAAA;EACA,WAAA;EACA,SAAA;EACA,6BAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;AACF","sourcesContent":[".panelHeader {\n  color: var(--grey-color-90);\n  font-family: Avenir Medium;\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 28px;\n  padding: 16px 42px;\n  position: absolute;\n  top: 16px;\n  left: 0;\n}\n\n.divider {\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid #e5e5e5;\n  margin: 0;\n  padding: 0;\n  width: 150%;\n  position: relative;\n  right: 50px;\n}\n\n.body {\n  width: 100%;\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelHeader": `hxPQ5gkLqEDaVuRves3q`,
	"divider": `iUjLXirXNiZnQKp86dSn`,
	"body": `YTKqjccNSw4RTuxlBIjQ`
};
export default ___CSS_LOADER_EXPORT___;
