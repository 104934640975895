var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Typography, styled } from "@mui/material";
var UserNotFound = function () {
    var Container = styled(Box)({
        textAlign: "center",
        padding: "50px 15px",
    });
    var UserNotFoundTitle = styled(Typography)({
        fontSize: "24px",
        fontWeight: "600",
        color: "#222222",
    });
    var UserNotFoundDesc = styled(Box)({
        fontSize: "15px",
        color: "#222222",
        padding: "16px",
    });
    return (_jsxs(Container, __assign({ "data-testid": "userNotFound" }, { children: [_jsx(UserNotFoundTitle, __assign({ variant: "h5" }, { children: "User Not Found" })), _jsx(UserNotFoundDesc, { children: "Sorry, the user you requested was not found." })] })));
};
export default UserNotFound;
