var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, styled, useTheme } from "@mui/material";
import React from "react";
var HoverableCircle = function (_a) {
    var children = _a.children, onClick = _a.onClick, sx = _a.sx;
    var usableSx = sx || {};
    var theme = useTheme();
    var ExpanderSymbolStyle = styled(Box)({
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: "transparent",
        width: "40px",
        height: "40px",
        borderRadius: "40px",
        "&:hover": {
            transition: "background .5s",
            // TODO: the designs had '#E0E1E5' here, but I don't know
            // how many colors we should be defining
            background: theme.palette.grey.A700 + "30",
            cursor: "pointer",
        },
    });
    var Circle = styled(Box)({
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
    });
    return (_jsx(ExpanderSymbolStyle, __assign({ onClick: onClick, "data-testid": "hoverableCircle", component: "span", sx: usableSx }, { children: _jsx(Circle, __assign({ className: "circle" }, { children: children })) })));
};
export default HoverableCircle;
