var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@mui/material";
import React from "react";
var Component = function (_a) {
    var sx = _a.sx;
    return (_jsxs(SvgIcon, __assign({ width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", sx: sx || [], "data-testid": "ClosingXIcon" }, { children: [_jsx("path", { d: "M1 21L21 1", stroke: "#002136", "stroke-width": "1.5" }), _jsx("path", { d: "M1 1.00003L21 21", stroke: "#002136", "stroke-width": "1.5" })] })));
};
export default Component;
