var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useContext } from "react";
import { willHold } from "../components/SpinnerAndErrorer/SpinnerAndErrorer";
import { FilterContext } from "../providers/FilterProvider";
import { useGetLeadershipRisksQuery, useGetLeadershipScoresQuery, useGetLeadershipOverviewQuery, useGetLeaderAtRiskScoresQuery, useGetPositionSuccessionOverviewQuery, useGetPositionSuccessionScoresQuery, useGetPositionSuccessionRisksQuery, useGetLeaderedSuccessionOverviewQuery, useGetLeaderedSuccessionScoresQuery, useGetLeaderedSuccessionRisksQuery, useGetLeaderAtRiskOverviewQuery, useGetLeadersAtRiskRisksQuery, } from "../store/api/core";
function fillEmpties(data, xLength, yLength, fillEmpty) {
    return Array.from(Array(yLength).keys()).map(function (yIndex) {
        return Array.from(Array(xLength).keys()).map(function (xIndex) { return (data[yIndex] || [])[xIndex] || fillEmpty(); });
    });
}
function useHeatmap(useGetOverviewQuery, useGetScoresQuery, useGetRisksQuery, fillEmpty, scoreType, riskRole) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var getFilters = useContext(FilterContext).getFilters;
    var filters = __assign(__assign({}, getFilters()), { scoreType: getFilters().dashboardScoreType });
    var scoresResponse = useGetScoresQuery({ filters: filters });
    var overview = useGetOverviewQuery({});
    var risksResponse = useGetRisksQuery({
        filters: filters,
        scoreType: scoreType,
        role: riskRole,
    });
    var headersConditions = [overview];
    var scoresConditions = [overview, scoresResponse, risksResponse];
    if (willHold(headersConditions)) {
        return { scoresConditions: scoresConditions, headersConditions: headersConditions };
    }
    var indexForLevelId = (_a = overview.data) === null || _a === void 0 ? void 0 : _a.indexForLevelId;
    var dataBreakdownHeaders = (_b = overview.data) === null || _b === void 0 ? void 0 : _b.dataBreakdownHeaders;
    var functionIndexes = ((_c = overview.data) === null || _c === void 0 ? void 0 : _c.functionIndexes) || {};
    var xHeaders = Object.entries(indexForLevelId).reduce(function (result, _a) {
        var levelId = _a[0], index = _a[1];
        result[index] = levelId;
        return result;
    }, ["All Levels"]);
    var yHeaders = __spreadArray([
        "All Functions"
    ], (((_d = overview.data) === null || _d === void 0 ? void 0 : _d.primaryFunctions) || []), true);
    var rawDataBreakdowns = {};
    for (var _i = 0, _o = Object.keys(dataBreakdownHeaders) || []; _i < _o.length; _i++) {
        var key = _o[_i];
        rawDataBreakdowns[key] = {
            headers: dataBreakdownHeaders[key].headers,
            data: [],
        };
    }
    var rawData = ((_e = scoresResponse.data) === null || _e === void 0 ? void 0 : _e.getRawData(rawDataBreakdowns, functionIndexes, indexForLevelId)) || [];
    var nameByLookupCode = __assign(__assign({}, (((_f = overview.data) === null || _f === void 0 ? void 0 : _f.lookupCodeMap) || {})), { "All Functions": "All Functions", "All Levels": "All Levels" });
    var data = rawData
        ? fillEmpties(rawData, xHeaders.length, yHeaders.length, fillEmpty)
        : [[]];
    var dataBreakdowns = ((_g = scoresResponse.data) === null || _g === void 0 ? void 0 : _g.getDataBreakdown(xHeaders.length, rawDataBreakdowns)) ||
        {};
    return {
        xHeaders: xHeaders,
        yHeaders: yHeaders,
        dataBreakdowns: dataBreakdowns,
        codeTypeMap: (_h = overview.data) === null || _h === void 0 ? void 0 : _h.codeTypeMap,
        data: data,
        riskyTeams: (_k = (_j = risksResponse === null || risksResponse === void 0 ? void 0 : risksResponse.data) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.high_risk_teams,
        riskyLeaders: (_m = (_l = risksResponse === null || risksResponse === void 0 ? void 0 : risksResponse.data) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.high_risk_entities,
        nameByLookupCode: nameByLookupCode,
        filters: filters,
        headersConditions: headersConditions,
        scoresConditions: scoresConditions,
    };
}
export function useHeatmapLeadershipScores(scoreType) {
    return function (_a) {
        var riskRole = _a.riskRole;
        return useHeatmap(useGetLeadershipOverviewQuery, useGetLeadershipScoresQuery, useGetLeadershipRisksQuery, function () { return ({ state: "nodata" }); }, scoreType, riskRole);
    };
}
export function useHeatmapLeadershipAtRiskScores(scoreType) {
    return function (_a) {
        var riskRole = _a.riskRole;
        return useHeatmap(useGetLeaderAtRiskOverviewQuery, useGetLeaderAtRiskScoresQuery, useGetLeadersAtRiskRisksQuery, function () { return ({ state: "nodata" }); }, scoreType, riskRole);
    };
}
export function useHeatmapPositionedSuccessionScores(scoreType) {
    return function (_a) {
        var riskRole = _a.riskRole;
        return useHeatmap(useGetPositionSuccessionOverviewQuery, useGetPositionSuccessionScoresQuery, useGetPositionSuccessionRisksQuery, function () { return ({ state: "nodata" }); }, scoreType, riskRole);
    };
}
export function useHeatmapLeaderedSuccessionScores(scoreType) {
    return function (_a) {
        var riskRole = _a.riskRole;
        return useHeatmap(useGetLeaderedSuccessionOverviewQuery, useGetLeaderedSuccessionScoresQuery, useGetLeaderedSuccessionRisksQuery, function () { return ({ state: "nodata" }); }, scoreType, riskRole);
    };
}
