var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from "./SuccessProfile.module.scss";
var SuccessProfileLegend = function () {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: styles.successProfileLegend }, { children: [_jsx("div", { children: "Undeveloped (1.00 - 1.99)" }), _jsx("div", { children: "Emerging (2.00 - 2.99)" }), _jsx("div", { children: "Proficient (3.00 - 3.99)" }), _jsx("div", { children: "Advanced (4.00 - 4.99)" }), _jsx("div", { children: "Expert (5.00)" })] })) }));
};
var SkillGroupHeading = function (props) {
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.skillGroupLayout }, { children: [_jsx("div", __assign({ className: styles.skillGroupTitle }, { children: props.title })), _jsxs("div", __assign({ className: styles.successProfileLegendAndTitleContainer }, { children: [_jsx("div", { children: _jsx(SuccessProfileLegend, {}) }), _jsxs("div", __assign({ className: styles.targetHeadingLayout }, { children: [_jsx("div", { className: styles.targetRectangle }), _jsx("div", __assign({ className: styles.targetHeading }, { children: "Target" }))] }))] }))] })), _jsx("div", __assign({ className: styles.skillGroupDescription }, { children: props.description }))] }));
};
export default SkillGroupHeading;
