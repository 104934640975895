// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HPoPeqyIVjYt_ygQFkCa {
  padding: 16px 32px;
}
.HPoPeqyIVjYt_ygQFkCa .FvYrMteqyiF76tFSqRnV {
  margin: 0 -16px;
}
.HPoPeqyIVjYt_ygQFkCa .sjzAnGnC0S47pkoOUjYN {
  padding-top: 0;
}
.HPoPeqyIVjYt_ygQFkCa .sjzAnGnC0S47pkoOUjYN .mlL1JeDToV9HWnP9rMuQ {
  display: none;
}
.HPoPeqyIVjYt_ygQFkCa .Fsmwvpddbqzc2KhwbXcB {
  padding-bottom: 0;
  margin-bottom: -16px;
}

.jCy0iFEH0503EJIbpRP5 {
  padding: 0 32px;
}

.Z2_bFKP4GaRltelHTkGA {
  margin: 32px 0;
}

.mlL1JeDToV9HWnP9rMuQ {
  margin: 32px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/SuccessProfilesSkillGroup/SkillGroupAccordion/SkillGroupAccordion.module.scss"],"names":[],"mappings":"AAIA;EACE,kBAAA;AAHF;AAKE;EACE,eAAA;AAHJ;AAME;EACE,cAAA;AAJJ;AAMI;EACE,aAAA;AAJN;AAQE;EACE,iBAAA;EACA,oBAAA;AANJ;;AAUA;EACE,eAAA;AAPF;;AAUA;EACE,cAAA;AAPF;;AAUA;EACE,yBAAA;AAPF","sourcesContent":[".skillContainer {\n  // padding: 32px 0;\n}\n\n.accordionContainer {\n  padding: 16px 32px;\n\n  .skillDividerContainer {\n    margin: 0 -16px;\n  }\n\n  .firstSkillContainer {\n    padding-top: 0;\n\n    .divider {\n      display: none;\n    }\n  }\n\n  .lastSkillContainer {\n    padding-bottom: 0;\n    margin-bottom: -16px;\n  }\n}\n\n.skillListContainer {\n  padding: 0 32px;\n}\n\n.skillList {\n  margin: 32px 0;\n}\n\n.divider {\n  margin: 32px 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionContainer": `HPoPeqyIVjYt_ygQFkCa`,
	"skillDividerContainer": `FvYrMteqyiF76tFSqRnV`,
	"firstSkillContainer": `sjzAnGnC0S47pkoOUjYN`,
	"divider": `mlL1JeDToV9HWnP9rMuQ`,
	"lastSkillContainer": `Fsmwvpddbqzc2KhwbXcB`,
	"skillListContainer": `jCy0iFEH0503EJIbpRP5`,
	"skillList": `Z2_bFKP4GaRltelHTkGA`
};
export default ___CSS_LOADER_EXPORT___;
