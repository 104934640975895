var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import React from "react";
export var defaultColorsToUse = [
    {
        background: "#2C8CC9",
        border: "#054D7B",
    },
    {
        background: "#7BE4F4",
        border: "#0B7B8B",
    },
    {
        background: "#FFCD78",
        border: "#9D6309",
    },
    {
        background: "#51ab93",
        border: "#0e2e26",
    },
    {
        background: "#E2E575",
        border: "#6D6F00",
    },
    {
        background: "#8CE1CA",
        border: "#2B715F",
    },
    {
        background: "#A9B0F5",
        border: "#414996",
    },
    {
        background: "#FFA3A3",
        border: "#993838",
    },
];
var polarToGrid = function (radius, angle) {
    return {
        y: -Math.cos(angle) * radius,
        x: Math.sin(angle) * radius,
    };
};
var CurvePiece = function (_a) {
    var startRadians = _a.startRadians, endRadians = _a.endRadians, color = _a.color, radius = _a.radius;
    var chunkLength = (endRadians - startRadians) / 3;
    var startMiddleRadians = startRadians + chunkLength;
    var endMiddleRadians = startRadians + chunkLength * 2;
    var correctedRadius = radius - 2;
    var thickness = 20;
    var outerStart = polarToGrid(correctedRadius, startRadians);
    var outerEnd = polarToGrid(correctedRadius, endRadians);
    var outerMiddleStart = polarToGrid(correctedRadius, startMiddleRadians);
    var outerMiddleEnd = polarToGrid(correctedRadius, endMiddleRadians);
    var innerStart = polarToGrid(correctedRadius - thickness, startRadians);
    var innerEnd = polarToGrid(correctedRadius - thickness, endRadians);
    var innerMiddleStart = polarToGrid(correctedRadius - thickness, startMiddleRadians);
    var innerMiddleEnd = polarToGrid(correctedRadius - thickness, endMiddleRadians);
    var middle = startRadians + (endRadians - startRadians) / 2;
    var innerMiddle = polarToGrid(correctedRadius - thickness, middle);
    var outerMiddle = polarToGrid(correctedRadius, middle);
    var borders = [
        "M".concat(outerMiddleEnd.x, " ").concat(outerMiddleEnd.y, " ") +
            "A ".concat(correctedRadius, " ").concat(correctedRadius, " 0 0 1 ").concat(outerEnd.x, " ").concat(outerEnd.y, " ") +
            "L".concat(innerEnd.x, " ").concat(innerEnd.y, " ") +
            "A ".concat(correctedRadius - thickness, " ").concat(correctedRadius - thickness, " 0 0 0 ").concat(innerMiddleEnd.x, " ").concat(innerMiddleEnd.y),
        "M".concat(outerMiddleStart.x, " ").concat(outerMiddleStart.y, " ") +
            "A ".concat(correctedRadius, " ").concat(correctedRadius, " 0 0 0 ").concat(outerStart.x, " ").concat(outerStart.y, " ") +
            "L".concat(innerStart.x, " ").concat(innerStart.y, " ") +
            "A ".concat(correctedRadius - thickness, " ").concat(correctedRadius - thickness, " 0 0 1 ").concat(innerMiddleStart.x, " ").concat(innerMiddleStart.y),
        "M".concat(outerMiddleStart.x, " ").concat(outerMiddleStart.y, " ") +
            "A ".concat(correctedRadius, " ").concat(correctedRadius, " 0 0 1 ").concat(outerMiddleEnd.x, " ").concat(outerMiddleEnd.y, " "),
        "M".concat(innerMiddleEnd.x, " ").concat(innerMiddleEnd.y, " ") +
            "A ".concat(correctedRadius - thickness, " ").concat(correctedRadius - thickness, " 0 0 0 ").concat(innerMiddleStart.x, " ").concat(innerMiddleStart.y, " "),
    ];
    var fills = [
        "M".concat(outerMiddle.x, " ").concat(outerMiddle.y, " ") +
            "A ".concat(correctedRadius, " ").concat(correctedRadius, " 0 0 1 ").concat(outerEnd.x, " ").concat(outerEnd.y, " ") +
            "L".concat(innerEnd.x, " ").concat(innerEnd.y, " ") +
            "A ".concat(correctedRadius - thickness, " ").concat(correctedRadius - thickness, " 0 0 0 ").concat(innerMiddle.x, " ").concat(innerMiddle.y, " Z"),
        "M".concat(outerMiddle.x, " ").concat(outerMiddle.y, " ") +
            "A ".concat(correctedRadius, " ").concat(correctedRadius, " 0 0 0 ").concat(outerStart.x, " ").concat(outerStart.y, " ") +
            "L".concat(innerStart.x, " ").concat(innerStart.y, " ") +
            "A ".concat(correctedRadius - thickness, " ").concat(correctedRadius - thickness, " 0 0 1 ").concat(innerMiddle.x, " ").concat(innerMiddle.y, " Z"),
        "M".concat(outerMiddleStart.x, " ").concat(outerMiddleStart.y, " ") +
            "A ".concat(correctedRadius, " ").concat(correctedRadius, " 0 0 1 ").concat(outerMiddleEnd.x, " ").concat(outerMiddleEnd.y, " ") +
            "L".concat(innerMiddleEnd.x, " ").concat(innerMiddleEnd.y, " ") +
            "A ".concat(correctedRadius - thickness, " ").concat(correctedRadius - thickness, " 0 0 0 ").concat(innerMiddleStart.x, " ").concat(innerMiddleStart.y, " Z"),
    ];
    return (_jsxs(_Fragment, { children: [borders.map(function (path) { return (_jsx("path", { d: path, fill: "none", stroke: color.border, "stroke-width": "1px" })); }), fills.map(function (path) { return (_jsx("path", { d: path, fill: color.background })); })] }));
};
export var DonutChartSvg = function (_a) {
    var counts = _a.counts, radius = _a.radius, colorsToUse = _a.colorsToUse;
    var colors = colorsToUse || defaultColorsToUse;
    var total = counts.reduce(function (total, value) { return total + value; }, 0);
    var getRadians = function (value) { return (value * 2 * Math.PI) / total; };
    var getEnd = function (points) { var _a; return ((_a = points === null || points === void 0 ? void 0 : points[points.length - 1]) === null || _a === void 0 ? void 0 : _a.end) || 0; };
    var points = counts
        .reduce(function (result, value) { return __spreadArray(__spreadArray([], result, true), [
        {
            start: getEnd(result),
            end: getEnd(result) + value,
        },
    ], false); }, [])
        .map(function (_a) {
        var start = _a.start, end = _a.end;
        return ({
            start: getRadians(start),
            end: getRadians(end),
        });
    });
    return (_jsx("svg", __assign({ viewBox: "".concat(-radius, " ").concat(-radius, " ").concat(radius * 2, " ").concat(radius * 2) }, { children: points.map(function (_a, index) {
            var start = _a.start, end = _a.end;
            return (_jsx(CurvePiece, { startRadians: start, endRadians: end, radius: radius, color: colors[index % colors.length] }));
        }) })));
};
export var DonutChart = function (_a) {
    var counts = _a.counts, radius = _a.radius, children = _a.children;
    return (_jsxs(Box, __assign({ sx: { width: "298px" }, "data-testid": "DonutChart" }, { children: [children, _jsx(DonutChartSvg, { counts: counts, radius: radius })] })));
};
export var HeadCountDonutChart = function (_a) {
    var counts = _a.counts, radius = _a.radius;
    return (_jsx(Box, __assign({ sx: { height: "".concat(radius * 2, "px"), width: "".concat(radius * 2, "px") } }, { children: _jsx(DonutChart, __assign({ counts: counts, radius: radius }, { children: _jsxs(Box, __assign({ sx: {
                    position: "absolute",
                    margin: "110px",
                } }, { children: [_jsx(Box, __assign({ sx: {
                            fontSize: "32px",
                            fontFamily: "Avenir Heavy",
                            fontWeight: 400,
                            lineHeight: "40px",
                            width: "100%",
                            textAlign: "center",
                        } }, { children: counts.reduce(function (total, count) { return total + count; }, 0) })), _jsx(Box, __assign({ sx: {
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            fontFamily: "Avenir Book",
                        } }, { children: "Headcount" }))] })) })) })));
};
