var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { FormControlLabel, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
export var BpCheckbox = function (props) {
    var HDChkBoxIcon = styled("span")(function (_a) {
        var theme = _a.theme;
        return ({
            borderRadius: 4,
            width: 24,
            height: 24,
            border: "3px solid " + (props.value ? "#054D7B" : "#4F5666"),
            backgroundColor: props.value ? "#7BE4FE" : "#fff",
            ".Mui-focusVisible &": props.omitFocusStyling
                ? {}
                : {
                    outline: "2px auto rgba(19,124,189,.6)",
                    outlineOffset: 2,
                },
            "input:hover ~ &": {
                border: "2px solid " +
                    (props.error
                        ? theme.palette.mode === "dark"
                            ? "#E449BD"
                            : "#C02195"
                        : theme.palette.mode === "dark"
                            ? "#197E95"
                            : "#18768C"),
            },
            "input:focus ~ &": props.omitFocusStyling
                ? {}
                : {
                    border: "2px double " +
                        (props.error
                            ? theme.palette.mode === "dark"
                                ? "#E449BD"
                                : "#C02195"
                            : theme.palette.mode === "dark"
                                ? "#197E95"
                                : "#18768C"),
                },
            "input:disabled ~ &": {
                boxShadow: "none",
                backgroundColor: theme.palette.mode === "dark" ? "#334255" : "#CCCCCC",
                border: "none",
                backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjU5IDBMNiA3LjU4TDEuNDEgM0wwIDQuNDFMNiAxMC40MUwxNSAxLjQxTDEzLjU5IDBaIiBmaWxsPSIjNjQ2QTdDIi8+Cjwvc3ZnPgo=)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
            },
        });
    });
    var HDCheckedIcon = styled(HDChkBoxIcon)(function (_a) {
        var theme = _a.theme;
        return ({
            display: "block",
            width: 24,
            height: 24,
            color: "#054D7B",
            backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjU5IDBMNiA3LjU4TDEuNDEgM0wwIDQuNDFMNiAxMC40MUwxNSAxLjQxTDEzLjU5IDBaIiBmaWxsPSIjMDAyMTM2Ii8+Cjwvc3ZnPgo=)",
            content: '""',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
        });
    });
    return (_jsx(Checkbox, __assign({ sx: {
            "&:hover": { bgcolor: "transparent" },
        }, disableRipple: true, color: "default", checkedIcon: _jsx(HDCheckedIcon, {}), icon: _jsx(HDChkBoxIcon, {}), inputProps: { "aria-label": "Checkbox demo" }, checked: props.value }, props)));
};
var HDCheckBox = function (props) {
    var _a;
    var theme = (_a = useTheme()) === null || _a === void 0 ? void 0 : _a.palette.mode;
    var StyledBoxWrapper = styled(Box)(function () { return ({
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "10px 0px 10px 20px",
        background: theme === "light" ? "#F8F8F8" : "#002136",
        border: theme === "light" && props.error
            ? "2px solid #C02195"
            : props.error
                ? "2px solid #E449BD"
                : "",
        borderRadius: "8px",
        ":hover": {
            border: !props.error && !props.disabled && "2px solid #18768C",
        },
    }); });
    var StyledFormControlLabel = styled(FormControlLabel)(function () { return ({
        color: theme === "light" ? "#002136" : "#FFFFFF",
        ".MuiFormControlLabel-label": {
            fontWeight: "500",
            color: theme === "light"
                ? props.disabled
                    ? "#646A7C  !important"
                    : "#002136"
                : props.disabled
                    ? "#A9A9A9  !important"
                    : "#FFFFFF",
            fontFamily: "Aventa",
            fontStyle: "normal",
        },
    }); });
    return (_jsx(Box, { children: _jsx(StyledBoxWrapper, { children: _jsx(StyledFormControlLabel, { value: props.value, label: props.labelTxt, disabled: props.disabled, control: _jsx(BpCheckbox, { disabled: props.disabled, defaultChecked: props.defaultChecked, value: !!props.value, onChange: props.onChange }) }) }) }));
};
export default HDCheckBox;
