var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var ChevronRight = function () { return (_jsx("svg", __assign({ width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("g", __assign({ id: "Icon 24/Chevron-right" }, { children: _jsx("path", { id: "Rectangle 160", d: "M5.3335 2.66663L10.6668 7.99996L5.3335 13.3333", stroke: "#002136", strokeWidth: "1.5" }) })) }))); };
export default ChevronRight;
