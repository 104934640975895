// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../HDFonts/AventaFont/Aventa-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../HDFonts/AvenirFont/AvenirLTProLight.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../HDFonts/InterFont/Inter-VariableFont_slnt,wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../HDFonts/AvenirFont/AvenirLTProMedium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../HDFonts/AventaFont/Aventa-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../HDFonts/AvenirFont/AvenirLTProHeavy.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Aventa";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
@font-face {
  font-family: "Avenir";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}
@font-face {
  font-family: "Inter";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
}
@font-face {
  font-family: "Avenir Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("opentype");
}
@font-face {
  font-family: "Aventa Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("opentype");
}
@font-face {
  font-family: "Avenir Heavy";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("opentype");
}`, "",{"version":3,"sources":["webpack://./src/hd-ui-kit/HDTheme.css"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,+DAAA;AACF;AAEA;EACE,qBAAA;EACA,+DAAA;AAAF;AAGA;EACE,oBAAA;EACA,+DAAA;AADF;AAKA;EACE,4BAAA;EACA,+DAAA;AAHF;AAMA;EACE,4BAAA;EACA,+DAAA;AAJF;AAOA;EACE,2BAAA;EACA,+DAAA;AALF","sourcesContent":["@font-face {\n  font-family: \"Aventa\";\n  src: url(\"../HDFonts/AventaFont/Aventa-Regular.ttf\") format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"Avenir\";\n  src: url(\"../HDFonts/AvenirFont/AvenirLTProLight.otf\") format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"Inter\";\n  src: url(\"../HDFonts/InterFont/Inter-VariableFont_slnt,wght.ttf\")\n    format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"Avenir Medium\";\n  src: url(\"../HDFonts/AvenirFont/AvenirLTProMedium.otf\") format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"Aventa Medium\";\n  src: url(\"../HDFonts/AventaFont/Aventa-Medium.ttf\") format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"Avenir Heavy\";\n  src: url(\"../HDFonts/AvenirFont/AvenirLTProHeavy.otf\") format(\"opentype\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
