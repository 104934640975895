var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled, Box } from "@mui/material";
import { CheckBox as OctupleCheckBox, SelectorSize, LabelAlign, } from "@eightfold.ai/octuple";
var CheckBox = function (props) {
    var CheckBoxContainer = styled(Box)({
        "--font-stack-full": "Avenir Book",
        ".checkbox [class^='selector-label']": {
            top: "1px",
            position: "relative",
        },
    });
    return (_jsx(CheckBoxContainer, __assign({ "data-testid": "checkbox-".concat(props.value) }, { children: _jsx(OctupleCheckBox, { classNames: "checkbox", value: props.value, label: props.label, checked: props.checked || false, size: props.size || SelectorSize.Large, labelAlign: LabelAlign.Center, onChange: props.onChange }) })));
};
export default CheckBox;
