import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled } from "@mui/material";
var StyledCircle = styled("div")({
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#F1F1F1",
    border: "1px solid #00213620",
});
var CircleComponent = function () {
    return _jsx(StyledCircle, {});
};
export default CircleComponent;
