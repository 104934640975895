import { useGetSkillsCategoryQuery, useGetStatementLibrariesQuery, useGetClientModelsQuery, } from "../../store/api/core";
import { PillVariant } from "../Pill/Pill";
export var VeryImportantSkillTag = {
    text: "Very Important",
    variant: PillVariant.Warning,
};
export var CriticalSkillTag = {
    text: "Critical",
    variant: PillVariant.Error,
};
var importanceRatingMappings = {
    "Very Important": VeryImportantSkillTag,
    Critical: CriticalSkillTag,
};
export var useImpactDataSource = function (clientId, roleProfileId, skillGroup) {
    var _a, _b, _c, _d;
    var impactData = { description: "", skills: [] };
    var skillCategoryResponse = useGetSkillsCategoryQuery({
        clientId: clientId,
        roleProfileId: roleProfileId,
    });
    var skillCategoryResponseData = (_b = (_a = skillCategoryResponse === null || skillCategoryResponse === void 0 ? void 0 : skillCategoryResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0];
    var statementLibrariesResponse = useGetStatementLibrariesQuery({
        clientId: clientId,
        skillGroup: skillGroup,
    });
    var statementLibrariesResponseData = (_c = statementLibrariesResponse === null || statementLibrariesResponse === void 0 ? void 0 : statementLibrariesResponse.data) === null || _c === void 0 ? void 0 : _c.data;
    if (!skillCategoryResponseData || !statementLibrariesResponseData) {
        return impactData;
    }
    var statementLibraryMap = statementLibrariesResponseData.reduce(function (acc, library) {
        acc[library.statementLibraryUUID] = {
            name: (library === null || library === void 0 ? void 0 : library.name) || "",
            description: (library === null || library === void 0 ? void 0 : library.description) || "",
            impactModuleName: (library === null || library === void 0 ? void 0 : library.impactModuleName) || "",
        };
        return acc;
    }, {});
    var impactModules = {};
    var skillGroupData = (_d = skillCategoryResponseData.skillGroups.filter(function (group) { return group.name === skillGroup; })) === null || _d === void 0 ? void 0 : _d[0];
    skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.skills.forEach(function (skill) {
        var lookups = statementLibraryMap[skill.statementLibraryUUID];
        var impactModule = (lookups === null || lookups === void 0 ? void 0 : lookups.impactModuleName) || "";
        if (!impactModules[impactModule]) {
            impactModules[impactModule] = [];
        }
        if (Object.keys(skill).length > 0) {
            impactModules[impactModule].push({
                name: (lookups === null || lookups === void 0 ? void 0 : lookups.name) || "",
                description: (lookups === null || lookups === void 0 ? void 0 : lookups.description) || "",
                benchmark: skill.benchmark,
                tag: skill.importanceRating == "Very Important" ||
                    skill.importanceRating == "Critical"
                    ? importanceRatingMappings[skill.importanceRating]
                    : undefined,
            });
        }
    });
    Object.keys(impactModules)
        .sort(function (a, b) { return a.localeCompare(b); })
        .forEach(function (key) {
        impactData.skills.push({
            summary: key,
            skills: impactModules[key].sort(function (a, b) { var _a, _b; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = b.name) === null || _b === void 0 ? void 0 : _b.toString()); }),
        });
    });
    impactData["description"] = skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.description;
    return impactData;
};
export var useCapabilitiesPotentialDataSource = function (clientId, roleProfileId, skillGroup) {
    var _a, _b, _c, _d, _e;
    var resultingData = { description: "", skills: [] };
    var skillCategoryResponse = useGetSkillsCategoryQuery({
        clientId: clientId,
        roleProfileId: roleProfileId,
    });
    var capabilitiesData = (_b = (_a = skillCategoryResponse === null || skillCategoryResponse === void 0 ? void 0 : skillCategoryResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0];
    var capabilitiesClientModelResponse = useGetClientModelsQuery({
        clientId: clientId,
        skillGroupName: "Capabilities",
    });
    var potentialClientModelResponse = useGetClientModelsQuery({
        clientId: clientId,
        skillGroupName: "Potential",
    });
    var capabilitiesClientModelResponseData = (_c = capabilitiesClientModelResponse === null || capabilitiesClientModelResponse === void 0 ? void 0 : capabilitiesClientModelResponse.data) === null || _c === void 0 ? void 0 : _c.data;
    var potentialClientModelResponseData = (_d = potentialClientModelResponse === null || potentialClientModelResponse === void 0 ? void 0 : potentialClientModelResponse.data) === null || _d === void 0 ? void 0 : _d.data;
    if (!capabilitiesData ||
        !capabilitiesClientModelResponseData ||
        !potentialClientModelResponseData) {
        return resultingData;
    }
    var clientModelsData = capabilitiesClientModelResponseData.concat(potentialClientModelResponseData);
    var clientModelMap = clientModelsData
        .filter(function (model) { return model.clientId == clientId || "Master"; })
        .reduce(function (acc, model) {
        acc[model.id] = {
            name: (model === null || model === void 0 ? void 0 : model.name) || "",
            description: (model === null || model === void 0 ? void 0 : model.description) || "",
        };
        return acc;
    }, {});
    var skillGroupData = (_e = capabilitiesData.skillGroups.filter(function (group) { return group.name === skillGroup; })) === null || _e === void 0 ? void 0 : _e[0];
    var skills = (skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.skills.filter(function (skill) {
        return skill.clientModelId;
    })) || [];
    resultingData.skills = [
        {
            summary: "",
            skills: skills
                .map(function (skill) {
                if (Object.keys(skill).length > 0) {
                    var lookups = clientModelMap[skill.clientModelId || ""];
                    return {
                        name: (lookups === null || lookups === void 0 ? void 0 : lookups.name) || "",
                        description: (lookups === null || lookups === void 0 ? void 0 : lookups.description) || "",
                        benchmark: skill.benchmark || 0,
                        tag: skill.importanceRating == "Very Important" ||
                            skill.importanceRating == "Critical"
                            ? importanceRatingMappings[skill.importanceRating]
                            : undefined,
                    };
                }
                else {
                    return { name: "", description: "", benchmark: 0, tag: undefined };
                }
            })
                .sort(function (a, b) { var _a, _b; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = b.name) === null || _b === void 0 ? void 0 : _b.toString()); }),
        },
    ];
    resultingData.description = skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.description;
    return resultingData;
};
