import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { useGetClientPlatformQuery, generateFileName, getClientId, apiBase, getLeaderScoresParamString, } from "../../store/api/core";
import DownloadButton from "../DownloadButton/DownloadButton";
import { FilterContext } from "../../providers/FilterProvider";
var SuccessionDownloadCsv = function () {
    var _a, _b, _c;
    var getFilters = useContext(FilterContext).getFilters;
    var platformClients = useGetClientPlatformQuery({});
    var clientName = ((_c = (_b = (_a = platformClients === null || platformClients === void 0 ? void 0 : platformClients.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.name) || "Your";
    var baseFileName = generateFileName({
        fileName: "Succession Plans",
        clientName: clientName,
        format: ".csv",
    });
    var clientId = getClientId() || "";
    var csvFilters = getFilters();
    var url = "".concat(apiBase.dashboard, "/").concat(encodeURIComponent(clientId), "/position-succession/leader-scores/download?");
    var filterString = getLeaderScoresParamString(csvFilters);
    if (filterString) {
        url += "&".concat(filterString);
    }
    if (csvFilters === null || csvFilters === void 0 ? void 0 : csvFilters.scoreType) {
        url += "&score_type=".concat(csvFilters.scoreType);
    }
    return (_jsx(DownloadButton, { buttonText: "Download CSV", filename: baseFileName, url: url }));
};
export default SuccessionDownloadCsv;
