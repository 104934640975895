var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, styled, useTheme } from "@mui/material";
import React from "react";
import ActionCircle from "../ActionCircle";
import PeopleSmall from "../../icons/PeopleSmall";
import { ScoreDescriptions, ScoreTitles, SuccesionScoreDescriptions, } from "../../common/action";
import { sortActionables } from "../ActionCircle/ActionCircle";
import RightArrow from "../RightArrow/RightArrow";
import ChevronRight from "../../icons/ChevronRight";
var ExpandableDropdownTitleStyled = styled(Box)({
    position: "relative",
    top: "2px",
    font: "300 16px/24px Avenir",
});
var UnexpandableDropdown = function (_a) {
    var title = _a.title, onClick = _a.onClick;
    var Container = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "8px 0",
            borderRadius: "14px",
            padding: "16px",
            background: theme.palette.primary.contrastText,
            border: "1px solid rgba(0, 33, 54, 0.20)",
            fontWeight: "300",
            fontSize: "16px",
            cursor: "pointer",
        });
    });
    return (_jsxs(Container, __assign({ onClick: onClick, "data-testid": "closed-dropdown-".concat(title) }, { children: [_jsx(ExpandableDropdownTitleStyled, { children: title }), _jsx(ChevronRight, {})] })));
};
var FirstDividerStyled = styled(Divider)({
    marginBottom: "10px",
});
var DividerStyled = styled(Divider)({
    marginTop: "10px",
    marginBottom: "10px",
});
export var RowBase = function (_a) {
    var icon = _a.icon, text = _a.text, fontSize = _a.fontSize, fontWeight = _a.fontWeight, fontFamily = _a.fontFamily, description = _a.description, textSx = _a.textSx, onClick = _a.onClick, isRoledBased = _a.isRoledBased;
    var theme = useTheme();
    var Container = styled(Box)({
        display: "flex",
    });
    var Text = styled(Box)({
        marginLeft: "8px",
        color: theme.palette.success.contrastText,
        fontSize: fontSize,
        fontWeight: fontWeight,
        fontFamily: fontFamily,
    });
    var Line = styled(Box)({
        position: "relative",
        marginBottom: "0",
        top: "2px",
        left: "8px",
        width: "1px",
        background: theme.palette.primary.main,
    });
    var Description = styled(Box)({
        color: theme.palette.primary.main,
        marginLeft: "8px",
        marginRight: "8px",
        position: "relative",
        top: "2px",
        left: "8px",
        fontStyle: "normal",
        fontFamily: "Avenir",
        fontWeight: "300",
    });
    return (_jsxs(Container, { children: [_jsx(Box, __assign({ sx: {
                    position: "relative",
                    top: "5px",
                }, component: "span" }, { children: icon })), _jsx(Text, __assign({ sx: textSx || {} }, { children: _jsx(Box, __assign({ component: "span", sx: __assign({ position: "relative", top: "7px" }, (isRoledBased ? { width: "600px", top: "15px" } : {})) }, { children: text })) })), description && (_jsxs(_Fragment, { children: [_jsx(Line, {}), _jsx(Description, __assign({ sx: { top: "6px" } }, { children: description }))] })), isRoledBased ? (_jsx(Box, __assign({ sx: { position: "relative", top: "11px" } }, { children: _jsx(RightArrow, { onClick: onClick || (function () { return undefined; }) }) }))) : (onClick && (_jsx(Box, __assign({ sx: { position: "relative", top: "3px" } }, { children: _jsx(RightArrow, { onClick: onClick || (function () { return undefined; }) }) }))))] }));
};
export var Breakdown = function (_a) {
    var action = _a.action, text = _a.text, description = _a.description, onClick = _a.onClick, isRoledBased = _a.isRoledBased;
    return (_jsx(RowBase, { icon: _jsx(ActionCircle, { action: action, size: isRoledBased ? "big" : "small" }), isRoledBased: isRoledBased, fontSize: "16px", fontWeight: "500", text: text, fontFamily: "Avenir Medium", textSx: {
            maxWidth: isRoledBased ? "400px" : "260px",
            minWidth: "150px",
        }, description: description, onClick: onClick }));
};
export var BreakdownSet = function (_a) {
    var scoreData = _a.scoreData, omitFirstDivider = _a.omitFirstDivider, showDescriptions = _a.showDescriptions, arrowClickables = _a.arrowClickables, isRoledBased = _a.isRoledBased, succesionScoreData = _a.succesionScoreData;
    var _b = isRoledBased ? succesionScoreData : scoreData, statement1 = _b.statement1, statement2 = _b.statement2, percentage1 = _b.percentage1, percentage2 = _b.percentage2, capability = _b.capability, organizational = _b.organizational, functional = _b.functional, potential = _b.potential;
    var getTexts = function (breakdownPart) { return ({
        text: ScoreTitles[breakdownPart],
        description: showDescriptions
            ? ScoreDescriptions[breakdownPart]
            : undefined,
    }); };
    var succesionGetTexts = function (percentage, breakdownPart) { return ({
        text: "".concat(percentage, "% ").concat(SuccesionScoreDescriptions[breakdownPart]),
    }); };
    return (_jsx(_Fragment, { children: sortActionables(isRoledBased
            ? [
                __assign({ action: statement1, onClick: arrowClickables === null || arrowClickables === void 0 ? void 0 : arrowClickables.statement1 }, succesionGetTexts(percentage1, "statement1")),
                __assign({ action: statement2, onClick: arrowClickables === null || arrowClickables === void 0 ? void 0 : arrowClickables.statement2 }, succesionGetTexts(percentage2, "statement2")),
            ]
            : [
                __assign({ action: capability, onClick: arrowClickables === null || arrowClickables === void 0 ? void 0 : arrowClickables.capability }, getTexts("capability")),
                __assign({ action: functional, onClick: arrowClickables === null || arrowClickables === void 0 ? void 0 : arrowClickables.functional }, getTexts("functional")),
                __assign({ action: organizational, onClick: arrowClickables === null || arrowClickables === void 0 ? void 0 : arrowClickables.organizational }, getTexts("organizational")),
                __assign({ action: potential, onClick: arrowClickables === null || arrowClickables === void 0 ? void 0 : arrowClickables.potential }, getTexts("potential")),
            ])
            .map(function (action) { return _jsx(Breakdown, __assign({ isRoledBased: isRoledBased }, action)); })
            .map(function (breakdown, index) { return (_jsx(Box, { children: _jsxs(_Fragment, { children: [index ? (_jsx(DividerStyled, {})) : (omitFirstDivider && _jsx(FirstDividerStyled, {})), breakdown] }) }, "score row ".concat(index))); }) }));
};
export var LeaderCount = function (_a) {
    var count = _a.count;
    return (_jsx(RowBase, { icon: _jsx(PeopleSmall, {}), text: "".concat(count, " Leader").concat(count == 1 ? "" : "s"), fontSize: "14px", fontWeight: "500", fontFamily: "Avenir", textSx: {
            position: "relative",
            top: "2px",
        } }));
};
var AdditionalRiskCard = function (_a) {
    var title = _a.title, rows = _a.rows, emptyMessage = _a.emptyMessage, sx = _a.sx;
    var Container = styled(Box)({
        borderRadius: "14px",
        background: "#F6F7F8",
        padding: "24px",
        verticalAlign: "top",
    });
    var TitleContainer = styled(Box)({
        display: "flex",
    });
    var TitleStyled = styled(Box)({
        position: "relative",
        top: "6px",
        left: "6px",
        font: "500 20px/29px Avenir Medium",
    });
    var DividerStyled = styled(Divider)({
        margin: "16px 0",
    });
    var EmptyMessageStyled = styled(Box)({
        fontFamily: "Avenir",
        fontSize: "16px",
        fontWeight: "300",
    });
    return (_jsxs(Container, __assign({ sx: sx ? sx : {}, "data-testid": "AdditionalRiskCard" }, { children: [_jsx(TitleContainer, { children: _jsx(TitleStyled, __assign({ component: "span" }, { children: title })) }), _jsx(DividerStyled, {}), rows && rows.length ? (rows.map(function (_a, index) {
                var title = _a.title, onClick = _a.onClick;
                return (_jsx(UnexpandableDropdown, { onClick: onClick, title: title }, index));
            })) : (_jsx(EmptyMessageStyled, { children: emptyMessage }))] })));
};
export default AdditionalRiskCard;
