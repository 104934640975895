var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, styled } from "@mui/material";
import React from "react";
import { useNavigate } from "../../hooks/navigate";
import BackButtonArrow from "../../icons/BackButtonArrow";
var BackToButton = function (props) {
    var navigate = useNavigate();
    var Container = styled(Box)({
        width: "250px",
        height: "24px",
        marginBottom: "6px",
    });
    var BackToButton = styled("a")(function (_a) {
        var theme = _a.theme;
        return ({
            fontSize: "16px",
            fontFamily: theme.typography.fontFamily + " Medium",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
            color: theme.palette.primary.main,
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
        });
    });
    var ButtonTitle = styled("span")({
        marginLeft: "8px",
    });
    return (_jsx(_Fragment, { children: _jsx(Container, __assign({ "data-testid": "backToButton" }, { children: _jsxs(BackToButton, __assign({ href: props.url, target: "", onClick: function (event) {
                    event.preventDefault();
                    props.onClick && props.onClick();
                    navigate(props.url);
                } }, { children: [_jsx(BackButtonArrow, {}), _jsx(ButtonTitle, { children: props.title })] })) })) }));
};
export default BackToButton;
