// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  width: 35px;
  height: 35px;
  border: 4px solid transparent;
  border-left-color: #146da6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/hd-ui-kit/HDSpinner/HDSpinner.css"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,0BAAA;EACA,kBAAA;EACA,kCAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF","sourcesContent":[".spinner {\n  width: 35px;\n  height: 35px;\n  border: 4px solid transparent;\n  border-left-color: #146da6;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
