var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Dialog as OctupleDialog, DialogSize } from "@eightfold.ai/octuple";
import styles from "./Dialog.module.css";
var Dialog = function (props) {
    var _a, _b;
    var cancelButton = (_a = props.buttons) === null || _a === void 0 ? void 0 : _a.cancelButtonProps;
    var okButton = (_b = props.buttons) === null || _b === void 0 ? void 0 : _b.okButtonProps;
    var buttonProps = {};
    if (cancelButton) {
        buttonProps["cancelButtonProps"] = __assign(__assign({}, cancelButton), { text: cancelButton.text || "Cancel", ariaLabel: "".concat(cancelButton.text || "Cancel", " button"), classNames: "dialog-cancel-btn-class", "data-testid": "dialog-cancel-btn-test-id" });
        buttonProps["onCancel"] = cancelButton.onClick || props.onClose;
    }
    if (okButton) {
        buttonProps["okButtonProps"] = __assign(__assign({}, okButton), { text: okButton.text || "OK", ariaLabel: "".concat(okButton.text || "OK", " button"), classNames: "dialog-ok-btn-class", "data-testid": "dialog-ok-btn-test-id" });
        buttonProps["onOk"] = okButton.onClick;
    }
    return (_jsx(OctupleDialog, __assign({ dialogWrapperClassNames: styles.dialogWrapper, dialogClassNames: styles.dialog, headerClassNames: styles.dialogHeader, bodyClassNames: styles.dialogBody, bodyPadding: true, "data-testid": "dialog-test-id", maskClosable: true, overlay: true, positionStrategy: "fixed", renderContentAlways: true, size: DialogSize.medium, zIndex: 1000 }, buttonProps, props)));
};
export default Dialog;
