var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Box, styled, Tabs, Tab } from "@mui/material";
import { useTheme } from "@mui/system";
import { FilterContext } from "../../providers/FilterProvider";
import { useLocation } from "../../hooks/location";
function TabPanel(props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", __assign({ role: "tabpanel", hidden: value !== index, id: "horizontal-tabpanel-".concat(index), "aria-labelledby": "horizontal-tab-".concat(index) }, other, { children: value === index && _jsx(Box, __assign({ sx: { padding: "3px" } }, { children: children })) })));
}
function a11yProps(index) {
    return {
        id: "horizontal-tab-".concat(index),
        "aria-controls": "horizontal-tabpanel-".concat(index),
    };
}
export var ControlledOverviewTabs = function (_a) {
    var downloadButton = _a.downloadButton, tabs = _a.tabs, sx = _a.sx;
    var hash = useLocation().hash;
    var hashByIndex = tabs.reduce(function (current, _a, index) {
        var _b;
        var hash = _a.hash;
        return Object.assign(current, (_b = {}, _b["#".concat(hash)] = index, _b));
    }, {});
    var selectedTab = hashByIndex[hash] || 0;
    return (_jsx(OverviewTabs, { downloadBtn: downloadButton, tabs: tabs.map(function (_a, index) {
            var label = _a.label, hash = _a.hash;
            return ({
                label: (_jsx("div", __assign({ onClick: function () {
                        window.location.hash = hash;
                        setTimeout(function () {
                            window.location.hash = hash;
                        }, 0);
                    }, style: {
                        height: "150%",
                    } }, { children: label }), index)),
            });
        }), data: tabs.map(function (_a) {
            var content = _a.content;
            return content;
        }), sx: sx, selectedTab: selectedTab }));
};
var OverviewTabs = function (props) {
    var tabs = props.tabs, data = props.data, selectedTab = props.selectedTab, sx = props.sx;
    var setFilters = useContext(FilterContext).setFilters;
    var theme = useTheme();
    var indexToHashMap = Object.fromEntries(tabs.map(function (tab, idx) { return [idx, tab.hash]; }));
    var _a = React.useState(selectedTab || 0), value = _a[0], setTabSelected = _a[1];
    React.useEffect(function () {
        setTabSelected(selectedTab || 0);
    }, [selectedTab]);
    var handleChange = function (event, newValue) {
        setTabSelected(newValue);
        var tabHash = indexToHashMap[newValue];
        if (tabHash) {
            window.location.hash = tabHash;
        }
        else {
            // Remove "#tab-name" from the URL
            history.pushState("", document.title, "".concat(window.location.pathname).concat(window.location.search));
        }
        setFilters({});
    };
    var WrapStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "-1px",
        alignSelf: "stretch",
        borderBottom: 1,
        borderColor: "divider",
        margin: "0 32px",
    };
    var TabStyle = {
        display: "flex",
        padding: 0,
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        marginRight: "32px",
        "&.Mui-selected": {
            color: theme.palette.blue.main,
        },
        minWidth: "unset",
    };
    var LabelStyle = styled("span")({
        fontFamily: "Avenir Medium",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "450",
        lineHeight: "24px",
        textTransform: "capitalize",
    });
    var tabIndicatorProps = {
        style: {
            backgroundColor: theme.palette.blue.main,
        },
    };
    return (_jsxs(Box, __assign({ sx: __assign({ padding: "16px 0px 0px" }, (sx || {})) }, { children: [_jsxs("div", __assign({ style: {
                    display: "flex",
                    borderBottom: "1px solid #d9dce1",
                    justifyContent: "space-between",
                } }, { children: [tabs.length > 0 && (_jsx(Tabs, __assign({ value: value, onChange: handleChange, indicatorColor: "secondary", sx: WrapStyle, "aria-label": "horizontal tabs example", TabIndicatorProps: tabIndicatorProps }, { children: tabs.map(function (tab, idx) { return (_jsx(Tab, __assign({ label: _jsx(LabelStyle, { children: tab.label }) }, a11yProps(idx), { sx: TabStyle }), "tab_".concat(idx))); }) }))), _jsx("div", __assign({ style: { marginRight: "10px" } }, { children: props.downloadBtn }))] })), _jsx("div", { children: data === null || data === void 0 ? void 0 : data.map(function (
                /* eslint-disable @typescript-eslint/no-explicit-any */
                panel, 
                /* eslint-disable @typescript-eslint/no-explicit-any */
                idx) { return (_jsx(TabPanel, __assign({ value: value, index: idx }, { children: panel }), "tabpanel_".concat(idx))); }) })] })));
};
export default OverviewTabs;
