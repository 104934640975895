// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WaZ8IdoIS8pFr7PosB1j {
  font-family: Avenir, sans-serif;
  font-size: 14px;
  color: var(--grey-color-60);
}

.U8F3Vgb9smDYhmK83SwD {
  font-family: Avenir Heavy, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(navbar-text-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/SuccessionPage/SuccessionPage.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,eAAA;EACA,2BAAA;AACF;;AAEA;EACE,qCAAA;EACA,eAAA;EACA,gBAAA;EACA,6BAAA;AACF","sourcesContent":[".leaderName {\n  font-family: Avenir, sans-serif;\n  font-size: 14px;\n  color: var(--grey-color-60);\n}\n\n.leaderRole {\n  font-family: Avenir Heavy, sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  color: var(navbar-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leaderName": `WaZ8IdoIS8pFr7PosB1j`,
	"leaderRole": `U8F3Vgb9smDYhmK83SwD`
};
export default ___CSS_LOADER_EXPORT___;
