var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
export var SpinnerContext = React.createContext({
    shouldSpin: false,
});
var SpinnerIncrementEventName = "spinnerIncrementEvent";
var SpinnerDecrementEventName = "spinnerDecrementEvent";
export var decrementSpinReason = function () {
    window.dispatchEvent(new CustomEvent(SpinnerDecrementEventName, {
        detail: 1,
    }));
};
export var incrementSpinReason = function () {
    window.dispatchEvent(new CustomEvent(SpinnerIncrementEventName, {
        detail: 1,
    }));
};
export var SpinnerProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(0), spinCount = _b[0], setSpinCount = _b[1];
    var decrementEventListener = function () {
        setSpinCount(function (count) { return count - 1; });
    };
    var incrementEventListener = function () {
        setSpinCount(function (count) { return count + 1; });
    };
    React.useEffect(function () {
        window.addEventListener(SpinnerIncrementEventName, incrementEventListener);
        window.addEventListener(SpinnerDecrementEventName, decrementEventListener);
        return function () {
            window.removeEventListener(SpinnerIncrementEventName, incrementEventListener);
            window.removeEventListener(SpinnerDecrementEventName, decrementEventListener);
        };
    }, []);
    var shouldSpin = spinCount > 0;
    return (_jsx(SpinnerContext.Provider, __assign({ value: { shouldSpin: shouldSpin } }, { children: children })));
};
