// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forwardArrow:hover {
  transform: translate(5px, 0px) scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/StrengthInfoPopup/StrengthInfoPopup.css"],"names":[],"mappings":"AAAA;EACE,yCAAA;AACF","sourcesContent":[".forwardArrow:hover {\n  transform: translate(5px, 0px) scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
