import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useGetPositionSuccessionOverviewQuery, useGetPositionSuccessionScoresQuery, useGetPositionSuccessionThresholdsQuery, } from "../../store/api/core";
import { ScoreBreakdown } from "../../components/Summary/Summary";
import HeatmappedPage from "../../components/HeatmappedPage/HeatmappedPage";
import StatusInfoPopUp from "../StatusInfoPopUp/StatusInfoPopUp";
import { Status } from "../../common/action";
import { LowPerformerLevelFunctionMatrix, percentageOfScoreFormatterBase, } from "../LevelFunctionMatrix/Variants";
import { useHeatmapPositionedSuccessionScores } from "../../hooks/heatmapScores";
import { updateFilters } from "../../providers/FilterProvider";
import { getRoute } from "../../routes/getAllRoutes";
import { SuccessionTypes, successionTooltips, } from "../../store/api/types/core";
import { PercentageLegend } from "../Legend/Legend";
import { useNavigate } from "../../hooks/navigate";
import SuccessionDownloadCsv from "../../components/SuccessionDownloadCsv";
var percentageOfScoreFormatter = percentageOfScoreFormatterBase;
var popupText = {
    act: "A high number of positions exhibit risks to leadership continuity. Recommend " +
        "taking action to address most critical areas of vulnerability.",
    watch: "A notable number of positions exhibit risks to leadership continuity. Recommend " +
        "additional review to identify any areas requiring immediate intervention.",
    maintain: "A manageable number of positions exhibit risks to leadership continuity. While risk " +
        "is generally low, proactive monitoring or review is still recommended.",
    noData: {
        title: "No position data available",
        desc: "You do not have position data for this specific function/level.",
    },
};
var LocalScoreBreakdown = function () {
    var _a, _b;
    var roledPositionOverview = useGetPositionSuccessionOverviewQuery({});
    var summaryStatus = (roledPositionOverview === null || roledPositionOverview === void 0 ? void 0 : roledPositionOverview.isSuccess)
        ? (_b = (_a = roledPositionOverview === null || roledPositionOverview === void 0 ? void 0 : roledPositionOverview.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.state
        : "No data";
    var summaryRightSideText = summaryStatus == Status.Act
        ? "This dashboard shows the degree and type of risks that are present in leadership " +
            "succession pipelines across your organization. The results indicate that a high number " +
            "of positions have one or more risks to leadership continuity. Explore the heatmap, " +
            "by group and metric, to identify the areas of greatest vulnerability for immediate, " +
            "targeted attention."
        : summaryStatus == Status.Watch
            ? "This dashboard shows the degree and type of risks that are present in " +
                "leadership succession pipelines across your organization. The results indicate that " +
                "a notable number of positions have one or more risks to leadership continuity. Explore " +
                "the heatmap, by group and metric, to identify areas requiring additional review " +
                "or immediate intervention."
            : summaryStatus == Status.Maintain
                ? "This dashboard shows the degree and type of risks that are present in " +
                    "leadership succession pipelines across your organization. The results indicate that " +
                    "a manageable number of positions have one or more risks to leadership continuity. " +
                    "Explore the heatmap, by group and metric, to identify any isolated areas that may " +
                    "benefit from proactive monitoring or review."
                : "No data";
    return (_jsx(ScoreBreakdown, { action: summaryStatus, summaryRightSideText: summaryRightSideText, title: "Organization Overview", showDescriptions: true }));
};
var RoledSuccession = function () {
    var navigate = useNavigate();
    var onClickIndividualRisk = function (leader) {
        updateFilters({
            role: leader.sub_function,
            level: leader.level,
        });
        navigate(getRoute("SuccessionAll"));
    };
    var overviewComponentInfo = {
        inside: _jsx(LocalScoreBreakdown, {}),
    };
    var descriptionPopup = (_jsx(StatusInfoPopUp, { description: "Please note the thresholds differ based on the metric selected.", popupText: popupText }));
    var SuccessionLowPerformerLevelFunctionMatrix = function (_a) {
        var scoreType = _a.scoreType;
        var highRiskTitleFormatter = function (_a) {
            var name = _a.name;
            return "".concat(name || "unknown name");
        };
        return (_jsx(LowPerformerLevelFunctionMatrix, { scoreType: scoreType, useScores: useHeatmapPositionedSuccessionScores, highRiskTitleFormatter: highRiskTitleFormatter, highRiskTitle: {
                teams: "Teams with most positions at risk",
                individual: "Roles with most positions at risk",
            }, secondaryPageUrl: getRoute("SuccessionAll"), onClickIndividualRisk: onClickIndividualRisk }));
    };
    var heatmaps = [
        {
            label: "Positions at risk",
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: SuccessionTypes.AtRisk })),
            legend: (_jsx(PercentageLegend, { scoreType: SuccessionTypes.AtRisk, useThreshold: useGetPositionSuccessionThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
            scoreKey: SuccessionTypes.AtRisk,
            scoreFormatter: percentageOfScoreFormatter,
            description: successionTooltips[SuccessionTypes.AtRisk],
        },
        {
            label: "Positions without pipelines",
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: SuccessionTypes.WithoutPipelines })),
            legend: (_jsx(PercentageLegend, { scoreType: SuccessionTypes.WithoutPipelines, useThreshold: useGetPositionSuccessionThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
            scoreKey: SuccessionTypes.WithoutPipelines,
            scoreFormatter: percentageOfScoreFormatter,
            description: successionTooltips[SuccessionTypes.WithoutPipelines],
        },
        {
            label: "Positions with shortage of successors",
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: SuccessionTypes.ShortageSuccessors })),
            legend: (_jsx(PercentageLegend, { scoreType: SuccessionTypes.ShortageSuccessors, useThreshold: useGetPositionSuccessionThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
            scoreKey: SuccessionTypes.ShortageSuccessors,
            scoreFormatter: percentageOfScoreFormatter,
            description: successionTooltips[SuccessionTypes.ShortageSuccessors],
        },
        {
            label: "Positions with shortage of ready now successors",
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: SuccessionTypes.ShortageReadyNow })),
            legend: (_jsx(PercentageLegend, { scoreType: SuccessionTypes.ShortageReadyNow, useThreshold: useGetPositionSuccessionThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
            scoreKey: SuccessionTypes.ShortageReadyNow,
            scoreFormatter: percentageOfScoreFormatter,
            description: successionTooltips[SuccessionTypes.ShortageReadyNow],
        },
        {
            label: "Positions with over-leveraged ready now successors",
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: SuccessionTypes.OverleveragedReadyNow })),
            legend: (_jsx(PercentageLegend, { scoreType: SuccessionTypes.OverleveragedReadyNow, useThreshold: useGetPositionSuccessionThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
            scoreKey: SuccessionTypes.OverleveragedReadyNow,
            scoreFormatter: percentageOfScoreFormatter,
            description: successionTooltips[SuccessionTypes.OverleveragedReadyNow],
        },
        {
            label: "Positions reliant on ready now successors with low match scores",
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: SuccessionTypes.ReliantLowMatchScores })),
            legend: (_jsx(PercentageLegend, { scoreType: SuccessionTypes.ReliantLowMatchScores, useThreshold: useGetPositionSuccessionThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
            scoreKey: SuccessionTypes.ReliantLowMatchScores,
            scoreFormatter: percentageOfScoreFormatter,
            description: successionTooltips[SuccessionTypes.ReliantLowMatchScores],
        },
    ];
    return (_jsx(HeatmappedPage, { heatmaps: heatmaps, useScores: useGetPositionSuccessionScoresQuery, heading: overviewComponentInfo, useOverview: useGetPositionSuccessionOverviewQuery, countText: "position", rightTrailer: _jsx(SuccessionDownloadCsv, {}) }));
};
export default RoledSuccession;
