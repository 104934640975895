import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { TextInput, IconName } from "@eightfold.ai/octuple";
import { FilterContext, updateFilters } from "../../providers/FilterProvider";
import { debounce } from "lodash";
var updateDebounce = debounce(function (value) {
    updateFilters({ roleName: value });
}, 800);
export var SearchRoleNameFilter = function () {
    var getFilters = useContext(FilterContext).getFilters;
    var handleInputChange = function (event) {
        var searchValue = event === null || event === void 0 ? void 0 : event.target.value;
        updateDebounce(searchValue);
    };
    return (_jsx(TextInput, { alignIcon: "left", autoFocus: true, classNames: "my-textinput-class", clearButtonAriaLabel: "Clear", clearable: true, value: getFilters().roleName || "", htmltype: "text", iconButtonProps: {
            allowDisabledFocus: false,
            disabled: false,
            iconProps: {
                path: IconName.mdiMagnify,
            },
        }, id: "searchRoleNameInputId", inputWidth: "fitContent", maxlength: 100, minlength: 0, name: "searchRoleNameInput", onChange: handleInputChange, placeholder: "Search for role name", shape: "rectangle", size: "medium", waitInterval: 10 }));
};
