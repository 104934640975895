import { useEffect, useState } from "react";
import { getClientId } from "../store/api/core";
export var useClient = function () {
    var _a = useState(getClientId()), clientId = _a[0], setClientId = _a[1];
    useEffect(function () {
        if (clientId) {
            return;
        }
        var timeId = setInterval(function () {
            var localClientId = getClientId();
            if (localClientId) {
                setClientId(localClientId);
            }
        }, 250);
        return function () {
            clearInterval(timeId);
        };
    });
    return { clientId: clientId };
};
