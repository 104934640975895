var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Dialog, Box, DialogContent, DialogContentText, DialogTitle, Slide, } from "@mui/material";
import ClosingX from "../../icons/ClosingX";
import { styled } from "@mui/system";
import SpinnerAndErrorer from "../SpinnerAndErrorer/SpinnerAndErrorer";
import HoverableCircle from "../HoverableCircle/HoverableCircle";
import { createContext, useState, useMemo } from "react";
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Slide, __assign({ direction: "left", ref: ref }, props));
});
TransitionPopup.defaultProps = {
    headers: [],
    tableData: [],
};
/* eslint-disable  @typescript-eslint/no-empty-function */
export var SliderStackContext = createContext({
    pushSliderStack: function () { },
    popSliderStack: function () { },
    clearSliderStack: function () { },
    sideSlider: null,
});
/* eslint-enable  @typescript-eslint/no-empty-function */
export var TransitionPopupStackProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), stack = _b[0], setStack = _b[1];
    var value = useMemo(function () {
        return {
            pushSliderStack: function (component) {
                setStack(function (last) {
                    var newStack = __spreadArray([], last, true);
                    newStack.push(_jsx(Box, __assign({ sx: {
                            zOrder: 1000 + stack.length,
                        } }, { children: component })));
                    return newStack;
                });
            },
            popSliderStack: function () {
                setStack(function (last) {
                    var newStack = __spreadArray([], last, true);
                    newStack.pop();
                    return newStack;
                });
            },
            clearSliderStack: function () {
                setStack(function (last) {
                    var newStack = __spreadArray([], last, true);
                    while (newStack.length) {
                        newStack.pop();
                    }
                    return newStack;
                });
            },
            sideSlider: stack,
        };
    }, [stack]);
    var displayable = __spreadArray(__spreadArray([], stack, true), [children], false);
    return (_jsx(SliderStackContext.Provider, __assign({ value: value }, { children: _jsx(_Fragment, { children: displayable }) })));
};
export default function TransitionPopup(_a) {
    var title = _a.title, leaderName = _a.leaderName, description = _a.description, open = _a.open, spinnerConditions = _a.spinnerConditions, statusHeader = _a.statusHeader, userProfile = _a.userProfile, children = _a.children, handleClose = _a.handleClose;
    var TitleBox = styled(Box)({
        display: "flex",
        justifyContent: "space-between",
    });
    var DialogBoxTitle = styled(DialogTitle)(function (_a) {
        var theme = _a.theme;
        return ({
            padding: "0",
            color: theme.palette.primary.dark,
            fontFamily: "Avenir",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "300",
            lineHeight: "24px",
            textTransform: "uppercase",
        });
    });
    var LeaderNameTitle = styled(DialogContentText)(function (_a) {
        var theme = _a.theme;
        return ({
            color: theme.palette.primary.dark,
            fontFamily: "Avenir Medium",
            fontSize: "31px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "41px",
        });
    });
    var SmallLine = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            width: "24px",
            height: "1px",
            background: theme.palette.secondary.main,
            marginTop: "16px",
        });
    });
    return (_jsxs(Dialog, __assign({ "data-testid": "transitionPopup", open: open, TransitionComponent: Transition, transitionDuration: 400, 
        // keepMounted
        onClose: handleClose, BackdropProps: {
            style: {
                backgroundColor: "rgba(237,238,238, 0.5)",
            },
        }, "aria-describedby": "alert-dialog-slide-description", PaperProps: {
            style: {
                maxWidth: "none",
                width: "1079px",
                boxShadow: "none",
                height: "100vh",
                position: "absolute",
                right: "-27px",
                padding: "64px 32px 48px 32px",
            },
        } }, { children: [_jsxs(TitleBox, { children: [_jsx(DialogBoxTitle, { children: title }), _jsx(HoverableCircle, __assign({ onClick: handleClose }, { children: _jsx(ClosingX, {}) }))] }), _jsxs(DialogContent, __assign({ sx: { padding: "8px 0" } }, { children: [_jsxs(LeaderNameTitle, __assign({ id: "alert-dialog-slide-description" }, { children: [leaderName, userProfile] })), _jsx(DialogContentText, __assign({ id: "alert-dialog-slide-description" }, { children: description })), _jsx(SmallLine, { id: "alert-dialog-slide-description" }), _jsx(SpinnerAndErrorer, __assign({ conditions: spinnerConditions, sx: {
                            position: "absolute",
                            top: "250px",
                        } }, { children: _jsxs(DialogContentText, __assign({ id: "alert-dialog-slide-description" }, { children: [_jsx(Box, __assign({ sx: { padding: "24px 0" } }, { children: statusHeader })), children] })) }))] }))] })));
}
