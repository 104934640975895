import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { Button, ButtonVariant as IntelligenceButtonVariant, } from "../Button/Button";
import { IconName } from "@eightfold.ai/octuple";
import { downloadCsvFile } from "../../store/api/core";
var DownloadButton = function (_a) {
    var buttonText = _a.buttonText, filename = _a.filename, url = _a.url;
    var _b = useState(false), downloadingCsv = _b[0], setDownloadingCsv = _b[1];
    return (_jsx(Button, { variant: IntelligenceButtonVariant.Secondary, text: buttonText, iconPath: IconName.mdiDownload, loading: downloadingCsv, onClick: function () {
            setDownloadingCsv(true);
            var onSuccess = function () {
                setDownloadingCsv(false);
            };
            downloadCsvFile(filename, url, onSuccess);
        } }));
};
export default DownloadButton;
