// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pSdUj1RX2kLKo5IFALO4 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  z-index: 500;
  width: 100%;
  position: fixed;
  margin: -5px 0 0 0;
  padding: 0;
}
.pSdUj1RX2kLKo5IFALO4 .i3H1myDP7fikVWe0ilUL {
  background-color: unset;
  color: var(--navbar-text-color);
  box-shadow: unset;
  height: 75px;
  padding-bottom: 0;
  max-width: 1560px;
  right: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mTP3q99p4VbgRRTCN_34 {
  border-bottom: 1px solid var(--grey-gradient-end-color-10);
  position: fixed;
  width: 999%;
  height: 96px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}

.qjz1O7cqmx3gWI4SrFYG {
  padding: 0 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.AQj0qjGusBKgpXIQViBm {
  width: 143px;
  height: 60px;
  margin-right: 24px;
}

.BMrkEVHoLSGsO4tJheoc {
  height: 56px;
  padding: 16px;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  color: var(--grey-gradient-start-color-10);
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;AACF;AACE;EACE,uBAAA;EACA,+BAAA;EACA,iBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAGA;EACE,0DAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,0BAAA;AAAF;;AAGA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAAF;;AAGA;EACE,YAAA;EACA,YAAA;EACA,kBAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,0CAAA;EACA,iBAAA;AAAF","sourcesContent":[".headerContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 64px;\n  z-index: 500;\n  width: 100%;\n  position: fixed;\n  margin: -5px 0 0 0;\n  padding: 0;\n\n  .tabBar {\n    background-color: unset;\n    color: var(--navbar-text-color);\n    box-shadow: unset;\n    height: 75px;\n    padding-bottom: 0;\n    max-width: 1560px;\n    right: unset;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n  }\n}\n\n.headerBackground {\n  border-bottom: 1px solid var(--grey-gradient-end-color-10);\n  position: fixed;\n  width: 999%;\n  height: 96px;\n  background-color: rgba(255, 255, 255, 0.9);\n  backdrop-filter: blur(5px);\n}\n\n.logoContainer {\n  padding: 0 40px;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.logo {\n  width: 143px;\n  height: 60px;\n  margin-right: 24px;\n}\n\n.menuItem {\n  height: 56px;\n  padding: 16px;\n  font-family: Avenir, sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 300;\n  color: var(--grey-gradient-start-color-10);\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `pSdUj1RX2kLKo5IFALO4`,
	"tabBar": `i3H1myDP7fikVWe0ilUL`,
	"headerBackground": `mTP3q99p4VbgRRTCN_34`,
	"logoContainer": `qjz1O7cqmx3gWI4SrFYG`,
	"logo": `AQj0qjGusBKgpXIQViBm`,
	"menuItem": `BMrkEVHoLSGsO4tJheoc`
};
export default ___CSS_LOADER_EXPORT___;
