var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ArrowRight from "./ArrowRight";
import { Box } from "@mui/material";
import Alert from "./Alert";
var color = "#C02195";
var defaultErrorDescription = "There was an error in trying to receive this data.";
var LoadError = function (props) {
    var tryAgain;
    if (props.tryAgainText === undefined) {
        tryAgain = "Please try again";
    }
    else if (props.tryAgainText) {
        tryAgain = props.tryAgainText;
    }
    return (_jsxs(Box, __assign({ "data-testid": "LoadError", sx: {
            color: color,
            display: "flex",
        } }, { children: [_jsx(Alert, {}), _jsxs(Box, __assign({ sx: {
                    marginLeft: "10px",
                } }, { children: [_jsx(Box, { children: props.errorDescription || defaultErrorDescription }), tryAgain && (_jsxs(Box, __assign({ sx: {
                            display: "flex",
                            justifyContent: "flex-start",
                        } }, { children: [_jsx(Box, { children: tryAgain }), _jsx(Box, __assign({ sx: { cursor: "pointer" }, onClick: props.onClick || window.location.reload, "aria-label": "Try Again" }, { children: _jsx(ArrowRight, {}) }))] })))] }))] })));
};
export default LoadError;
