var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography, Modal, IconButton, useTheme, } from "@mui/material";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/system";
var CustomButton = styled(Button)({
    // marginBottom: "8px",
    "&:hover": {
        backgroundColor: "#ecfafb",
        boxShadow: "none",
    },
});
export default function StrengthInfoGenericModal(props) {
    var title = props.title, desc = props.desc, open = props.open, close = props.close;
    var theme = useTheme();
    var style = {
        width: "950px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        padding: "24px 24px 32px",
    };
    var buttonStyle = {
        background: "#b0f3fe",
        borderRadius: "24px",
        color: "#054D7B",
        padding: "8px 12px",
        boxShadow: "none",
        fontWeight: "bold",
    };
    var titleStyle = {
        fontFamily: "Avenir",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "31px",
        lineHeight: "41px",
        color: theme.palette.text.primary,
        flex: 1,
    };
    var descStyle = {
        fontFamily: "Avenir",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "300",
        lineHeight: "24px",
        color: theme.palette.primary.main,
    };
    return (_jsx(Modal, __assign({ open: open, onClose: close, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsxs(Box, __assign({ sx: style }, { children: [_jsxs(Box, __assign({ sx: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    } }, { children: [_jsx(Typography, __assign({ id: "modal-modal-title", style: titleStyle }, { children: title })), _jsx(IconButton, __assign({ "aria-label": "close", sx: { padding: 0 }, onClick: close }, { children: _jsx(Close, { fontSize: "large" }) }))] })), _jsx(Box, __assign({ id: "modal-modal-description", sx: { marginTop: "24px" } }, { children: _jsx(Typography, __assign({ variant: "body2", style: descStyle }, { children: desc })) })), _jsx(CustomButton, __assign({ variant: "contained", onClick: close, sx: buttonStyle }, { children: "Ok" }))] })) })));
}
