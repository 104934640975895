var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import styles from "./Pill.module.scss";
export var PillVariant;
(function (PillVariant) {
    PillVariant["Warning"] = "warning";
    PillVariant["Error"] = "error";
})(PillVariant || (PillVariant = {}));
export var Pill = function (props) {
    return (_jsx("div", __assign({ className: props.variant === PillVariant.Warning
            ? styles.pillWarning
            : styles.pillError }, { children: props.text })));
};
export default Pill;
