var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import UpCaret from "../../icons/CaretUpBig";
import DownCaret from "../../icons/CaretDownBig";
import { ActBigDarkSvg, MaintainBigDarkSvg, NodataBigDarkSvg, WatchBigDarkSvg, ActBigLightSvg, MaintainBigLightSvg, NodataBigLightSvg, WatchBigLightSvg, } from "../ActionCircle/Svgs";
import ClosingX from "../../icons/ClosingX";
import HoverableCircle from "../HoverableCircle";
import { getIndividualRiskCardProps, getTeamRiskCardProps, } from "../AdditionalRiskCards/AdditionalRiskCards";
import AdditionalRiskCard from "../AdditionalRiskCard";
import "../../hd-ui-kit/HDTheme.css";
import SpinnerAndErrorer, { willHold, } from "../SpinnerAndErrorer/SpinnerAndErrorer";
import { useNavigate } from "../../hooks/navigate";
import { getRoute } from "../../routes/getAllRoutes";
import { FilterContext } from "../../providers/FilterProvider";
import Tooltip, { TooltipPlacement } from "../Tooltip/Tooltip";
import { useEightfold } from "../../hooks/eightFold";
export var LEVEL = "level";
export var FUNCTION = "function";
export var SUB_FUNCTION = "sub-function";
var withFocusedBorder = function (outlineColor) { return ({
    border: "1px solid ".concat(outlineColor),
    "&:focus": {
        border: "8px solid ".concat(outlineColor),
    },
}); };
var onEnterKey = function (toDo) { return function (event) {
    if ((event === null || event === void 0 ? void 0 : event.code) != "Enter") {
        return;
    }
    toDo();
}; };
var IconSvg = {
    light: {
        act: ActBigLightSvg,
        maintain: MaintainBigLightSvg,
        watch: WatchBigLightSvg,
        nodata: NodataBigLightSvg,
    },
    dark: {
        act: ActBigDarkSvg,
        maintain: MaintainBigDarkSvg,
        watch: WatchBigDarkSvg,
        nodata: NodataBigDarkSvg,
    },
};
export function getDefaultDataCell(_a) {
    var level = _a.level, roles = _a.roles, isActive = _a.isActive, score = _a.score, expandCell = _a.expandCell, getNameByLookupCode = _a.getNameByLookupCode;
    var Component = function () {
        var _a = useState(false), pressed = _a[0], setPressed = _a[1];
        var theme = useTheme();
        var heading = roles[1]
            ? "".concat(getNameByLookupCode(roles[1]), " with Sub-Function ").concat(getNameByLookupCode(roles[0]))
            : getNameByLookupCode(roles[0]);
        var ariaLabel = "".concat(score.state, " ").concat(getNameByLookupCode(level), " doing ").concat(heading);
        var colorsForAction = {
            act: {
                background: theme.palette.error.main,
                border: theme.palette.error.dark,
            },
            watch: {
                background: theme.palette.warning.main,
                border: theme.palette.warning.dark,
            },
            maintain: {
                background: theme.palette.success.main,
                border: theme.palette.success.dark,
            },
            nodata: {
                background: theme.palette.grey.A400,
                border: theme.palette.primary.dark + "20",
            },
        };
        var _b = colorsForAction[score.state], borderColor = _b.border, backgroundColor = _b.background;
        var borderStyle = pressed
            ? {}
            : isActive()
                ? { border: "none" }
                : withFocusedBorder(borderColor);
        var BottomBorder = styled(Box)({
            height: "8px",
            background: colorsForAction[score.state].background,
            position: "relative",
            top: "58px",
        });
        var StyledCell = styled(Box)(__assign(__assign(__assign({ background: backgroundColor }, borderStyle), IconSvg.dark[score.state]), { "&:hover": IconSvg.light[score.state], "&:active": IconSvg.light[score.state], "&:focus": {
                outline: "none",
                border: "3px solid ".concat(borderColor),
                position: "relative",
                bottom: "2px",
                right: "2px",
            }, width: "100%", height: cellHeight }));
        var handlers = {
            onMouseDown: function () {
                setPressed(true);
            },
            onMouseUp: function () {
                setPressed(false);
                expandCell();
            },
        };
        return (_jsx(_Fragment, { children: _jsx(StyledCell, __assign({}, handlers, (ariaLabel ? { "aria-label": ariaLabel } : {}), { tabIndex: 0, onClick: expandCell, onKeyPress: onEnterKey(expandCell) }, { children: isActive() && _jsx(BottomBorder, {}) })) }));
    };
    return _jsx(Component, {});
}
var ensureSEnding = function (text) {
    return "".concat(text).concat(text[text.length - 1] == "s" ? "" : "s");
};
var cellHeight = "58px";
function LevelFunctionMatrix(_a) {
    var useHeatmapScores = _a.useHeatmapScores, getExpandedCell = _a.getExpandedCell, getDataCell = _a.getDataCell, highRiskTitleFormatter = _a.highRiskTitleFormatter, highRiskTitle = _a.highRiskTitle, secondaryPageUrl = _a.secondaryPageUrl, onClickIndividualRisk = _a.onClickIndividualRisk;
    var navigate = useNavigate();
    var theme = useTheme();
    var handleEightfoldUserNavigation = useEightfold().handleEightfoldUserNavigation;
    var _b = useState(null), exposedCell = _b[0], setExposedCell = _b[1];
    var _c = useState(null), expandedRow = _c[0], setExpandedRow = _c[1];
    var heatmapScores = useHeatmapScores({
        riskRole: expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.role,
    });
    var xHeaders = heatmapScores.xHeaders, yHeaders = heatmapScores.yHeaders, dataBreakdowns = heatmapScores.dataBreakdowns, data = heatmapScores.data, nameByLookupCode = heatmapScores.nameByLookupCode, codeTypeMap = heatmapScores.codeTypeMap, scoresConditions = heatmapScores.scoresConditions, headersConditions = heatmapScores.headersConditions, filters = heatmapScores.filters, riskyTeams = heatmapScores.riskyTeams, riskyLeaders = heatmapScores.riskyLeaders;
    var updateFilters = useContext(FilterContext).updateFilters;
    if (willHold(headersConditions)) {
        return _jsx(SpinnerAndErrorer, { conditions: headersConditions });
    }
    var getNameByLookupCode = function (lookupCode, usePlaceholder) {
        if (usePlaceholder === void 0) { usePlaceholder = false; }
        var title;
        var name = nameByLookupCode[lookupCode];
        if (usePlaceholder &&
            (name === "" || name === null || name === undefined)) {
            var lookupType = codeTypeMap[lookupCode];
            if (lookupType === SUB_FUNCTION) {
                title = "No Sub-Function Found";
            }
            else if (lookupType === FUNCTION) {
                title = "No Function Found";
            }
            else if (lookupType === LEVEL) {
                title = "No Level Found";
            }
            title = _jsx(Box, __assign({ sx: { color: theme.palette.error.dark } }, { children: title }));
        }
        else {
            title = name;
        }
        return title;
    };
    var handleOnTeamClick = function (_a) {
        var leader = _a.leader;
        updateFilters({
            role: leader.code,
            level: "all",
            manager: leader === null || leader === void 0 ? void 0 : leader.manager_email,
        });
        navigate(secondaryPageUrl || getRoute("AssessmentAll"));
    };
    var teams = (riskyTeams === null || riskyTeams === void 0 ? void 0 : riskyTeams.map(function (leader) { return ({
        title: "".concat(nameByLookupCode[leader.code] || "unknown team", " (reporting to ").concat(leader.manager_name || "unknown manager", ")"),
        leader: leader,
        onClick: function () { return handleOnTeamClick({ leader: leader }); },
        code: leader.code,
        location: leader.location,
    }); })) || [];
    var highRiskFormatter = function (_a) {
        var name = _a.name, role = _a.role;
        return "".concat(name || "unknown name", ", ").concat(role);
    };
    var getIndividualTitle = function (leader) {
        var role = nameByLookupCode[leader.sub_function] ||
            nameByLookupCode[leader.function] ||
            "";
        return (highRiskTitleFormatter || highRiskFormatter)({
            name: leader === null || leader === void 0 ? void 0 : leader.name,
            role: role,
        });
    };
    var individuals = riskyLeaders === null || riskyLeaders === void 0 ? void 0 : riskyLeaders.map(function (leader) {
        return {
            title: getIndividualTitle(leader),
            onClick: function () {
                (onClickIndividualRisk || handleEightfoldUserNavigation)(leader);
            },
            leader: leader,
            level: leader.level,
            role: leader.sub_function ? leader.sub_function : leader["function"],
            location: leader.location,
            leaderId: leader.leader_id,
        };
    });
    var risks = teams && individuals
        ? {
            team: (_jsx(AdditionalRiskCard, __assign({}, getTeamRiskCardProps(teams, highRiskTitle === null || highRiskTitle === void 0 ? void 0 : highRiskTitle.teams)))),
            individual: (_jsx(AdditionalRiskCard, __assign({}, getIndividualRiskCardProps(individuals, highRiskTitle === null || highRiskTitle === void 0 ? void 0 : highRiskTitle.individual)))),
        }
        : null;
    var cells = [];
    var currentRowIndex = 0;
    var PositionedSpan = function (_a) {
        var x = _a.x, y = _a.y, children = _a.children, sx = _a.sx, span = _a.span, ySpan = _a.ySpan, onClick = _a.onClick, visibleOnExpandedRow = _a.visibleOnExpandedRow, visibleOnExposedCoordinates = _a.visibleOnExposedCoordinates, neverHide = _a.neverHide, ariaLabel = _a.ariaLabel, testId = _a.testId;
        if (visibleOnExposedCoordinates &&
            exposedCell &&
            (visibleOnExposedCoordinates.x != exposedCell.x ||
                visibleOnExposedCoordinates.y != exposedCell.y)) {
            return _jsx(Box, { sx: { height: "0px", overflowY: "hidden" } });
        }
        if (visibleOnExposedCoordinates && !exposedCell) {
            return _jsx(Box, { sx: { height: "0px", overflowY: "hidden" } });
        }
        if (visibleOnExpandedRow && (expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.index) != visibleOnExpandedRow) {
            return _jsx(Box, { sx: { height: "0px", overflowY: "hidden" } });
        }
        var hidable = neverHide
            ? {}
            : exposedCell && exposedCell.y != y && !(expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.index)
                ? { display: "none" }
                : (expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.index) &&
                    (expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.index) != y &&
                    (expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.index) != visibleOnExpandedRow
                    ? { display: "none" }
                    : {};
        var GridThing = styled(Box)(__assign(__assign({ gridColumnStart: "".concat(1 + x * 2), gridColumnEnd: "span ".concat(2 * (span || 1)), gridRowStart: "".concat(y + 2), gridRowEnd: "".concat(y + 2 + (ySpan || 1)) }, hidable), { padding: "3px" }));
        return (_jsx(GridThing, __assign({}, (ariaLabel ? { "aria-label": ariaLabel } : {}), (testId ? { "data-testid": testId } : {}), { onClick: function () {
                onClick && onClick();
            }, component: "span", sx: sx || {} }, { children: children })));
    };
    var XHeader = function (_a) {
        var children = _a.children, background = _a.background, sx = _a.sx;
        var OuterCell = styled(Box)(__assign(__assign({}, (background ? { background: background } : {})), { textAlign: "center", height: "58px", border: "1px solid ".concat(theme.palette.grey.A400), borderRadius: "14px", display: "flex", flexDirection: "column", justifyContent: "center", font: "300 16px/24px Avenir" }));
        var InnerCell = styled(Box)({
            position: "relative",
            top: "2px",
        });
        return (_jsx(OuterCell, { children: _jsx(InnerCell, __assign({ sx: sx || {} }, { children: children })) }));
    };
    var RowHeader = function (_a) {
        var header = _a.header, expanded = _a.expanded, expandable = _a.expandable, background = _a.background, font = _a.font, isAllFunctions = _a.isAllFunctions;
        var ExpandableRowHeaderOuterContainer = styled(Box)(__assign(__assign(__assign({ display: "flex", justifyContent: isAllFunctions
                ? "flex-start"
                : expandable
                    ? "space-between"
                    : "flex-end", flexDirection: "row", height: cellHeight }, (expandable
            ? { position: "relative", top: "0px" }
            : { paddingRight: "16px" })), (background ? { background: background } : {})), { paddingLeft: "16px", borderRadius: "14px", outline: "1px solid ".concat(theme.palette.primary.main + 20) }));
        var ExpanderSymbolStyle = styled(Box)({
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            background: theme.palette.background.default,
            width: "40px",
            height: "40px",
            borderRadius: "40px",
        });
        var VerticalCenterer = styled(Box)({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        });
        var headerText = getNameByLookupCode(header, true);
        var characterCount = headerText === null || headerText === void 0 ? void 0 : headerText.length;
        var maxCharacters = expandable ? 24 : 26;
        var truncatedStr = characterCount > maxCharacters
            ? headerText.slice(0, maxCharacters) + "..."
            : headerText;
        var content = _jsx(Box, { children: truncatedStr });
        var toolTipContent = (_jsx(Tooltip, __assign({ content: headerText, placement: TooltipPlacement.Top }, { children: content })));
        var finalConent = isAllFunctions ? content : toolTipContent;
        return (_jsxs(ExpandableRowHeaderOuterContainer, __assign({ "aria-label": "".concat(getNameByLookupCode(header), " Function") }, { children: [_jsx(VerticalCenterer, __assign({ component: "span", sx: __assign(__assign({}, (font ? { font: font } : { font: "300 16px/24px Avenir" })), (expandable
                        ? {}
                        : {
                            textAlign: "right",
                        })) }, { children: finalConent })), expandable && (_jsx(VerticalCenterer, { children: _jsx(ExpanderSymbolStyle, __assign({ component: "span" }, { children: _jsx(HoverableCircle, { children: expanded ? _jsx(UpCaret, {}) : _jsx(DownCaret, {}) }) })) }))] })));
    };
    var rowCount = ((yHeaders === null || yHeaders === void 0 ? void 0 : yHeaders.length) || 0) +
        Object.values(dataBreakdowns || {}).reduce(function (count, next) {
            return count + next.headers.length;
        }, 0) +
        ((yHeaders === null || yHeaders === void 0 ? void 0 : yHeaders.length) || 0) * ((xHeaders === null || xHeaders === void 0 ? void 0 : xHeaders.length) || 0) +
        Object.values(dataBreakdowns || {}).reduce(function (count, next) {
            return count + next.data.length * xHeaders.length;
        }, 0);
    var TableStyled = styled(Box)({
        display: "grid",
        gridTemplateColumns: "repeat(".concat((((xHeaders === null || xHeaders === void 0 ? void 0 : xHeaders.length) || 0) + 2) * 2 - 2, ", 1fr)"),
        gridTemplateRows: "repeat(".concat(((rowCount || 0) + 2) * 2, ", auto)"),
    });
    var handleSummaryRow = function (exposedRow, row, header) {
        row.forEach(function (cell, xIndex) {
            var role = header == "All Functions" ? "All" : header;
            var level = xHeaders[xIndex] == "All Levels" ? "All" : xHeaders[xIndex];
            var BorderContainer = styled(Box)({
                borderCollapse: "separate",
                marginTop: "-2px",
                border: "1px solid ".concat(theme.palette.primary.dark + "20"),
                borderRadius: "0 0 6px 6px",
                //borderTop: `4px solid ${colorsForAction[cell.state].background}`,
                paddingRight: "50px",
                display: "flex",
                justifyContent: "flex-start",
            });
            var Trail = styled(Box)({
                height: "3px",
                width: "100%",
                position: "relative",
                bottom: "2px",
                //background: colorsForAction[cell.state].background,
            });
            currentRowIndex++;
            cells.push(_jsx(PositionedSpan, __assign({ x: xIndex + 1, y: currentRowIndex, span: 1, visibleOnExposedCoordinates: { y: exposedRow, x: xIndex }, neverHide: true }, { children: _jsx(Trail, {}) })));
            cells.push(_jsx(PositionedSpan, __assign({ x: 1, y: currentRowIndex, span: yHeaders.length, visibleOnExposedCoordinates: { y: exposedRow, x: xIndex }, neverHide: true, testId: "".concat(getNameByLookupCode(header), ", ").concat(ensureSEnding(getNameByLookupCode(xHeaders[xIndex]))) }, { children: _jsxs(BorderContainer, { children: [getExpandedCell({ cell: cell, role: role, level: level, filters: filters }), _jsx(HoverableCircle, __assign({ onClick: function () {
                                setExposedCell(null);
                            }, sx: {
                                position: "relative",
                                top: "20px",
                                left: "20px",
                            } }, { children: _jsx(ClosingX, {}) }))] }) })));
        });
    };
    var handleDataCell = function (onRow, visibleOnExpandedRow, roles, neverHide) {
        return function (cell, columnIndex) {
            var level = xHeaders[columnIndex];
            cells.push(_jsx(PositionedSpan, __assign({ x: columnIndex + 1, y: currentRowIndex, visibleOnExpandedRow: visibleOnExpandedRow, neverHide: neverHide }, { children: getDataCell({
                    getNameByLookupCode: getNameByLookupCode,
                    level: level,
                    roles: roles,
                    filters: filters,
                    isActive: function () {
                        return exposedCell &&
                            exposedCell.x == columnIndex &&
                            exposedCell.y == currentRowIndex;
                    },
                    score: cell,
                    expandCell: function () {
                        setExposedCell(!exposedCell
                            ? { x: columnIndex, y: onRow }
                            : exposedCell.x == columnIndex && exposedCell.y == onRow
                                ? null
                                : { x: columnIndex, y: onRow });
                        if (!visibleOnExpandedRow && onRow != (expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.index)) {
                            setExpandedRow(null);
                        }
                    },
                }) })));
        };
    };
    var handleDataRow = function (headers, expandedOn, parentHeader, hasData) {
        return function (row, index) {
            currentRowIndex++;
            var expandedRowIdentifier = currentRowIndex;
            if (expandedOn || index > 0) {
                cells.push(_jsx(PositionedSpan, __assign({ onClick: function () {
                        setExpandedRow(expandedRow
                            ? null
                            : expandedOn
                                ? null
                                : { index: expandedRowIdentifier, role: headers[index] });
                        setExposedCell(null);
                    }, y: currentRowIndex, x: 0, neverHide: index == 0, visibleOnExpandedRow: expandedOn }, { children: _jsx(RowHeader, { header: headers[index], expanded: (expandedRow === null || expandedRow === void 0 ? void 0 : expandedRow.index) == expandedRowIdentifier, expandable: !expandedOn }) })));
            }
            if (!hasData) {
                return;
            }
            var roles = parentHeader
                ? [headers[index], parentHeader]
                : [headers[index]];
            row.forEach(handleDataCell(currentRowIndex, expandedOn, roles, index == 0));
            handleSummaryRow(expandedRowIdentifier, row, headers[index]);
            if (!dataBreakdowns[headers[index]]) {
                return;
            }
            var breakdown = dataBreakdowns[headers[index]];
            breakdown.data.forEach(handleDataRow(breakdown.headers, expandedRowIdentifier, headers[index], true));
        };
    };
    if (!willHold(scoresConditions)) {
        data.forEach(handleDataRow(yHeaders, null, null, true));
    }
    else {
        (yHeaders || [])
            .map(function () { return [{ state: "nodata" }]; })
            .forEach(handleDataRow(yHeaders, null, null, false));
        currentRowIndex++;
        cells.push(_jsx(PositionedSpan, __assign({ x: 1, y: currentRowIndex, span: (xHeaders === null || xHeaders === void 0 ? void 0 : xHeaders.length) || 0, ySpan: 2 }, { children: _jsx(Box, { sx: { width: "100%", height: "116px" } }) })));
        cells.push(_jsx(PositionedSpan, __assign({ x: 1, y: 1, span: (xHeaders === null || xHeaders === void 0 ? void 0 : xHeaders.length) || 0, ySpan: ((yHeaders === null || yHeaders === void 0 ? void 0 : yHeaders.length) || 0) + 6 }, { children: _jsx(SpinnerAndErrorer, { conditions: scoresConditions }) })));
    }
    if (!willHold(scoresConditions)) {
        currentRowIndex++;
        var span = xHeaders.length / 2;
        cells.push(_jsx(PositionedSpan, __assign({ y: currentRowIndex, x: 1, span: span, neverHide: true, sx: { padding: "32px 0 0" } }, { children: risks === null || risks === void 0 ? void 0 : risks.team })));
        cells.push(_jsx(PositionedSpan, __assign({ y: currentRowIndex, x: 1 + span, span: span, neverHide: true, sx: { padding: "32px 0 0", position: "relative", left: "3px" } }, { children: risks === null || risks === void 0 ? void 0 : risks.individual })));
    }
    return (_jsx(TableStyled, __assign({ "aria-label": "Level vs Role Table" }, { children: _jsxs(_Fragment, { children: [_jsx(PositionedSpan, { x: 0, y: 0 }), _jsx(PositionedSpan, __assign({ x: 1, y: 0, neverHide: true }, { children: _jsx(XHeader, __assign({ background: theme.palette.grey.A400, sx: {
                            font: "500 16px/24px Avenir Medium",
                        } }, { children: xHeaders === null || xHeaders === void 0 ? void 0 : xHeaders[0] }), "xheader-all") })), _jsx(PositionedSpan, __assign({ x: 0, y: 1, neverHide: true, onClick: function () {
                        setExpandedRow(null);
                        setExposedCell(null);
                    }, sx: {
                        justifyContent: "flex-start",
                    } }, { children: _jsx(RowHeader, { font: "500 16px/24px Avenir Medium", expandable: false, isAllFunctions: true, header: yHeaders === null || yHeaders === void 0 ? void 0 : yHeaders[0], expanded: false, background: theme.palette.grey.A400 }) })), (xHeaders || []).slice(1).map(function (lookupCode, index) { return (_jsx(PositionedSpan, __assign({ x: index + 2, y: 0, neverHide: true }, { children: _jsxs(XHeader, { children: [" ", getNameByLookupCode(lookupCode, true), " "] }, "xheader-".concat(index)) }))); }), cells] }) })));
}
export default LevelFunctionMatrix;
