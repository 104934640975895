import { createTheme } from "@mui/material";
export var LightTheme = createTheme({
    palette: {
        /* blue/teal */
        primary: {
            dark: "#002136",
            main: "#334255",
            light: "#18768C",
            contrastText: "#FFFFFF",
        },
        /* redish orange */
        secondary: {
            main: "#E05729",
        },
        /* red */
        error: {
            main: "#FFEFEF",
            dark: "#8E3E3B",
            contrastText: "#0B0C10",
        },
        /* yellow */
        warning: {
            main: "#FFFFEB",
            dark: "#9E5F00",
            contrastText: "#0B0C10",
        },
        /* green */
        success: {
            main: "#F0FEFA",
            dark: "#006648",
            contrastText: "#0B0C10",
        },
        /* primary blue/teal */
        info: {
            dark: "#334255",
            main: "#18768C",
        },
        /* light, main, dark = A200, A400, A700 */
        grey: {
            //A100: "#E0E1E5", //TODO: this is a color that was used for background hover.
            //TODO: It has some blue in it. See if we are suppose to have a custom material UI pallet.
            A200: "#F8F8F8",
            A400: "#F1F1F1",
            A700: "#646A7C",
        },
        /* blue */
        blue: {
            main: "#146DA6",
        },
        text: {
            primary: "#002136",
        },
    },
    typography: {
        fontFamily: ["Avenir"].join(","),
        button: {
            fontFamily: ["Avenir Medium"].join(","),
        },
        h4: {
            fontFamily: ["Avenir Heavy"].join(","),
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "28px",
        },
    },
    components: {},
    spacing: function (factor) { return "".concat(0.25 * factor, "rem"); },
});
