var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useGetLeaderAtRiskOverviewQuery, useGetLeadersAtRiskThresholdsQuery, useGetLeaderAtRiskScoresQuery, normalizeScoreType, } from "../../store/api/core";
import { ScoreTitles } from "../../common/action";
import { ScoreBreakdown } from "../../components/Summary/Summary";
import { Box, styled, Typography } from "@mui/material";
import "../../hd-ui-kit/HDTheme.css";
import { getRoute } from "../../routes/getAllRoutes";
import { useNavigate } from "../../hooks/navigate";
import HeatmappedPage from "../../components/HeatmappedPage/HeatmappedPage";
import { ScoreTypes, percentageOfScoreFormatterBase, LowPerformerLevelFunctionMatrix, } from "../../components/LevelFunctionMatrix/Variants";
import { PercentageLegend } from "../../components/Legend/Legend";
import { Status } from "../../common/action";
import StatusInfoPopUp from "../../components/StatusInfoPopUp/StatusInfoPopUp";
import { useHeatmapLeadershipAtRiskScores } from "../../hooks/heatmapScores";
import StrengthInfoPopUp from "../../components/StrengthInfoPopup/StrengthInfoPopup";
var percentageOfScoreFormatter = percentageOfScoreFormatterBase;
var getAllAssessmentRoute = function (assessment) {
    return "".concat(getRoute("".concat(assessment, "All")));
};
var getOverviewTexts = function (scoreData) {
    var matcheds = [
        "organizational",
        "functional",
        "potential",
        "capability",
    ]
        .filter(function (score) {
        /* eslint-disable */
        return scoreData[score] == scoreData.overall;
    }
    /* eslint-enable */
    )
        .map(function (score) { return ScoreTitles[score]; });
    var matchedTexts = matcheds.length == 0
        ? "scores"
        : matcheds.length == 1
            ? matcheds[0]
            : matcheds.length === 2
                ? "".concat(matcheds[0], " and ").concat(matcheds[1])
                : matcheds
                    .map(function (score, index) {
                    return index == matcheds.length - 1
                        ? ", and ".concat(score)
                        : index == 0
                            ? score
                            : ", ".concat(score);
                })
                    .join("");
    return ({
        watch: "Monitor your organization's ".concat(matchedTexts || "", ". ") +
            "The heat map below will help you identify development opportunities across " +
            "Leadership Summary.",
        act: "Evaluate your organization's ".concat(matchedTexts || "", ". ") +
            "The heat map below will help you identify development opportunities across " +
            "Leadership Summary.",
        maintain: "Your organization's ".concat(matchedTexts || "", " ") +
            "are strong right now. The heat map below will help you " +
            "identify development opportunities across Leadership Summary.",
        nodata: "error with data",
    }[scoreData.overall] || "Error with overall score");
};
var popupText = {
    act: "A high number of leaders exhibit notable gaps in meeting several role requirements. " +
        "Recommend immediate action to target greatest gaps and risk areas.",
    watch: "A meaningful number of leaders exhibit gaps in meeting some role requirements. Recommend " +
        "close monitoring and/or proactive management of greatest gaps and risk areas.",
    maintain: "The majority of leaders are meeting role requirements in most areas. Recommend identifying " +
        "key areas of strength and any gaps requiring monitoring.",
};
var descriptionPopup = (_jsx(StatusInfoPopUp, { description: "Scores range from 0% to 100%.", popupText: popupText }));
var LeadershipLowPerformerLevelFunctionMatrix = function (_a) {
    var scoreType = _a.scoreType;
    return (_jsx(LowPerformerLevelFunctionMatrix, { scoreType: scoreType, secondaryPageUrl: getRoute("RiskAssessmentAll"), useScores: useHeatmapLeadershipAtRiskScores, scoreNormalizer: normalizeScoreType, highRiskTitle: {
            teams: "Teams with significant gaps",
            individual: "Leaders with significant gaps",
        } }));
};
var untoggledHeatmaps = [
    {
        label: "Leaders with low Match Scores",
        implementation: (_jsx(LeadershipLowPerformerLevelFunctionMatrix, { scoreType: ScoreTypes.Overall })),
        legend: (_jsx(PercentageLegend, { scoreType: "Match", useThreshold: useGetLeadersAtRiskThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
        scoreKey: ScoreTypes.Overall,
        scoreFormatter: percentageOfScoreFormatter,
        description: "Percentage of leaders with Match Scores indicating significant gaps against role " +
            "requirements and/or AI-derived success factors.",
    },
    {
        label: "Leaders with low Heidrick Scores",
        implementation: (_jsx(LeadershipLowPerformerLevelFunctionMatrix, { scoreType: ScoreTypes.Heidrick })),
        legend: (_jsx(PercentageLegend, { scoreType: "Heidrick", useThreshold: useGetLeadersAtRiskThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
        scoreKey: ScoreTypes.Heidrick,
        scoreFormatter: percentageOfScoreFormatter,
        description: "Percentage of leaders with Heidrick Scores indicating significant gaps against functional " +
            "impact, org impact, capability, and/or potential requirements.",
    },
    {
        label: "Leaders with low AI Scores",
        implementation: (_jsx(LeadershipLowPerformerLevelFunctionMatrix, { scoreType: ScoreTypes.AI })),
        legend: (_jsx(PercentageLegend, { scoreType: "AI", useThreshold: useGetLeadersAtRiskThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
        scoreKey: ScoreTypes.AI,
        scoreFormatter: percentageOfScoreFormatter,
        description: "Percentage of leaders with AI score indicating significant gaps in current roles from " +
            "work history, skills, and qualifications.",
    },
    {
        label: "Leaders with low Functional Impact Scores",
        implementation: (_jsx(LeadershipLowPerformerLevelFunctionMatrix, { scoreType: ScoreTypes.Functional })),
        legend: (_jsx(PercentageLegend, { scoreType: "Functional", useThreshold: useGetLeadersAtRiskThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
        scoreKey: ScoreTypes.Functional,
        scoreFormatter: percentageOfScoreFormatter,
        description: "Percentage of leaders with Functional Impact Scores indicating significant gaps " +
            "against functional impact requirements.",
    },
    {
        label: "Leaders with low Organizational Impact Scores",
        implementation: (_jsx(LeadershipLowPerformerLevelFunctionMatrix, { scoreType: ScoreTypes.Organizational })),
        legend: (_jsx(PercentageLegend, { scoreType: "Organizational", useThreshold: useGetLeadersAtRiskThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
        scoreKey: ScoreTypes.Organizational,
        scoreFormatter: percentageOfScoreFormatter,
        description: "Percentage of leaders with Organizational Impact Scores indicating significant " +
            "gaps against organizational impact requirements.",
    },
    {
        label: "Leaders with low Capability Scores",
        implementation: (_jsx(LeadershipLowPerformerLevelFunctionMatrix, { scoreType: ScoreTypes.Capability })),
        legend: (_jsx(PercentageLegend, { scoreType: "Capabilities", useThreshold: useGetLeadersAtRiskThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
        scoreKey: ScoreTypes.Capability,
        scoreFormatter: percentageOfScoreFormatter,
        description: "Percentage of leaders with Capability Scores indicating significant gaps " +
            "against capability requirements.",
    },
    {
        label: "Leaders with low Potential Scores",
        implementation: (_jsx(LeadershipLowPerformerLevelFunctionMatrix, { scoreType: ScoreTypes.Potential })),
        legend: (_jsx(PercentageLegend, { scoreType: "Potential", useThreshold: useGetLeadersAtRiskThresholdsQuery, descriptionPopup: descriptionPopup, isDescending: true })),
        scoreKey: ScoreTypes.Potential,
        scoreFormatter: percentageOfScoreFormatter,
        description: "Percentage of leaders with Potential Scores indicating significant gaps " +
            "against potential requirements.",
    },
];
var LeadershipRisk = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var overview = useGetLeaderAtRiskOverviewQuery({});
    var navigate = useNavigate();
    var arrowClickables = {
        functional: function () { return navigate(getAllAssessmentRoute("FunctionalImpact")); },
        organizational: function () {
            return navigate(getAllAssessmentRoute("OrganizationalImpact"));
        },
        capability: function () { return navigate(getAllAssessmentRoute("Capability")); },
        potential: function () { return navigate(getAllAssessmentRoute("Potential")); },
    };
    var summaryData = (overview.isSuccess
        ? {
            overall: (_b = (_a = overview.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.overall_score.state,
            functional: (_d = (_c = overview === null || overview === void 0 ? void 0 : overview.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.functional.state,
            organizational: (_f = (_e = overview === null || overview === void 0 ? void 0 : overview.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.organizational.state,
            potential: (_h = (_g = overview === null || overview === void 0 ? void 0 : overview.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.potential.state,
            capability: (_k = (_j = overview === null || overview === void 0 ? void 0 : overview.data) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.capability.state,
        }
        : { overall: "nodata" });
    var InfoPopupWrapper = styled(Box)({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    });
    var ImageWrapper = styled(Box)({
        display: "flex",
        justifyContent: "center",
        width: "855px",
        height: "513px",
    });
    var summaryPopupDescription = (_jsxs(InfoPopupWrapper, { children: [_jsx(Typography, __assign({ component: "span" }, { children: "A summary of organizational risks identified by the percentage of leaders lacking in key attributes - business impact, leadership capability, and potential. Further review is required to fully understand the extent and type of any misalignments." })), _jsx(ImageWrapper, { children: _jsx("img", { src: getRoute("SummaryImage"), width: "100%" }) })] }));
    var strengthPopupInfo = {
        title: "Leadership Risk",
        desc: summaryPopupDescription,
    };
    var summaryRightSideText = (summaryData === null || summaryData === void 0 ? void 0 : summaryData.overall) == Status.Act
        ? "This dashboard shows the alignment of your leaders with their role requirements. " +
            "The results indicate a high number of leaders exhibit notable gaps in fulfilling " +
            "multiple role requirements. Explore the heatmap, by group and attribute, to identify " +
            "the highest risk areas that demand immediate, targeted attention."
        : (summaryData === null || summaryData === void 0 ? void 0 : summaryData.overall) == Status.Watch
            ? "This dashboard shows the alignment of your leaders with their role requirements. " +
                "The results indicate a meaningful number of leaders exhibit some gaps in meeting " +
                "certain role requirements. Explore the heatmap, by group and attribute, to identify " +
                "areas requiring additional monitoring or proactive management."
            : (summaryData === null || summaryData === void 0 ? void 0 : summaryData.overall) == Status.Maintain
                ? "This dashboard shows the alignment of your leaders with their role requirements. " +
                    "While the majority of leaders meet role requirements there are still some areas " +
                    "where improvements can be considered. Explore the heatmap, by group and attribute, " +
                    "to identify key strengths to leverage or any areas that could benefit from refinement."
                : "No data";
    var StatusPopup = function () {
        return (_jsx(StrengthInfoPopUp, { title: strengthPopupInfo.title, desc: strengthPopupInfo.desc }));
    };
    var overviewComponentInfo = {
        inside: (_jsx(ScoreBreakdown, { action: (_o = (_m = (_l = overview.data) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.overall_score) === null || _o === void 0 ? void 0 : _o.state, summaryRightSideText: summaryRightSideText, title: "Organization Overview", text: getOverviewTexts(summaryData), showDescriptions: true, arrowClickables: arrowClickables, statusPopup: _jsx(StatusPopup, {}) })),
    };
    return (_jsx(HeatmappedPage, { "data-testid": "LeadershipRiskPage", heatmaps: untoggledHeatmaps, useScores: useGetLeaderAtRiskScoresQuery, heading: overviewComponentInfo, useOverview: useGetLeaderAtRiskOverviewQuery, countText: "leader" }));
};
export default LeadershipRisk;
