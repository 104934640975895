var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import BackToButton from "../../components/BackToButton";
import SecondaryMainHeading from "../../components/SecondMainHeading/SecondMainHeading";
import { useParams } from "react-router-dom";
import { useGetNineBox, updateNineBoxSettingsQuery, useGetAvailableNineBoxes, } from "../../store/api/core";
import { Form, Icon, IconName, TextInput, TextInputWidth, } from "@eightfold.ai/octuple";
import { createPortal } from "react-dom";
import Dialog from "../../components/Dialog";
import FormRequired from "../../components/FormRequired";
import { NineBox, ReadOnlyNineBox } from "../../components/NineBox/NineBox";
import trStyles from "../../components/TalentReview/TalentReview.module.scss";
import { getRoute } from "../../routes/getAllRoutes";
import { emptyFilters, setFilters } from "../../providers/FilterProvider";
var FilterClearingBackToButton = function (_a) {
    var url = _a.url, title = _a.title;
    var clearFilters = function () { return setFilters(emptyFilters); };
    var handleFilters = function () {
        clearFilters();
    };
    return _jsx(BackToButton, { title: title, url: url, onClick: handleFilters });
};
export var TalentReviewNineBoxBase = function (_a) {
    var children = _a.children, secondaryHeadingTitle = _a.secondaryHeadingTitle, backToButton = _a.backToButton;
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryMainHeading, { backToButton: backToButton, role: "All", level: "All", "data-testid": "SecondaryHeading", secondaryHeadingTitle: secondaryHeadingTitle }), children] }));
};
var EditNineBoxDialog = function (props) {
    var form = Form.useForm()[0];
    var updateNineBoxName = updateNineBoxSettingsQuery({
        substitutions: { id: props.boxId },
        invalidates: [
            { queryKey: ["{ninebox}/nine-box/".concat(props.boxId)] },
            { queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] },
        ],
    });
    var _a = useState(""), formError = _a[0], setFormError = _a[1];
    var _b = useState(""), errorClass = _b[0], setErrorClass = _b[1];
    var onDoneClick = function () {
        form.submit();
    };
    var onFormFinish = function (formValues) {
        if (props.field == "boxName") {
            updateNineBoxName.mutate({ boxName: formValues.name }, {
                onSuccess: function () { return props.onClose(); },
                onError: function (data) {
                    var _a;
                    if (data.message == "400") {
                        setFormError((_a = data.cause) === null || _a === void 0 ? void 0 : _a.boxName[0]);
                        setErrorClass(trStyles.formError);
                    }
                },
            });
        }
        if (props.field == "versionName") {
            updateNineBoxName.mutate({ versionName: formValues.name }, {
                onSuccess: function () { return props.onClose(); },
                onError: function (data) {
                    var _a;
                    if (data.message == "400") {
                        setFormError((_a = data.cause) === null || _a === void 0 ? void 0 : _a.versionName[0]);
                        setErrorClass(trStyles.formError);
                    }
                },
            });
        }
    };
    var boxNameRules = [
        { required: true, message: "9 Box name is required" },
        {
            type: "string",
            max: 30,
            message: "9 Box name cannot be more than ${max} characters",
        },
    ];
    var versionNameRules = [
        { required: true, message: "Version name is required" },
    ];
    var boxNameHeader = "Edit 9 Box Name";
    var versionNameHeader = "Edit Version Name";
    var EditNineBoxForm = function () {
        var initialValues = {
            name: props.initialValue,
        };
        return (_jsxs("div", __assign({ "data-testid": "nineboxform" }, { children: [_jsx(FormRequired, {}), _jsxs(Form, __assign({ form: form, layout: "vertical", autoComplete: "off", onFinish: onFormFinish, initialValues: initialValues }, { children: [_jsx(Form.Item, __assign({ label: "Name", name: "name", rules: props.field == "boxName" ? boxNameRules : versionNameRules, style: { marginBottom: "0" } }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill, classNames: errorClass }) })), updateNineBoxName.isError && (_jsx("div", __assign({ role: "alert", className: "form-item-explain-error ".concat(errorClass) }, { children: formError })))] }))] })));
    };
    return (_jsx(Dialog, { header: props.field == "boxName" ? boxNameHeader : versionNameHeader, body: _jsx(EditNineBoxForm, {}), buttons: {
            okButtonProps: {
                text: "Done",
                onClick: onDoneClick,
                loading: updateNineBoxName.isPending,
            },
            cancelButtonProps: {
                text: "Cancel",
            },
        }, visible: props.visible, onClose: props.onClose }));
};
var ReadOnlyBoxNameTitle = function (_a) {
    var _b;
    var setVisible = _a.setVisible, id = _a.id;
    var nineBoxDetails = useGetNineBox({ id: id });
    var versionName = (((_b = nineBoxDetails === null || nineBoxDetails === void 0 ? void 0 : nineBoxDetails.data) === null || _b === void 0 ? void 0 : _b.data) || {}).versionName;
    var handlePencilClick = function () {
        setVisible(true);
    };
    return (_jsxs("div", __assign({ style: { display: "flex", alignItems: "baseline", gap: "6px" } }, { children: [_jsx("div", { children: versionName || "" }), _jsx("div", __assign({ style: { cursor: "pointer" }, onClick: handlePencilClick, "data-testid": "editPencil" }, { children: _jsx(Icon, { path: IconName.mdiPencil }) }))] })));
};
var ReadOnlyBackToButton = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id;
    var nineBoxDetails = useGetNineBox({ id: id });
    var boxId = (((_b = nineBoxDetails === null || nineBoxDetails === void 0 ? void 0 : nineBoxDetails.data) === null || _b === void 0 ? void 0 : _b.data) || {}).boxId;
    var availableBoxes = useGetAvailableNineBoxes({ boxId: boxId }, { shouldSkipCall: function () { return !boxId; } });
    var currentVersionId = (_e = (_d = (_c = availableBoxes === null || availableBoxes === void 0 ? void 0 : availableBoxes.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.id;
    return currentVersionId ? (_jsx(FilterClearingBackToButton, { title: "Current Version", url: getRoute("TalentReviewNineBox").replace(":id", currentVersionId) })) : (_jsx(_Fragment, {}));
};
export var ReadOnlyTalentReviewNineBoxPage = function () {
    var id = useParams().id;
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    return (_jsxs(TalentReviewNineBoxBase, __assign({ backToButton: _jsx(ReadOnlyBackToButton, { id: id }), secondaryHeadingTitle: _jsx(ReadOnlyBoxNameTitle, { id: id, setVisible: setVisible }) }, { children: [_jsx(ReadOnlyNineBox, { id: id }), visible &&
                createPortal(_jsx(EditNineBoxDialog, { field: "versionName", visible: visible, boxId: id, onClose: function () { return setVisible(false); }, initialValue: versionName }), document.body)] })));
};
var BoxNameTitle = function (_a) {
    var _b;
    var id = _a.id, setInitialBoxName = _a.setInitialBoxName, setVisible = _a.setVisible;
    var nineBoxDetails = useGetNineBox({ id: id });
    var boxName = (((_b = nineBoxDetails === null || nineBoxDetails === void 0 ? void 0 : nineBoxDetails.data) === null || _b === void 0 ? void 0 : _b.data) || {}).boxName;
    var handlePencilClick = function () {
        setInitialBoxName(boxName || "");
        setVisible(true);
    };
    return (_jsxs("div", __assign({ style: { display: "flex", alignItems: "baseline", gap: "6px" } }, { children: [_jsx("div", { children: boxName || "" }), _jsx("div", __assign({ style: { cursor: "pointer" }, onClick: handlePencilClick, "data-testid": "editPencil" }, { children: _jsx(Icon, { path: IconName.mdiPencil }) }))] })));
};
export var TalentReviewNineBoxPage = function () {
    var id = useParams().id;
    var _a = useState(""), initialBoxName = _a[0], setInitialBoxName = _a[1];
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    return (_jsxs(TalentReviewNineBoxBase, __assign({ backToButton: _jsx(FilterClearingBackToButton, { title: "Talent Review", url: getRoute("DashboardTalentReview") }), secondaryHeadingTitle: _jsx(BoxNameTitle, { setVisible: setVisible, setInitialBoxName: setInitialBoxName, id: id }) }, { children: [_jsx(NineBox, { id: id }), visible &&
                createPortal(_jsx(EditNineBoxDialog, { field: "boxName", visible: visible, boxId: id, initialValue: initialBoxName, onClose: function () { return setVisible(false); } }), document.body)] })));
};
