var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var Component = function (_a) {
    var isUp = _a.isUp;
    var transform = "";
    if (isUp) {
        transform = "rotate(180)";
    }
    return (_jsxs("svg", __assign({ width: "17", height: "17", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", transform: transform }, { children: [_jsx("path", { d: "M3.50073 8.37708L8.00171 12.8781L12.5027 8.37708", stroke: "#69717F", "stroke-width": "1.00189", "stroke-miterlimit": "10", "stroke-linecap": "square" }), _jsx("path", { d: "M8.00195 12.2525V3.12555", stroke: "#69717F", "stroke-width": "1.00189", "stroke-miterlimit": "10", "stroke-linecap": "square" })] })));
};
export default Component;
