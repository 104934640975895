import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTheme, styled, Box } from "@mui/material";
import { Button as OctupleButton, ButtonShape, ButtonSize, ButtonVariant as OctupleButtonVariant, } from "@eightfold.ai/octuple";
export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant[ButtonVariant["Default"] = OctupleButtonVariant.Default] = "Default";
    ButtonVariant[ButtonVariant["Neutral"] = OctupleButtonVariant.Neutral] = "Neutral";
    ButtonVariant[ButtonVariant["Primary"] = OctupleButtonVariant.Primary] = "Primary";
    ButtonVariant[ButtonVariant["Secondary"] = OctupleButtonVariant.Secondary] = "Secondary";
})(ButtonVariant || (ButtonVariant = {}));
export var Button = function (props) {
    var theme = useTheme();
    var ButtonContainer = styled(Box)({
        "--button-font-family": "".concat(theme.typography.button.fontFamily),
        "--badge-font-family": "".concat(theme.typography.button.fontFamily),
        ".button": {
            padding: "0 12px",
        },
        ".button [class^='button-text']": {
            fontWeight: "initial",
        },
        ".button [class^='counter'], .button [class*=' counter']": {
            fontSize: "16px",
            fontWeight: "initial",
            height: "24px",
            lineHeight: "24px",
            padding: "0 6px",
            paddingTop: "1px",
            backgroundColor: "white",
            borderRadius: "4px",
        },
    });
    return (_jsx(ButtonContainer, { children: _jsx(OctupleButton, { ariaLabel: "".concat(props.text || "", " button"), classNames: "button", shape: ButtonShape.Pill, size: ButtonSize.Medium, text: "".concat(props.text || ""), variant: props.variant || ButtonVariant.Primary, counter: props.counter || "", loading: props.loading || false, onClick: props.onClick, iconProps: { path: props.iconPath } }) }));
};
export var IconButton = function (props) {
    return (_jsx(OctupleButton, { iconProps: { path: props.iconPath }, ariaLabel: props.ariaLabel || "Icon button", classNames: "icon-button", shape: ButtonShape.Round, size: ButtonSize.Medium, variant: props.variant || ButtonVariant.Primary, onClick: props.onClick, disabled: props.disabled, "data-testid": props.testId }));
};
export default Button;
