var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Button } from "@mui/material";
import "./StrengthInfoPopup.css";
import StrengthInfoGenericModal from "./StrengthInfoGenericModal";
import Question from "../../icons/Question";
export default function StrengthInfoPopUp(_a) {
    var title = _a.title, desc = _a.desc;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    return (_jsxs(Box, __assign({ "data-testid": "strengthInfo" }, { children: [_jsx(Button, __assign({ "aria-label": "information popup", onClick: handleOpen, style: {
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                } }, { children: _jsx(Question, {}) })), open && (_jsx(StrengthInfoGenericModal, { title: title, desc: desc, open: open, close: handleClose }))] })));
}
