// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nVWnodRl4hzeKukcK12z {
  color: var(--grey-color-70);
  font-size: 14px;
  font-family: Avenir, sans-serif;
  font-weight: 350;
  line-height: 20px;
}

.OtuchDghkHjLhk9x1uRj {
  padding: 36px 0 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/SuccessProfile/Table.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,eAAA;EACA,+BAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,oBAAA;AACF","sourcesContent":[".cell {\n  color: var(--grey-color-70);\n  font-size: 14px;\n  font-family: Avenir, sans-serif;\n  font-weight: 350;\n  line-height: 20px;\n}\n\n.showingInfo {\n  padding: 36px 0 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `nVWnodRl4hzeKukcK12z`,
	"showingInfo": `OtuchDghkHjLhk9x1uRj`
};
export default ___CSS_LOADER_EXPORT___;
