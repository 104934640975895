var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { styled } from "@mui/material";
var GetHelpButton = function () {
    var GetHelpBox = styled("a")(function (_a) {
        var theme = _a.theme;
        return ({
            color: "#4f5666",
            padding: "8px 12px",
            fontFamily: theme.typography.fontFamily,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "20px",
            position: "fixed",
            bottom: "120px",
            zIndex: "100000",
            right: "0",
            transform: "rotate(90deg)",
            transformOrigin: "100% 0",
            borderRadius: "5px",
            cursor: "pointer",
            border: "1px solid #d9dce1",
            textDecoration: "none",
            transition: "background-color 0.3s ease-in-out",
            backgroundColor: "#f6f7f8",
            backgroundImage: "linear-gradient(top, #a1a6b1, #69717f)",
        });
    });
    return (_jsx(GetHelpBox, __assign({ "data-testid": "getHelpButton", href: "https://heidricksupport.zendesk.com/hc/en-us", target: "_blank" }, { children: "Get Help" })));
};
export default GetHelpButton;
