var _a;
export var SuccessionTypes;
(function (SuccessionTypes) {
    SuccessionTypes["AtRisk"] = "positions_at_risk";
    SuccessionTypes["WithoutPipelines"] = "positions_without_pipelines";
    SuccessionTypes["ShortageSuccessors"] = "positions_shortage_successors";
    SuccessionTypes["ShortageReadyNow"] = "positions_shortage_ready_now";
    SuccessionTypes["OverleveragedReadyNow"] = "positions_overleveraged_ready_now";
    SuccessionTypes["ReliantLowMatchScores"] = "positions_reliant_low_match_scores";
})(SuccessionTypes || (SuccessionTypes = {}));
export var successionTooltips = (_a = {},
    _a[SuccessionTypes.AtRisk] = "Positions with one or more succession risks that pose a threat to leadership continuity and effectiveness.",
    _a[SuccessionTypes.WithoutPipelines] = "Positions without an associated succession pipeline.",
    _a[SuccessionTypes.ShortageSuccessors] = "Positions with an inadequate number of successors.",
    _a[SuccessionTypes.ShortageReadyNow] = "Positions with an inadequate number of ready now successors.",
    _a[SuccessionTypes.OverleveragedReadyNow] = "Positions with a high number of successors on 3+ succession pipelines.",
    _a[SuccessionTypes.ReliantLowMatchScores] = "Positions with ready now successors whose match scores suggest significant gaps.",
    _a);
export var LeaderedSuccessionTypes;
(function (LeaderedSuccessionTypes) {
    LeaderedSuccessionTypes["MobilityRisk"] = "leaders_mobility_risk";
    LeaderedSuccessionTypes["NoPathForward"] = "leaders_no_path_forward";
    LeaderedSuccessionTypes["OnMultiplePipelines"] = "leaders_on_multiple_pipelines";
})(LeaderedSuccessionTypes || (LeaderedSuccessionTypes = {}));
export var BoxLeaderScoreType;
(function (BoxLeaderScoreType) {
    BoxLeaderScoreType["Performance"] = "Performance";
    BoxLeaderScoreType["Capabilities"] = "Capabilities";
    BoxLeaderScoreType["Potential"] = "Potential";
})(BoxLeaderScoreType || (BoxLeaderScoreType = {}));
export var NineBoxAuditLogUpdateType;
(function (NineBoxAuditLogUpdateType) {
    NineBoxAuditLogUpdateType["SaveLeaders"] = "Save Leaders";
    NineBoxAuditLogUpdateType["MoveLeader"] = "Move Leader";
    NineBoxAuditLogUpdateType["ChangeLabels"] = "Change Labels";
})(NineBoxAuditLogUpdateType || (NineBoxAuditLogUpdateType = {}));
