var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, styled } from "@mui/material";
import { getRoute } from "../../routes/getAllRoutes";
var HomePageHeader = function () {
    var Container = styled(Box)({
        height: "135px",
        backgroundImage: "url(".concat(getRoute("HeaderBackground"), ")"),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "right",
        backgroundPositionY: "bottom",
        marginTop: "3px",
        marginBottom: "20px",
    });
    var LableStyle = styled(Box)({
        padding: "31px 0px 53px 34px",
        fontFamily: "Avenir Medium",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
    });
    return (_jsx(Container, __assign({ "data-testid": "homepageheading" }, { children: _jsx(LableStyle, { children: "Intelligence" }) })));
};
export default HomePageHeader;
