var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { isObject, memoize } from "lodash";
import { useGetLookupsQuery } from "../../store/api/core";
/* eslint-disable  @typescript-eslint/no-explicit-any */
var getNameLookups = function (clientId, data) {
    var _a;
    if (Array.isArray(data)) {
        return data.reduce(function (current, item) { return (__assign(__assign({}, getNameLookups(clientId, item)), current)); }, {});
    }
    if (isObject(data) && (data === null || data === void 0 ? void 0 : data.code) && (data === null || data === void 0 ? void 0 : data.fieldValue)) {
        return __assign((_a = {}, _a[data.code] = data.fieldValue, _a), getNameLookups(clientId, Object.values(data)));
    }
    if (isObject(data)) {
        return getNameLookups(clientId, Object.values(data));
    }
    return {};
};
/* eslint-enable  @typescript-eslint/no-explicit-any */
var getMemoizedNameLookups = memoize(function (clientId, data) {
    return getNameLookups(clientId, data);
});
export var useLookupField = function (_a) {
    var _b, _c, _d;
    var rawClientId = _a.clientId;
    var clientId = rawClientId || "Master";
    var result = useGetLookupsQuery({ clientId: clientId }, { shouldSkipCall: function () { return !clientId; } });
    if (!((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.data) || !clientId) {
        return {};
    }
    return getMemoizedNameLookups(clientId, (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[0]);
};
export var LookupField = function (_a) {
    var clientId = _a.clientId, code = _a.code;
    var lookups = useLookupField({ clientId: clientId });
    var value = lookups[((code === null || code === void 0 ? void 0 : code.toString()) || "").trim()];
    if (!value) {
        if (Object.keys(lookups).length > 0) {
            console.error("do not have a lookup for ".concat(code));
        }
        return _jsx(_Fragment, {});
    }
    return _jsx(_Fragment, { children: value });
};
