var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var Component = function () { return (_jsx("svg", __assign({ width: "15", height: "16", viewBox: "0 0 15 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", __assign({ id: "question" }, { children: [_jsx("circle", { id: "Ellipse 533", cx: "7.5", cy: "8", r: "7", stroke: "#4F5666" }), _jsx("path", { id: "?", d: "M6.83066 9.61326C6.83066 9.28913 6.90313 8.98711 7.04805 8.70718C7.19298 8.42726 7.36842 8.18785 7.57437 7.98895C7.78032 7.79006 7.98627 7.60221 8.19222 7.42541C8.39817 7.24125 8.57361 7.04236 8.71854 6.82873C8.86346 6.60774 8.93593 6.37569 8.93593 6.1326C8.93593 5.76427 8.80244 5.48435 8.53547 5.29282C8.27613 5.09392 7.93669 4.99448 7.51716 4.99448C6.67811 4.99448 6.14035 5.35175 5.90389 6.0663L5 5.58011C5.19069 5.06446 5.51487 4.67403 5.97254 4.40884C6.43021 4.13628 6.94889 4 7.5286 4C8.20748 4 8.78719 4.18416 9.26773 4.55249C9.75591 4.92081 10 5.43278 10 6.0884C10 6.39779 9.92754 6.68877 9.78261 6.96133C9.63768 7.23389 9.46224 7.46961 9.25629 7.66851C9.05034 7.8674 8.84439 8.06262 8.63844 8.25414C8.43249 8.43831 8.25706 8.64825 8.11213 8.88398C7.9672 9.11234 7.89474 9.35543 7.89474 9.61326H6.83066ZM7.35698 12C7.15103 12 6.97559 11.93 6.83066 11.7901C6.68574 11.6501 6.61327 11.4807 6.61327 11.2818C6.61327 11.0829 6.68574 10.9134 6.83066 10.7735C6.97559 10.6335 7.15103 10.5635 7.35698 10.5635C7.57056 10.5635 7.746 10.6335 7.8833 10.7735C8.02822 10.9134 8.10069 11.0829 8.10069 11.2818C8.10069 11.4807 8.02822 11.6501 7.8833 11.7901C7.746 11.93 7.57056 12 7.35698 12Z", fill: "#4F5666" })] })) }))); };
export default Component;
