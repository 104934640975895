var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var Component = function () { return (_jsxs("svg", __assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M6 12.5L18 12.5", stroke: "#C02195", "stroke-width": "1.5" }), _jsx("path", { d: "M12.4609 6.5L18.4609 12.5L12.4609 18.5", stroke: "#C02195", "stroke-width": "1.5" })] }))); };
export default Component;
