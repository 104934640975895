var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createRef, useEffect } from "react";
import ResetButton from "../ResetButton/ResetButton";
import { Box } from "@mui/material";
import { FilterResetEventName, MultiSelectFilter } from "./MultiSelectFilter";
import { emptyFilters, filterListeners, setFilters, } from "../../providers/FilterProvider";
import { getAttributeType, getClientId, LeaderAttribute, useGetLookupsQuery, useGetLookupsFiltersQuery, } from "../../store/api/core";
import { FloatingSpinner } from "../SpinnerAndErrorer/SpinnerAndErrorer";
import { isObject, memoize } from "lodash";
import { Stack } from "@eightfold.ai/octuple";
import { SearchRoleNameFilter } from "./SearchRoleNameFilter";
import { Additional } from "./Additional";
import { useClient } from "../../hooks/client";
export var AvailableFilters;
(function (AvailableFilters) {
    AvailableFilters[AvailableFilters["BusinessUnit"] = 0] = "BusinessUnit";
    AvailableFilters[AvailableFilters["Function"] = 1] = "Function";
    AvailableFilters[AvailableFilters["FunctionSuccessProfile"] = 2] = "FunctionSuccessProfile";
    AvailableFilters[AvailableFilters["SubFunction"] = 3] = "SubFunction";
    AvailableFilters[AvailableFilters["SubFunctionSuccessProfile"] = 4] = "SubFunctionSuccessProfile";
    AvailableFilters[AvailableFilters["Level"] = 5] = "Level";
    AvailableFilters[AvailableFilters["LevelSuccessProfile"] = 6] = "LevelSuccessProfile";
    AvailableFilters[AvailableFilters["Location"] = 7] = "Location";
    AvailableFilters[AvailableFilters["Manager"] = 8] = "Manager";
    AvailableFilters[AvailableFilters["RoleName"] = 9] = "RoleName";
    AvailableFilters[AvailableFilters["Reset"] = 10] = "Reset";
    AvailableFilters[AvailableFilters["Spinner"] = 11] = "Spinner";
    AvailableFilters[AvailableFilters["RetentionRisk"] = 12] = "RetentionRisk";
    AvailableFilters[AvailableFilters["CareerInterest"] = 13] = "CareerInterest";
    AvailableFilters[AvailableFilters["AssessmentSource"] = 14] = "AssessmentSource";
    AvailableFilters[AvailableFilters["Age"] = 15] = "Age";
    AvailableFilters[AvailableFilters["DerailerRisk"] = 16] = "DerailerRisk";
    AvailableFilters[AvailableFilters["RelocationPreference"] = 17] = "RelocationPreference";
    AvailableFilters[AvailableFilters["Gender"] = 18] = "Gender";
    AvailableFilters[AvailableFilters["Ethnicity"] = 19] = "Ethnicity";
    AvailableFilters[AvailableFilters["NineBox"] = 20] = "NineBox";
    AvailableFilters[AvailableFilters["Location2"] = 21] = "Location2";
    AvailableFilters[AvailableFilters["Division"] = 22] = "Division";
    AvailableFilters[AvailableFilters["RoleProfileId"] = 23] = "RoleProfileId";
})(AvailableFilters || (AvailableFilters = {}));
var AdditionalAvailableFilters = new Set([
    AvailableFilters.RetentionRisk,
    AvailableFilters.CareerInterest,
    AvailableFilters.AssessmentSource,
    AvailableFilters.Age,
    AvailableFilters.DerailerRisk,
    AvailableFilters.RelocationPreference,
    AvailableFilters.Gender,
    AvailableFilters.Ethnicity,
    AvailableFilters.NineBox,
]);
var hasAdditionalFilters = function (filters) {
    return Array.from(filters).some(function (filter) { return AdditionalAvailableFilters.has(filter); });
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
var getFunctionMap = function (data, current, result) {
    if (Array.isArray(data)) {
        return data.forEach(function (value) {
            getFunctionMap(value, current, result);
        });
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.PrimaryRole &&
        (data === null || data === void 0 ? void 0 : data.subFunctions)) {
        return getFunctionMap(Object.values(data.subFunctions), data.code, result);
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.SecondaryRole &&
        getAttributeType(current) == LeaderAttribute.PrimaryRole) {
        result.functionFilters[data.code] = current;
        if (!result.subFunctionFilters[current]) {
            result.subFunctionFilters[current] = new Set();
        }
        result.subFunctionFilters[current].add(data.code);
    }
    if (isObject(data)) {
        getFunctionMap(Object.values(data), current, result);
    }
};
/* eslint-enable  @typescript-eslint/no-explicit-any */
var getMemoizedFunctionMap = memoize(function (data) {
    var result = { functionFilters: {}, subFunctionFilters: {} };
    getFunctionMap(data, undefined, result);
    return result;
});
export var useFunctionMap = function (_a) {
    var _b;
    var clientId = _a.clientId;
    var client = useClient();
    var clientIdToUse = clientId || client.clientId;
    var response = useGetLookupsQuery({ clientId: clientIdToUse }, { shouldSkipCall: function () { return !clientIdToUse; } });
    return getMemoizedFunctionMap((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data[0]);
};
export var DefaultFilterSet = new Set([
    AvailableFilters.Location,
    AvailableFilters.Location2,
    AvailableFilters.BusinessUnit,
    AvailableFilters.Division,
    AvailableFilters.Function,
    AvailableFilters.SubFunction,
    AvailableFilters.RoleProfileId,
    AvailableFilters.Level,
    AvailableFilters.Manager,
    AvailableFilters.Reset,
    AvailableFilters.Spinner,
].concat(Array.from(AdditionalAvailableFilters)));
export var LandingPageFilters = new Set([
    AvailableFilters.Location,
    AvailableFilters.Location2,
    AvailableFilters.BusinessUnit,
    AvailableFilters.Division,
    AvailableFilters.RoleProfileId,
    AvailableFilters.Spinner,
    AvailableFilters.Reset,
    AvailableFilters.NineBox,
].concat(Array.from(AdditionalAvailableFilters)));
LandingPageFilters.delete(AvailableFilters.Gender);
LandingPageFilters.delete(AvailableFilters.Ethnicity);
export var SuccessProfileFilterSet = new Set([
    AvailableFilters.FunctionSuccessProfile,
    AvailableFilters.SubFunctionSuccessProfile,
    AvailableFilters.LevelSuccessProfile,
    AvailableFilters.RoleName,
    AvailableFilters.Spinner,
    AvailableFilters.Reset,
]);
var FunctionFilter = function (_a) {
    var _b;
    var useDataSource = _a.useDataSource;
    var response = useGetLookupsQuery({ clientId: getClientId() });
    var functionMap = getMemoizedFunctionMap((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data[0]);
    var filterRereader = createRef(null);
    var eventListener = function (_a, allNext) {
        var previous = _a.subFunctionFilter, settable = _a.functionFilter;
        var next = allNext.subFunctionFilter;
        var subChanges = (next || []).filter(function (value) { return !(previous === null || previous === void 0 ? void 0 : previous.includes(value)); });
        var changes = subChanges.map(function (value) { return functionMap.functionFilters[value]; });
        if (!changes.every(function (value) { return settable === null || settable === void 0 ? void 0 : settable.includes(value); })) {
            var mergedFunctions_1 = Array.from(new Set(changes.concat(settable || [])));
            if (!mergedFunctions_1.every(function (value) { return settable === null || settable === void 0 ? void 0 : settable.includes(value); }) ||
                mergedFunctions_1.length != (settable === null || settable === void 0 ? void 0 : settable.length)) {
                setTimeout(function () {
                    setFilters(__assign(__assign({}, allNext), { subFunctionFilter: next, functionFilter: mergedFunctions_1 }));
                    setTimeout(function () {
                        filterRereader.current && filterRereader.current();
                    }, 0);
                }, 0);
            }
        }
    };
    useEffect(function () {
        filterListeners.add(eventListener);
        return function () {
            filterListeners.delete(eventListener);
        };
    }, [response]);
    return (_jsx(MultiSelectFilter, { label: "Function", entity: "function", filterEntity: "functionFilter", filterRereader: filterRereader, useOptionsQuery: useDataSource }));
};
var SubFunctionFilter = function (_a) {
    var _b;
    var useDataSource = _a.useDataSource;
    var response = useGetLookupsQuery({ clientId: getClientId() });
    var functionMap = getMemoizedFunctionMap((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data[0]);
    var filterRereader = createRef(null);
    var eventListener = function (_a, allNext) {
        var previous = _a.functionFilter, settable = _a.subFunctionFilter;
        var next = allNext.functionFilter;
        var removedFunctions = (previous || []).filter(function (value) { return !(next === null || next === void 0 ? void 0 : next.includes(value)); });
        var removedSubFunctions = removedFunctions.reduce(function (current, value) {
            return current.concat(Array.from(functionMap.subFunctionFilters[value] || []));
        }, []);
        var newSettable = Array.from(new Set(__spreadArray([], (settable || []), true).filter(function (value) { return !removedSubFunctions.includes(value); })));
        if (!newSettable.every(function (value) { return (settable || []).includes(value); }) ||
            newSettable.length != (settable === null || settable === void 0 ? void 0 : settable.length)) {
            setTimeout(function () {
                setFilters(__assign(__assign({}, allNext), { functionFilter: next, subFunctionFilter: newSettable }));
                setTimeout(function () {
                    filterRereader.current && filterRereader.current();
                }, 0);
            }, 0);
        }
    };
    useEffect(function () {
        filterListeners.add(eventListener);
        return function () {
            filterListeners.delete(eventListener);
        };
    }, [response]);
    return (_jsx(MultiSelectFilter, { label: "Sub-Function", entity: "sub_function", filterEntity: "subFunctionFilter", filterRereader: filterRereader, useOptionsQuery: useDataSource }));
};
var useFilterComponents = function (_a) {
    var clearFilters = _a.clearFilters;
    var defaultClearFilters = function () {
        setFilters(emptyFilters);
    };
    var filterOrder = [
        {
            key: AvailableFilters.Location,
            implementation: (_jsx(Box, { children: _jsx(MultiSelectFilter, { entity: "location", label: "Location 1" }) }, AvailableFilters.Location)),
        },
        {
            key: AvailableFilters.Location2,
            implementation: (_jsx(Box, { children: _jsx(MultiSelectFilter, { entity: "location_2", label: "Location 2" }) }, AvailableFilters.Location2)),
        },
        {
            key: AvailableFilters.BusinessUnit,
            implementation: (_jsx(MultiSelectFilter, { entity: "business_unit", label: "Business Unit" }, AvailableFilters.BusinessUnit)),
        },
        {
            key: AvailableFilters.Division,
            implementation: (_jsx(MultiSelectFilter, { entity: "division", label: "Division" }, AvailableFilters.Division)),
        },
        {
            key: AvailableFilters.Function,
            implementation: _jsx(FunctionFilter, {}, AvailableFilters.Function),
        },
        {
            key: AvailableFilters.FunctionSuccessProfile,
            implementation: (_jsx(FunctionFilter, { useDataSource: useGetLookupsFiltersQuery }, AvailableFilters.FunctionSuccessProfile)),
        },
        {
            key: AvailableFilters.SubFunction,
            implementation: _jsx(SubFunctionFilter, {}, AvailableFilters.SubFunction),
        },
        {
            key: AvailableFilters.SubFunctionSuccessProfile,
            implementation: (_jsx(SubFunctionFilter, { useDataSource: useGetLookupsFiltersQuery }, AvailableFilters.SubFunctionSuccessProfile)),
        },
        {
            key: AvailableFilters.RoleProfileId,
            implementation: (_jsx(MultiSelectFilter, { entity: "roles", filterEntity: "roleProfileId", label: "Role" }, AvailableFilters.RoleProfileId)),
        },
        {
            key: AvailableFilters.Level,
            implementation: (_jsx(MultiSelectFilter, { label: "Level", entity: "level" }, AvailableFilters.Level)),
        },
        {
            key: AvailableFilters.LevelSuccessProfile,
            implementation: (_jsx(MultiSelectFilter, { label: "Level", entity: "level", useOptionsQuery: useGetLookupsFiltersQuery }, AvailableFilters.LevelSuccessProfile)),
        },
        {
            key: AvailableFilters.Manager,
            implementation: (_jsx(MultiSelectFilter, { label: "Manager", entity: "manager" }, AvailableFilters.Manager)),
        },
        {
            key: AvailableFilters.RoleName,
            implementation: (_jsx(Box, { children: _jsx(SearchRoleNameFilter, {}) }, AvailableFilters.RoleName)),
        },
        {
            key: AvailableFilters.Spinner,
            implementation: _jsx(FloatingSpinner, {}, AvailableFilters.Spinner),
        },
        {
            key: AvailableFilters.Reset,
            implementation: (_jsx(ResetButton, { label: "Reset filters", onClick: function () {
                    (clearFilters || defaultClearFilters)();
                    setTimeout(function () {
                        window.dispatchEvent(new CustomEvent(FilterResetEventName, {}));
                    }, 0);
                } }, AvailableFilters.Reset)),
        },
        {
            key: AvailableFilters.Additional,
            implementation: (_jsx(ResetButton, { label: "Reset filters", onClick: function () {
                    (clearFilters || defaultClearFilters)();
                    setTimeout(function () {
                        window.dispatchEvent(new CustomEvent(FilterResetEventName, {}));
                    }, 0);
                } }, Math.random())),
        },
    ];
    return filterOrder;
};
var RightSideFilters = new Set([
    AvailableFilters.Spinner,
    AvailableFilters.Reset,
]);
var isRightSideFilter = function (_a) {
    var key = _a.key;
    return RightSideFilters.has(key);
};
var isLeftSideFilter = function (_a) {
    var key = _a.key;
    return !isRightSideFilter({ key: key });
};
var implementationalize = function (_a) {
    var implementation = _a.implementation;
    return implementation;
};
var appendTrailer = function (implementations, trailer) {
    if (!trailer) {
        return implementations;
    }
    if (Array.isArray(trailer)) {
        return implementations.concat(trailer);
    }
    return implementations.concat([trailer]);
};
export var Filters = function (_a) {
    var availableFilters = _a.availableFilters, clearFilters = _a.clearFilters, leftTrailer = _a.leftTrailer, rightTrailer = _a.rightTrailer;
    var filters = useFilterComponents({ clearFilters: clearFilters });
    var presentFilters = new Set(availableFilters || DefaultFilterSet);
    var isUsedFilter = function (_a) {
        var key = _a.key;
        return presentFilters.has(key);
    };
    var left = appendTrailer(filters
        .filter(isUsedFilter)
        .filter(isLeftSideFilter)
        .map(implementationalize), leftTrailer).filter(function (v) { return v; });
    var right = appendTrailer(filters
        .filter(isUsedFilter)
        .filter(isRightSideFilter)
        .map(implementationalize)
        .filter(function (v) { return v; })
        .concat(hasAdditionalFilters(availableFilters || new Set())
        ? [_jsx(Additional, { filters: availableFilters || new Set() })]
        : []), rightTrailer);
    return (_jsxs(Stack, __assign({ fullWidth: true, direction: "horizontal", justify: "space-between" }, { children: [_jsx(Stack, __assign({ direction: "horizontal", flexGap: "s", wrap: "wrap" }, { children: left })), _jsx(Stack, __assign({ direction: "horizontal", flexGap: "s", wrap: "nowrap" }, { children: right }))] })));
};
