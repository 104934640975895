var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function useEndpointPagination(_a) {
    var useHook = _a.useHook, limit = _a.limit, parameters = _a.parameters;
    var usableLimit = limit || 10;
    return function (_a) {
        var page = _a.page, sort = _a.sort;
        var offset = usableLimit * page;
        var response = useHook(__assign(__assign({}, parameters), __assign({ limit: usableLimit, offset: offset }, (sort ? { sort: sort } : {}))));
        if (!(response === null || response === void 0 ? void 0 : response.data)) {
            return {
                data: null,
                paginationStatus: {
                    offset: offset,
                    limit: limit,
                    totalCount: 0,
                    pageCount: 0,
                },
            };
        }
        return {
            data: response.data.data,
            paginationStatus: response.data.metadata.pagination,
        };
    };
}
