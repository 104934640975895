import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@mui/material";
import React, { memo } from "react";
import { UnselectedFilter } from "./UnselectedFilter";
import { SelectedFilter } from "./SelectedFilter";
export var HeaderFilter = memo(function (_a) {
    var filterPopped = _a.filterPopped, onChange = _a.onChange;
    return (_jsx(_Fragment, { children: _jsx(Box, { children: !filterPopped ? (_jsx(UnselectedFilter, { onClick: function () {
                    onChange(true);
                } })) : (_jsx(SelectedFilter, { onClick: function () {
                    onChange(false);
                } })) }) }));
});
