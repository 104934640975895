export var ActBigDarkSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='%23FF6C6C' /%3E%3Cpath d='M19.1318 8.51943C19.5156 7.84761 20.4843 7.84761 20.8682 8.51943L31.1451 26.5039C31.526 27.1705 31.0446 28 30.2768 28L9.72318 28C8.95536 28 8.47399 27.1705 8.85494 26.5039L19.1318 8.51943Z' fill='%23FFA7A7' stroke='%230B0C10' stroke-width='1.5' /%3E%3Cpath d='M20 14V17V22' stroke='%230B0C10' stroke-width='1.5' /%3E%3Cpath d='M20 24L20 25' stroke='%230B0C10' stroke-width='1.5' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
export var ActBigLightSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='%23FFA7A7' /%3E%3Cpath d='M19.1318 8.51943C19.5156 7.84761 20.4843 7.84761 20.8682 8.51943L31.1451 26.5039C31.526 27.1705 31.0446 28 30.2768 28L9.72318 28C8.95536 28 8.47399 27.1705 8.85494 26.5039L19.1318 8.51943Z' fill='%23FFA7A7' stroke='%230B0C10' stroke-width='1.5' /%3E%3Cpath d='M20 14V17V22' stroke='%230B0C10' stroke-width='1.5' /%3E%3Cpath d='M20 24L20 25' stroke='%230B0C10' stroke-width='1.5' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
export var WatchBigDarkSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='%23FFD56A' /%3E%3Cpath d='M20 29C14.9692 29 10.3072 25.4869 8 20.0053C10.3009 14.5173 14.9616 11 20 11C25.0384 11 29.6992 14.5159 32 20.0053C29.6925 25.4869 25.0308 29 20 29Z' fill='%23FFE6A6' stroke='%230B0C10' stroke-width='1.5' /%3E%3Ccircle cx='20' cy='20' r='3' stroke='%230B0C10' stroke-width='1.5' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
export var WatchBigLightSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='%23FFE6A6' /%3E%3Cpath d='M20 29C14.9692 29 10.3072 25.4869 8 20.0053C10.3009 14.5173 14.9616 11 20 11C25.0384 11 29.6992 14.5159 32 20.0053C29.6925 25.4869 25.0308 29 20 29Z' fill='%23FFE6A6' stroke='%230B0C10' stroke-width='1.5' /%3E%3Ccircle cx='20' cy='20' r='3' stroke='%230B0C10' stroke-width='1.5' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
export var MaintainBigDarkSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='%23ABE1C7' /%3E%3Ccircle cx='20' cy='20' r='11.25' fill='%23D5F0E3' stroke='%230B0C10' stroke-width='1.5' /%3E%3Cpath d='M15.2 19.7882L18.9384 23.6L26 16.4' stroke='%230B0C10' stroke-width='1.5' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
export var MaintainBigLightSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='%23D5F0E3' /%3E%3Ccircle cx='20' cy='20' r='11.25' fill='%23D5F0E3' stroke='%230B0C10' stroke-width='1.5' /%3E%3Cpath d='M15.2 19.7882L18.9384 23.6L26 16.4' stroke='%230B0C10' stroke-width='1.5' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
export var NodataBigDarkSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='%23F1F1F1' /%3E%3Crect x='8' y='19.25' width='24' height='1.5' fill='%230B0C10' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
export var NodataBigLightSvg = {
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Crect width='40' height='40' rx='20' fill='white' /%3E%3Crect x='8' y='19.25' width='24' height='1.5' fill='%230B0C10' /%3E%3C/svg%3E\");",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
};
