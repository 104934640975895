import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/material";
var StyledCircle = styled("div")({
    width: "14px",
    height: "14px",
    borderRadius: "34px",
    backgroundColor: "#FFFFEB",
    border: "1px solid rgba(158, 95, 0, 0.75)",
});
var CircleComponent = function () {
    return _jsx(StyledCircle, {});
};
export default CircleComponent;
