// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aCmR2dNadzKpKGFwTizZ {
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.32px;
}

.GdB6z7PLZLlWs7wNEauw {
  display: flex;
  justify-content: space-between;
}

.xJ4oiGgHYnDITuQHb4Vt {
  padding: 24px;
  margin-top: 32px;
  border: 1px solid var(--grey-gradient-end-color-10);
  border-radius: 25px;
}

.g33Rcvhxw5eG2RDSrHDt {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.J66ovA6BBSGOVbz0Ibgs {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  gap: 24px;
}

.LWmOKVsrEqbJx2tcqkGp {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AqgqxVrAekPUEpdhq4mX {
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--grey-gradient-end-color-10);
}

.t1U24EsYraZjn3V29wGX {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.wJnu0u1GhzHMomMzAqy1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  font-family: Avenir Heavy, sans-serif;
}

.bU8Tr0J91_1K3UQAHJPv {
  font-size: 32px;
  font-family: Avenir Heavy, sans-serif;
  color: var(--grey-color-90);
}

.PqdqJDIWHnKJ8U0BG4qQ {
  margin: 30px 0 0 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Diversity/Diversity.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,gBAAA;EACA,mDAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mDAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qCAAA;AACF;;AAEA;EACE,eAAA;EACA,qCAAA;EACA,2BAAA;AACF;;AAEA;EACE,qBAAA;AACF","sourcesContent":[".labelStyle {\n  font-style: normal;\n  font-weight: 400;\n  line-height: 40px;\n  letter-spacing: -0.32px;\n}\n\n.pipelineWrapper {\n  display: flex;\n  justify-content: space-between;\n}\n\n.demographicsContainer {\n  padding: 24px;\n  margin-top: 32px;\n  border: 1px solid var(--grey-gradient-end-color-10);\n  border-radius: 25px;\n}\n\n.demographicsTitle {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 28px;\n}\n\n.circleHeaderWrapper {\n  display: flex;\n  justify-content: space-between;\n  margin: 24px 0;\n  gap: 24px;\n}\n\n.donutWrapper {\n  width: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.statusWrapper {\n  padding: 24px;\n  border-radius: 16px;\n  border: 1px solid var(--grey-gradient-end-color-10);\n}\n\n.statusContainer {\n  display: flex;\n  justify-content: space-between;\n  gap: 10px;\n}\n\n.allLeadersTitle {\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 28px;\n  font-family: Avenir Heavy, sans-serif;\n}\n\n.mainTitle {\n  font-size: 32px;\n  font-family: Avenir Heavy, sans-serif;\n  color: var(--grey-color-90);\n}\n\n.outerFilterLayout {\n  margin: 30px 0 0 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelStyle": `aCmR2dNadzKpKGFwTizZ`,
	"pipelineWrapper": `GdB6z7PLZLlWs7wNEauw`,
	"demographicsContainer": `xJ4oiGgHYnDITuQHb4Vt`,
	"demographicsTitle": `g33Rcvhxw5eG2RDSrHDt`,
	"circleHeaderWrapper": `J66ovA6BBSGOVbz0Ibgs`,
	"donutWrapper": `LWmOKVsrEqbJx2tcqkGp`,
	"statusWrapper": `AqgqxVrAekPUEpdhq4mX`,
	"statusContainer": `t1U24EsYraZjn3V29wGX`,
	"allLeadersTitle": `wJnu0u1GhzHMomMzAqy1`,
	"mainTitle": `bU8Tr0J91_1K3UQAHJPv`,
	"outerFilterLayout": `PqdqJDIWHnKJ8U0BG4qQ`
};
export default ___CSS_LOADER_EXPORT___;
