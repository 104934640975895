var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from "@mui/material";
import React from "react";
import { getRoute } from "../../routes/getAllRoutes";
var SecondaryMainHeading = function (_a) {
    var children = _a.children, backToButton = _a.backToButton, secondaryHeadingTitle = _a.secondaryHeadingTitle;
    var Container = styled(Box)({
        padding: "32px",
        backgroundImage: "url(".concat(getRoute("HeaderBackground"), ")"),
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "right",
        backgroundPositionY: "bottom",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    });
    var MainFunctionAndLevelTitle = styled(Box)({
        display: "flex",
        flexDirection: "row",
    });
    var FunctionAndLevelTitle = styled(Box)({
        fontFamily: "Avenir Medium",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "32px",
        lineHeight: "54px",
        color: "#1A212E",
        letterSpacing: "-0.41",
    });
    return (_jsxs(Container, __assign({ "data-testid": "secondHeading" }, { children: [backToButton, _jsx(MainFunctionAndLevelTitle, { children: _jsx(FunctionAndLevelTitle, { children: secondaryHeadingTitle }) }), children] })));
};
export default SecondaryMainHeading;
