var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, styled } from "@mui/material";
import * as React from "react";
import "./HDSpinner.css";
var HDSpinner = function (props) {
    var StyledBox = styled("div")({
        color: "#146DA6",
    });
    return (_jsx(_Fragment, { children: _jsx(StyledBox, __assign({ "data-testid": "heidrick-spinner", className: "spinner" }, { children: _jsx(Box, {}) })) }));
};
export default HDSpinner;
