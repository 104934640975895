var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@mui/material";
import React from "react";
export var SelectedFilter = function (_a) {
    var onClick = _a.onClick, ref = _a.ref;
    return (_jsx(Box, __assign({ "data-testid": "selected-filter", onClick: onClick }, { children: _jsxs("svg", __assign({ ref: ref, width: "29", height: "20", viewBox: "0 0 29 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { x: "0.5", width: "28", height: "20", rx: "10", fill: "#B0F3FE" }), _jsx("path", { d: "M15.8438 10V15.25C15.8646 15.4792 15.8021 15.6667 15.6562 15.8125C15.5104 15.9375 15.3438 16 15.1562 16C14.9896 16 14.8333 15.9375 14.6875 15.8125L13.375 14.4688C13.2083 14.3021 13.1354 14.1146 13.1562 13.9062V10L9.3125 5.09375C9.1875 4.94792 9.13542 4.78125 9.15625 4.59375C9.19792 4.40625 9.28125 4.26042 9.40625 4.15625C9.55208 4.05208 9.69792 4 9.84375 4H19.1562C19.3021 4 19.4375 4.05208 19.5625 4.15625C19.7083 4.26042 19.7917 4.40625 19.8125 4.59375C19.8542 4.78125 19.8125 4.94792 19.6875 5.09375L15.8438 10Z", fill: "#025966" })] })) })));
};
