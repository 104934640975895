import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./src/reportWebVitals";
import Application from "./src/Application";
var container = document.getElementById("root");
if (!container) {
    throw "can't find root element";
}
var root = createRoot(container);
root.render(_jsx(Application, {}));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
