var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSearchParams } from "../../hooks/searchParams";
import { useGetPermissions, useGetClientListPlatformQuery, } from "../../store/api/core";
import { PermissionResource, PermissionResourceType, } from "../../store/api/permissions";
import { Box, styled } from "@mui/material";
import SpinnerAndErrorer from "../../components/SpinnerAndErrorer/SpinnerAndErrorer";
import styles from "./RequiredClientId.module.scss";
var ClientIdEndpointCaller = function () {
    var _a, _b, _c, _d;
    var clientId = "";
    var permissions = useGetPermissions();
    var _e = useState(""), referrer = _e[0], setReferrer = _e[1];
    var clientDetails = useGetClientListPlatformQuery({ navigatorUrl: referrer }, { shouldSkipCall: function () { return !referrer; } });
    var LoaderContainer = styled(Box)({
        position: "fixed",
        display: "flex",
        flexDirection: "row",
        left: "calc(50% - 150px)",
        top: "50%",
    });
    if (!permissions || clientDetails.isLoading) {
        return (_jsxs(LoaderContainer, { children: [" ", _jsx(SpinnerAndErrorer, { conditions: { spin: function () { return false; }, error: function () { return false; } } }), " "] }));
    }
    for (var _i = 0, permissions_1 = permissions; _i < permissions_1.length; _i++) {
        var permission = permissions_1[_i];
        if (permission.resourceName === PermissionResource.ORG_DASHBOARD_UI &&
            permission.resourceType === PermissionResourceType.Application &&
            ((_a = permission.clientIds) === null || _a === void 0 ? void 0 : _a.length) === 1 &&
            permission.clientIds[0] !== "*") {
            clientId = permission.clientIds[0];
        }
    }
    if (!clientId && localStorage.getItem("referrer")) {
        // Try a lookup with the referrer
        if (!referrer) {
            setReferrer(localStorage.getItem("referrer") || "");
            return;
        }
        if (((_b = clientDetails.data) === null || _b === void 0 ? void 0 : _b.data) && ((_c = clientDetails.data) === null || _c === void 0 ? void 0 : _c.data.length) === 1) {
            clientId = (_d = clientDetails.data) === null || _d === void 0 ? void 0 : _d.data[0].id;
        }
    }
    if (clientId) {
        localStorage.setItem("clientId", clientId);
        localStorage.removeItem("referrer");
    }
    else {
        // If we still don't have a client ID, we need to show an error and send the user
        // Admin Portal
        if (process.env.REACT_APP_ADMIN_PORTAL_URL) {
            window.location.href = "".concat(process.env.REACT_APP_ADMIN_PORTAL_URL);
        }
        return (_jsxs("div", __assign({ className: "".concat(styles.clientErrorContainer) }, { children: [_jsx("h1", { children: "Client not found" }), _jsx("p", { children: "Please contact your administrator to ensure you have access to the application." })] })));
    }
    return _jsx(Outlet, {});
};
export var RequiredClientId = function () {
    var searchParams = useSearchParams()[0];
    var clientId = searchParams.get("client");
    if (!clientId) {
        // Check if the client ID is in the URL path
        var params = useParams();
        clientId = params.clientId;
    }
    if (clientId) {
        localStorage.setItem("clientId", clientId);
    }
    else if (!localStorage.getItem("clientId")) {
        return _jsx(ClientIdEndpointCaller, {});
    }
    return _jsx(Outlet, {});
};
