import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/material";
var StyledCircle = styled("div")({
    width: "14px",
    height: "14px",
    borderRadius: "14px",
    border: "1px solid rgba(142, 62, 59, 0.75)",
    backgroundColor: "#FFEFEF",
});
var CircleComponent = function () {
    return _jsx(StyledCircle, {});
};
export default CircleComponent;
