var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from "@mui/material";
import React from "react";
import "../../hd-ui-kit/HDTheme.css";
import { Status } from "../../common/action";
var Summary = function (_a) {
    var actionIn = _a.action, summaryRightSideText = _a.summaryRightSideText, title = _a.title, statusPopup = _a.statusPopup;
    var actionText = !actionIn ? "No data" : actionIn;
    var ActionHeading = styled(Box)({
        width: "250px",
        font: "500 31px Avenir",
        lineHeight: "41px",
        textTransform: "capitalize",
    });
    var ActionTitle = styled(Box)({
        height: "19px",
        fontFamily: "Avenir medium",
        color: "#0B0C10",
        fontSize: "16px",
        fontWeight: "500",
    });
    var TextContainer = styled(Box)({
        width: "750",
        font: "300 18px Avenir Light",
        fontStyle: "normal",
        lineHeight: "26px",
    });
    var Container = styled(Box)({
        padding: "0 0 62px",
    });
    var MainContainerWrapper = styled(Box)({
        display: "flex",
        padding: "32px",
        borderRadius: "20px",
        backgroundColor: "#F8F8F8",
    });
    return (_jsx(Container, __assign({ "data-testid": "Summary" }, { children: _jsxs(MainContainerWrapper, { children: [_jsxs(Box, { children: [_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "baseline" } }, { children: [title && _jsx(ActionTitle, { children: title }), statusPopup] })), _jsx(ActionHeading, __assign({ sx: {
                                color: function (theme) {
                                    return actionText === Status.Act
                                        ? "#993838"
                                        : actionText === Status.Watch
                                            ? theme.palette.warning.dark
                                            : actionText === Status.Maintain
                                                ? theme.palette.success.dark
                                                : "#000000";
                                },
                            } }, { children: actionText }))] }), _jsx(Box, __assign({ sx: { display: "flex", alignItems: "center", marginTop: "3px" } }, { children: _jsx(TextContainer, __assign({ "aria-label": "heading summary" }, { children: summaryRightSideText })) }))] }) })));
};
export var ScoreBreakdown = function (_a) {
    var action = _a.action, title = _a.title, text = _a.text, summaryRightSideText = _a.summaryRightSideText, statusPopup = _a.statusPopup;
    return (_jsx(Summary, { action: action, summaryRightSideText: summaryRightSideText, text: text || "", title: title, statusPopup: statusPopup }));
};
export default Summary;
