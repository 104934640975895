// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HLtYzhADsxKgj_qUCiWO {
  margin: 32px;
}

.I6noNFHr0DfX9kXs0_JJ {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/FilteredOverviewPage/FilteredOverviewPage.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".filters {\n  margin: 32px;\n}\n\n.scoreCards {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": `HLtYzhADsxKgj_qUCiWO`,
	"scoreCards": `I6noNFHr0DfX9kXs0_JJ`
};
export default ___CSS_LOADER_EXPORT___;
