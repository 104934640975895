// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OMOFFBy9aaRhzdzQvFDg {
  font-family: "Avenir Book", sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.OMOFFBy9aaRhzdzQvFDg .m8GaYqZExGTx9JtOH5hP {
  padding-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/FormRequired/FormRequired.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".required {\n  font-family: \"Avenir Book\", sans-serif;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  justify-content: right;\n}\n\n.required .requiredText {\n  padding-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"required": `OMOFFBy9aaRhzdzQvFDg`,
	"requiredText": `m8GaYqZExGTx9JtOH5hP`
};
export default ___CSS_LOADER_EXPORT___;
