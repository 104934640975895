var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from "react";
import SecondaryMainHeading from "../SecondMainHeading/SecondMainHeading";
import { Box } from "@mui/material";
import { Filters } from "../Filters/Filters";
import { useScoreCardComponents } from "./Scores";
import { percentageOfScoreFormatterBase } from "../LevelFunctionMatrix/Variants";
import { ControlledOverviewTabs, } from "../OveriewTabs/OverviewTabs";
import { FilterContext } from "../../providers/FilterProvider";
import styles from "./FilteredOverviewPage.module.scss";
import { Stack } from "@eightfold.ai/octuple";
export var textLowScorerGetter = function (count, score, totalCount) {
    return percentageOfScoreFormatterBase({ value: score, count: count }, totalCount);
};
export function ScoreSelectorRow(_a) {
    var _b;
    var textGetter = _a.textGetter, useScores = _a.useScores, initialScoreType = _a.initialScoreType, scoreInfos = _a.scoreInfos;
    var _c = useContext(FilterContext), updateFilters = _c.updateFilters, getFilters = _c.getFilters;
    React.useEffect(function () {
        if (getFilters().scoreType === undefined) {
            updateFilters({ scoreType: initialScoreType });
        }
    }, [(_b = getFilters()) === null || _b === void 0 ? void 0 : _b.scoreType]);
    var components = useScoreCardComponents({
        textGetter: textGetter,
        scoreInfos: scoreInfos,
        useScores: useScores,
    });
    return _jsx(Stack, __assign({ gap: "s" }, { children: components.map(function (component) { return component; }) }));
}
function FilteredOverviewBase(_a) {
    var children = _a.children, title = _a.title, backToButton = _a.backToButton, secondaryHeadingTitle = _a.secondaryHeadingTitle;
    React.useEffect(function () {
        document.title = title;
    }, []);
    return (_jsxs(Box, __assign({ "data-testid": "FilteredOverviewPage" }, { children: [_jsx(SecondaryMainHeading, { backToButton: backToButton, "data-testid": "SecondaryHeading", secondaryHeadingTitle: secondaryHeadingTitle }), children] })));
}
export function TabbedFilteredOverviewPage(_a) {
    var tabs = _a.tabs, title = _a.title, backToButton = _a.backToButton, secondaryHeadingTitle = _a.secondaryHeadingTitle, availableFilters = _a.availableFilters, scoreCards = _a.scoreCards, filterClearer = _a.filterClearer;
    return (_jsx(FilteredOverviewBase, __assign({ title: title, backToButton: backToButton, secondaryHeadingTitle: secondaryHeadingTitle }, { children: _jsx(ControlledOverviewTabs, { tabs: tabs.map(function (tab) { return (__assign(__assign({}, tab), { content: (_jsxs(_Fragment, { children: [_jsxs(Stack, __assign({ className: styles.filters, direction: "vertical", flexGap: "l", gap: "l" }, { children: [_jsx(Filters, { availableFilters: availableFilters, clearFilters: filterClearer }), scoreCards] })), _jsx(Box, __assign({ sx: {
                                padding: "0 32px",
                            } }, { children: _jsx(_Fragment, { children: tab.content }) }))] })) })); }) }) })));
}
export function FilteredOverviewPage(_a) {
    var children = _a.children, title = _a.title, backToButton = _a.backToButton, scoreCards = _a.scoreCards, secondaryHeadingTitle = _a.secondaryHeadingTitle, availableFilters = _a.availableFilters, filterClearer = _a.filterClearer, rightTrailer = _a.rightTrailer;
    return (_jsxs(FilteredOverviewBase, __assign({ title: title, backToButton: backToButton, secondaryHeadingTitle: secondaryHeadingTitle }, { children: [_jsxs(Stack, __assign({ className: styles.filters, direction: "vertical", flexGap: "l" }, { children: [_jsx(Filters, { availableFilters: availableFilters, clearFilters: filterClearer, rightTrailer: rightTrailer }), scoreCards && _jsx("div", __assign({ className: styles.scoreCards }, { children: scoreCards }))] })), _jsx(Box, __assign({ sx: {
                    padding: "0 32px",
                } }, { children: _jsx(_Fragment, { children: children }) }))] })));
}
export default FilteredOverviewPage;
