export var Status;
(function (Status) {
    Status["Act"] = "act";
    Status["Watch"] = "watch";
    Status["Maintain"] = "maintain";
    Status["NoData"] = "nodata";
})(Status || (Status = {}));
export var ScoreTitles = {
    capability: "Leadership Capability",
    functional: "Business Impact: Functional",
    organizational: "Business Impact: Organizational",
    potential: "Leadership Potential",
};
export var ScoreDescriptions = {
    capability: "Current Leadership Skills",
    functional: "Role-specific past experiences & accomplishments",
    organizational: "Role-agnostic past experiences & accomplishments",
    potential: "Future Leadership Outlook",
};
export var SuccesionScoreDescriptions = {
    statement1: "of leaders have no path forward",
    statement2: "of leaders are in 3+ pipelines",
};
