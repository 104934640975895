var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var Component = function () { return (_jsx("svg", __assign({ width: "13", height: "8", viewBox: "0 0 13 8", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M11.6918 -3.64214e-08C12.5672 -2.26079e-08 13.0201 1.04528 12.4213 1.68394L7.22954 7.22183C6.83446 7.64324 6.16554 7.64324 5.77046 7.22183L0.578696 1.68394C-0.0200539 1.04528 0.432793 -2.14075e-07 1.30823 -2.00262e-07L11.6918 -3.64214e-08Z", fill: "#0B0C10" }) }))); };
export default Component;
