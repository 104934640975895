import { useNavigate } from "./navigate";
import { getRoute } from "../routes/getAllRoutes";
import { prepareEightfoldDomain, useGetClientDetailsQuery, } from "../store/api/core";
export var useEightfold = function () {
    var _a, _b;
    var navigate = useNavigate();
    var clientDetails = useGetClientDetailsQuery({});
    var eightfoldDomain = prepareEightfoldDomain((_b = (_a = clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.domain);
    var handleEightfoldUserNavigation = function (_a) {
        var ef_id = _a.ef_id;
        if (ef_id) {
            var url = "".concat(eightfoldDomain, "/careerhub/profile/").concat(ef_id);
            window.location.href = url;
        }
        else {
            navigate(getRoute("UserNotFound"));
        }
    };
    return {
        handleEightfoldUserNavigation: handleEightfoldUserNavigation,
    };
};
