import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/material";
var StyledCircle = styled("div")({
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "#F0FEFA",
    border: "1px solid #51AB93",
});
var CircleComponent = function () {
    return _jsx(StyledCircle, {});
};
export default CircleComponent;
