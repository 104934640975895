var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useGetClientDetailsQuery, updateClientDetailsQuery, useGetLeaderFiltersQuery, } from "../../store/api/core";
import SpinnerAndErrorer, { willHold, } from "../SpinnerAndErrorer/SpinnerAndErrorer";
import OverviewTabs from "../OveriewTabs/OverviewTabs";
import InfoBar from "../InfoBar/InfoBar";
import CheckBox from "../CheckBox/CheckBox";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import Table, { ValidSortDirections } from "../Table/Table";
var TabContent = function (tabProps) {
    var title = tabProps.title, locations = tabProps.locations, diversityOptions = tabProps.diversityOptions, diversityData = tabProps.diversityData, setDiversityData = tabProps.setDiversityData, tableHeaders = tabProps.tableHeaders;
    var TabBody = styled(Box)({
        margin: "32px",
    });
    var indexOfOption = function (optionsData, value) {
        return optionsData === null || optionsData === void 0 ? void 0 : optionsData.map(function (e) {
            return e.value;
        }).indexOf(value);
    };
    var onChange = function (country, selected) {
        var selectedValues = selected.map(function (index) { return diversityOptions[index].value; });
        var newDiversityData = JSON.parse(JSON.stringify(diversityData));
        var valueToUpdate = newDiversityData.find(function (diversity) { return diversity.country === country; });
        if (valueToUpdate && valueToUpdate.value) {
            valueToUpdate.value = selectedValues;
        }
        else {
            newDiversityData.push({
                country: country,
                value: selectedValues,
            });
        }
        setDiversityData(newDiversityData);
    };
    var formatters = [
        function (location) { return location; },
        function (diversity) {
            var _a;
            return (_jsx(Box, { children: _jsx(Dropdown, { options: diversityOptions, multiple: true, initial: ((_a = diversity === null || diversity === void 0 ? void 0 : diversity.value) === null || _a === void 0 ? void 0 : _a.map(function (value) {
                        return indexOfOption(diversityOptions, value);
                    })) || [], inactiveLabel: "All", onChangeMultiple: function (selected) {
                        onChange(diversity.country, selected);
                    }, renderCounter: function (options, selected) {
                        if (!options ||
                            options.length === 0 ||
                            !selected ||
                            selected.length === 0) {
                            return "None";
                        }
                        var firstSelected = options[selected[0]].label;
                        if (selected.length === 1) {
                            return firstSelected;
                        }
                        if (selected.length > 1) {
                            return "".concat(firstSelected, " +").concat(selected.length - 1);
                        }
                        return "None";
                    } }) }));
        },
    ];
    var tableData = locations.map(function (location) {
        return [
            location === null || location === void 0 ? void 0 : location.label,
            diversityData.find(function (diversity) { return diversity.country === location.value; }) || { country: location.value, value: [] },
        ];
    });
    return (_jsxs(TabBody, __assign({ sx: { "td:first-child": { width: "30%" } } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: { paddingBottom: "24px" } }, { children: title })), _jsx(Table, { showingInfo: function () {
                    return "".concat(locations.length, " location").concat(locations.length !== 1 ? "s" : "");
                }, data: tableData, formatters: formatters, headers: tableHeaders, defaultSortColumnIndex: 0, defaultSortDirection: ValidSortDirections.ASC, unsortableColumns: [1] })] })));
};
var DiversitySettings = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var clientDetailsResponse = useGetClientDetailsQuery({});
    var updateClientDetails = updateClientDetailsQuery({
        invalidates: [
            { queryKey: ["{dashboard}/{ClientId}/client-details"] },
            { queryKey: ["{dashboard}/{ClientId}/leaders"] },
        ],
    });
    var filterOptionsResponse = useGetLeaderFiltersQuery({});
    var _k = useState(false), dataLoaded = _k[0], setDataLoaded = _k[1];
    var _l = useState(((_a = clientDetailsResponse.data) === null || _a === void 0 ? void 0 : _a.data.hide_gender_and_ethnicity) || false), hideGenderAndEthnicity = _l[0], setHideGenderAndEthnicity = _l[1];
    var _m = useState(((_b = clientDetailsResponse.data) === null || _b === void 0 ? void 0 : _b.data.ethnic_diversity) || []), ethnicDiversity = _m[0], setEthnicDiversity = _m[1];
    var _o = useState(((_c = clientDetailsResponse.data) === null || _c === void 0 ? void 0 : _c.data.gender_diversity) || []), genderDiversity = _o[0], setGenderDiversity = _o[1];
    var buildOptions = function (data) {
        if (!data) {
            return [];
        }
        return data
            .filter(function (element) {
            return (element !== undefined &&
                element !== null &&
                typeof element !== "string");
        })
            .sort(function (a, b) { return a.value.localeCompare(b.value); })
            .map(function (item) { return ({ value: item.value, label: item.label }); });
    };
    var locationCountries = buildOptions((_d = filterOptionsResponse.data) === null || _d === void 0 ? void 0 : _d.data.location_2);
    var genderOptions = buildOptions((_e = filterOptionsResponse.data) === null || _e === void 0 ? void 0 : _e.data.gender);
    var ethnicityOptions = buildOptions((_f = filterOptionsResponse.data) === null || _f === void 0 ? void 0 : _f.data.ethnicity);
    // Remove any diversity data not matching the location country or dropdown options
    var removeBadDiversityData = function (diversityData, locationCountries, diversityOptions) {
        diversityData.forEach(function (data) {
            data.value = data.value.filter(function (value) {
                return diversityOptions.some(function (option) { return option.value === value; });
            });
        });
        return diversityData.filter(function (data) {
            if (data.country === "") {
                // Don't filter out the "All" option
                return true;
            }
            return locationCountries.some(function (country) { return country.value === data.country || data.country === ""; });
        });
    };
    if (!dataLoaded &&
        !willHold([clientDetailsResponse, filterOptionsResponse])) {
        setHideGenderAndEthnicity(((_g = clientDetailsResponse.data) === null || _g === void 0 ? void 0 : _g.data.hide_gender_and_ethnicity) || false);
        setEthnicDiversity(removeBadDiversityData(((_h = clientDetailsResponse.data) === null || _h === void 0 ? void 0 : _h.data.ethnic_diversity) || [], locationCountries, ethnicityOptions));
        setGenderDiversity(removeBadDiversityData(((_j = clientDetailsResponse.data) === null || _j === void 0 ? void 0 : _j.data.gender_diversity) || [], locationCountries, genderOptions));
        setDataLoaded(true);
    }
    var tabs = [{ label: "Ethnic Diversity" }, { label: "Gender Diversity" }];
    var data = [
        TabContent({
            title: "Ethnic Diversity",
            locations: locationCountries,
            diversityOptions: ethnicityOptions,
            diversityData: ethnicDiversity,
            setDiversityData: setEthnicDiversity,
            tableHeaders: ["Location", "Diverse Ethnicities"],
        }),
        TabContent({
            title: "Gender Diversity",
            locations: [{ label: "All", value: "" }],
            diversityOptions: genderOptions,
            diversityData: genderDiversity,
            setDiversityData: setGenderDiversity,
            tableHeaders: ["Location", "Diverse Genders"],
        }),
    ];
    var TopContainer = styled(Box)({
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        paddingBottom: "60px",
        margin: "0 32px",
    });
    var InfoBarContainer = styled(Box)({
        margin: "0 32px 24px 32px",
    });
    return (_jsxs(_Fragment, { children: [updateClientDetails.isError && (_jsx(InfoBarContainer, { children: _jsx(InfoBar, { content: "Unable to save changes", type: "disruptive" }) })), updateClientDetails.isSuccess && (_jsx(InfoBarContainer, { children: _jsx(InfoBar, { content: "Changes saved", type: "positive" }) })), _jsxs(SpinnerAndErrorer, __assign({ conditions: [clientDetailsResponse, filterOptionsResponse] }, { children: [_jsxs(TopContainer, __assign({ "data-testid": "DiversitySettings" }, { children: [_jsx(CheckBox, { label: "Hide ethnicity and gender data for individual leaders", value: "hide_gender_and_ethnicity", checked: hideGenderAndEthnicity, onChange: function (event) {
                                    setHideGenderAndEthnicity(event.target.checked);
                                } }), _jsx(Button, { text: "Save changes", loading: updateClientDetails.isPending, onClick: function () {
                                    updateClientDetails.mutate({
                                        hide_gender_and_ethnicity: hideGenderAndEthnicity,
                                        gender_diversity: genderDiversity,
                                        ethnic_diversity: ethnicDiversity,
                                    });
                                } })] })), _jsx(OverviewTabs, { tabs: tabs, data: data, selectedTab: 0, sx: { paddingTop: "0", width: "100%", margin: "0" } })] }))] }));
};
export default DiversitySettings;
