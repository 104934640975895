var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@mui/material";
import ActionCircle from "../ActionCircle/ActionCircle";
import { Status } from "../../common/action";
import styles from "./Legend.module.scss";
var LegendAction = function (_a) {
    var action = _a.action, displayName = _a.displayName, range = _a.range;
    return (_jsxs("div", __assign({ className: styles.actionContainer }, { children: [_jsx(ActionCircle, { size: "small", action: action }), _jsxs("div", __assign({ className: styles.actionText }, { children: [displayName, _jsx("span", __assign({ className: styles.actionScore }, { children: range }))] }))] })));
};
var Legend = function (_a) {
    var legendItems = _a.legendItems, descriptionPopup = _a.descriptionPopup, isAssessment = _a.isAssessment, isDescending = _a.isDescending;
    return (_jsx("div", __assign({ "data-testid": "Legend", className: styles.mainWrapper }, { children: _jsx("div", __assign({ className: styles.thresholdBox }, { children: _jsxs("div", __assign({ style: { width: "100%" }, className: styles.container }, { children: [_jsxs("div", __assign({ className: styles.thresholdWrapper }, { children: [_jsx("div", __assign({ style: { width: "20%" } }, { children: _jsxs("div", __assign({ className: styles.statusContainer }, { children: [_jsx("div", __assign({ className: styles.statusText }, { children: "Thresholds" })), descriptionPopup] })) })), _jsxs("div", __assign({ style: { display: "flex", width: "80%" } }, { children: [legendItems &&
                                        legendItems.map(function (legendItem, index) {
                                            var _a;
                                            return (_jsxs("div", __assign({ style: {
                                                    width: "".concat(isAssessment
                                                        ? ((legendItem.actualScoreMax -
                                                            legendItem.actualScoreMin) /
                                                            100) *
                                                            100
                                                        : ((legendItem.actualScoreMax -
                                                            legendItem.actualScoreMin) /
                                                            5) *
                                                            100, "%"),
                                                }, className: styles.legendBox }, { children: [_jsx(Box, { sx: {
                                                            borderRadius: isDescending
                                                                ? legendItem.action === "maintain"
                                                                    ? "4px 0px 0px 4px"
                                                                    : legendItem.action === "act" && "0 4px 4px 0"
                                                                : legendItem.action === "act"
                                                                    ? "4px 0px 0px 4px"
                                                                    : legendItem.action === "maintain" && "0 4px 4px 0",
                                                            height: "10px",
                                                            border: "1px solid ".concat(legendItem.action == "act"
                                                                ? "rgba(142, 62, 59, 0.75)"
                                                                : legendItem.action == "watch"
                                                                    ? "rgba(158, 95, 0, 0.75)"
                                                                    : "rgba(0, 102, 72, 0.75)"),
                                                            borderLeft: (isDescending &&
                                                                (legendItem.action === "watch" ||
                                                                    legendItem.action === "act")) ||
                                                                (!isDescending &&
                                                                    (legendItem.action === "watch" ||
                                                                        legendItem.action === "maintain"))
                                                                ? "none"
                                                                : undefined,
                                                            background: "".concat(legendItem.action == "act"
                                                                ? "#FFEFEF"
                                                                : legendItem.action == "watch"
                                                                    ? "#FFFFEB"
                                                                    : "#F0FEFA"),
                                                            paddingRight: "10px",
                                                            fontFamily: "Avenir Light",
                                                        } }), _jsx("span", __assign({ style: {
                                                            marginTop: "10px",
                                                            marginLeft: isDescending
                                                                ? legendItem.action == "maintain"
                                                                    ? "0"
                                                                    : "-8px"
                                                                : legendItem.action == "act"
                                                                    ? "0"
                                                                    : "-10px",
                                                        } }, { children: "".concat(isAssessment || isDescending
                                                            ? legendItem.actualScoreMin
                                                            : (_a = legendItem.actualScoreMin) === null || _a === void 0 ? void 0 : _a.toFixed(1)).concat(!isAssessment ? "" : "%") }))] }), "legendItem-".concat(index)));
                                        }), _jsxs("span", __assign({ className: styles.statusWrapper }, { children: [_jsx("span", { style: { height: "10px" } }), _jsx("span", __assign({ style: {
                                                    marginTop: "10px",
                                                    fontFamily: "Avenir Light",
                                                    marginLeft: isDescending || isAssessment ? "-36px" : "-20px",
                                                } }, { children: isDescending || isAssessment ? "100%" : "5.0" }))] }))] }))] })), _jsx("div", __assign({ className: styles.labelContainer }, { children: legendItems &&
                            legendItems.map(function (legendItem, index) { return (_jsxs(_Fragment, { children: [_jsx(LegendAction, { action: legendItem.action, displayName: legendItem.displayName, range: legendItem.range, actualScoreMin: legendItem.actualScoreMin, actualScoreMax: legendItem.actualScoreMax }, index), index !== legendItems.length - 1 && (_jsx("div", { className: styles.actionDivider }))] })); }) }))] })) })) })));
};
var toZeroDecimal = function (value) {
    return Math.round(value).toFixed(0).toString();
};
export var PercentageLegend = function (_a) {
    var _b;
    var scoreType = _a.scoreType, useThreshold = _a.useThreshold, descriptionPopup = _a.descriptionPopup, isDescending = _a.isDescending;
    var data = useThreshold({ scoreType: scoreType }).data;
    if (!(data === null || data === void 0 ? void 0 : data.data)) {
        return null;
    }
    var _c = ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.score_type_threshold) || [
        0, 0,
    ], low = _c[0], high = _c[1];
    return (_jsx("div", __assign({ className: styles.legendComponentWrapper }, { children: _jsx(Legend, { legendItems: [
                {
                    action: isDescending ? Status.Maintain : Status.Act,
                    displayName: isDescending ? "Maintain" : "Act",
                    range: "( \u2264 ".concat(toZeroDecimal(low), "% )"),
                    actualScoreMin: 0,
                    actualScoreMax: low,
                },
                {
                    action: Status.Watch,
                    displayName: "Watch",
                    range: "( > ".concat(toZeroDecimal(low), "; \u2264 ").concat(toZeroDecimal(high), "% )"),
                    actualScoreMin: low,
                    actualScoreMax: high,
                },
                {
                    action: isDescending ? Status.Act : Status.Maintain,
                    displayName: isDescending ? "Act" : "Maintain",
                    range: "( > ".concat(toZeroDecimal(high), "% )"),
                    actualScoreMin: high,
                    actualScoreMax: 100,
                },
            ], descriptionPopup: descriptionPopup, isAssessment: true, isDescending: isDescending }) })));
};
export default Legend;
