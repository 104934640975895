var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import BackToButton from "../../components/BackToButton";
import { LeaderListContent, getReadOnlyLeaderViewUrl, getTextOnlyPopupLeaderBox, getTextOnlyTableLeaderBox, SpecifiedNineBox, ViewOnlyInfoBar, } from "../../components/NineBox/NineBox";
import { useParams } from "react-router-dom";
import { useGetFilteredNineBox } from "../../store/api/core";
import { getRoute } from "../../routes/getAllRoutes";
import { TalentReviewNineBoxBase } from "../../pages/TalentReviewPage/TalentReviewNineBoxPage";
var NineBoxLeadersBase = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var getLeaderBoxForPopup = _a.getLeaderBoxForPopup, getLeaderBoxForTable = _a.getLeaderBoxForTable, getBackUrl = _a.getBackUrl, heading = _a.heading, getSeeAllLeadersUrl = _a.getSeeAllLeadersUrl, getSaverButtons = _a.getSaverButtons;
    var _h = useParams(), id = _h.id, assigned_box = _h.assigned_box;
    var assginedBoxIndex = String(Number(assigned_box) + 1);
    var nineBoxLeadersBox = useGetFilteredNineBox({
        id: id,
        assigned_box: assginedBoxIndex,
    });
    var boxName = (((_b = nineBoxLeadersBox.data) === null || _b === void 0 ? void 0 : _b.data) || {}).boxName;
    var assignedBoxName = (_d = (_c = nineBoxLeadersBox === null || nineBoxLeadersBox === void 0 ? void 0 : nineBoxLeadersBox.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.boxes[assigned_box].name;
    var boxLeadersCount = (_g = (_f = (_e = nineBoxLeadersBox.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.leaders) === null || _g === void 0 ? void 0 : _g.length;
    return (_jsx(_Fragment, { children: _jsx(TalentReviewNineBoxBase, __assign({ backToButton: _jsx(BackToButton, { title: boxName ? "".concat(boxName) : "", url: getBackUrl(id) }), secondaryHeadingTitle: assignedBoxName ? "".concat(assignedBoxName, " (").concat(boxLeadersCount, ")") : "" }, { children: _jsx(SpecifiedNineBox, { id: id, content: LeaderListContent, getLeaderBoxForTable: getLeaderBoxForTable, getSaverButtons: getSaverButtons, getLeaderBoxForPopup: getLeaderBoxForPopup, getSeeAllLeadersUrl: getSeeAllLeadersUrl, heading: heading }) })) }));
};
export var NineBoxLeadersReadOnly = function () { return (_jsx(NineBoxLeadersBase, { getLeaderBoxForPopup: getTextOnlyPopupLeaderBox, getLeaderBoxForTable: getTextOnlyTableLeaderBox, getBackUrl: function (id) {
        return getRoute("TalentReviewNineBoxRead").replace(":id", id);
    }, getSeeAllLeadersUrl: getReadOnlyLeaderViewUrl, getSaverButtons: function () { return []; }, heading: _jsx(ViewOnlyInfoBar, {}) })); };
var NineBoxLeaders = function () { return (_jsx(NineBoxLeadersBase, { getBackUrl: function (id) {
        return getRoute("TalentReviewNineBox").replace(":id", id);
    } })); };
export default NineBoxLeaders;
