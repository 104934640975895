import { useOktaAuth as realUseOktaAuth } from "@okta/okta-react";
import { isLiveEnvironment } from "../common/testHelpers";
/* eslint-disable @typescript-eslint/no-explicit-any */
export var useOktaAuth = isLiveEnvironment()
    ? realUseOktaAuth
    : function () { return ({
        oktaAuth: {
            isAuthenticated: function () { return undefined; },
            setOriginalUri: function () { return undefined; },
            signInWithRedirect: function () { return undefined; },
        },
    }); };
/* eslint-enable @typescript-eslint/no-explicit-any */
