// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oh0fmnLX5OOcqqOw9ruH {
  --tooltip-border-radius: 8px;
  --tooltip-background-color-dark: var(--grey-color-70);
  font-size: 14px;
}

._q7SHYqr_msISm4Ov2Us {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,qDAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".tooltip {\n  --tooltip-border-radius: 8px;\n  --tooltip-background-color-dark: var(--grey-color-70);\n  font-size: 14px;\n}\n\n.tooltipWrapper {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `oh0fmnLX5OOcqqOw9ruH`,
	"tooltipWrapper": `_q7SHYqr_msISm4Ov2Us`
};
export default ___CSS_LOADER_EXPORT___;
