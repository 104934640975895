var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { CircularProgress, Box, styled } from "@mui/material";
import RightArrow from "../RightArrow";
import { useNavigate } from "../../hooks/navigate";
import { getRoute } from "../../routes/getAllRoutes";
var CircleProgressBar = function (_a) {
    var percentage = _a.percentage, value = _a.value, descText = _a.descText, secondaryPageUrl = _a.secondaryPageUrl;
    var navigate = useNavigate();
    var size = 90;
    var borderWidth = 5;
    var handleDiverseOnClick = function (key) {
        navigate(getRoute(key));
    };
    var MainCircleWrapper = styled(Box)({
        position: "relative",
        width: size,
        height: size,
    });
    var Container = styled(Box)({
        padding: "12px 24px",
        borderRadius: "16px",
        background: "#F6F7F8",
    });
    var ProgressBarCenterText = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            lineHeight: "24px",
            color: "#1A212E",
            fontSize: "19px",
            letterSpacing: 1,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 800,
        });
    });
    var TextBox = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            fontFamily: theme.typography.fontFamily + " Book",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            textWrap: "nowrap",
        });
    });
    return (_jsx(Container, __assign({ "data-testid": "CircleProgressBar" }, { children: _jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsxs(MainCircleWrapper, { children: [_jsx(CircularProgress, { variant: "determinate", value: 100, size: size, thickness: borderWidth, sx: {
                                color: "#646A7C20",
                                position: "absolute",
                                top: 0,
                                left: 0,
                            } }), _jsx(CircularProgress, { variant: "determinate", value: percentage, size: size, thickness: borderWidth, sx: {
                                color: "#2C8CC9",
                                position: "absolute",
                                top: 0,
                                left: 0,
                            } }), _jsxs(ProgressBarCenterText, { children: [percentage, "%"] })] }), _jsxs(Box, __assign({ onClick: function () { return handleDiverseOnClick(secondaryPageUrl); }, sx: {
                        display: "flex",
                        marginLeft: "16px",
                        alignItems: "center",
                        cursor: "pointer",
                    } }, { children: [_jsxs(TextBox, { children: [value, " ", descText] }), _jsx(Box, { children: _jsx(RightArrow, {}) })] }))] })) })));
};
export default CircleProgressBar;
