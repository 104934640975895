// this is javascript so it can be used by devops and others

import routes from "./routes.json";

function getAllRoutesPrivate(start, result) {
  const at = start || routes;
  if (!(typeof at === "object" && !Array.isArray(at) && at !== null)) {
    return;
  }
  Object.keys(start).forEach((key) => {
    if (key == "url") {
      result.push(start[key]);
    } else {
      getAllRoutesPrivate(start[key], result);
    }
  });
}

export function getAllRoutes() {
  const found = [];
  getAllRoutesPrivate(routes, found);
  return found;
}

function appendPath(prefix, path) {
  return (
    path.includes("/#") ? path.replace("/#", `/${prefix}#`) : prefix + path
  ).replace("//", "/");
}

export function getRoute(value) {
  // this is here in case the routing table gets complicated and represented as a tree
  // eslint-disable-next-line no-undef
  const prefix = (window?.location?.hostname || "intelligence.test.host")
    .toLowerCase()
    .includes("intelligence")
    ? ""
    : "/dashboard";
  return appendPath(prefix, routes[value].url);
}
