var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useEightfold } from "../../../hooks/eightFold";
var LeaderName = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        fontFamily: "Avenir Heavy",
        fontSize: "16px",
        fontWeight: "400",
        color: theme.palette.primary.dark,
    });
});
var LeaderRole = styled(Box)({
    fontFamily: "Avenir",
    fontSize: "14px",
    color: "#69717F",
});
var colorRules = [
    { code: 0, background: "#993838" },
    { code: 1, background: "#9d6309" },
    { code: 2, background: "#a54d2c" },
    { code: 3, background: "#857600" },
    { code: 4, background: "#6d6f00" },
    { code: 5, background: "#2b715f" },
    { code: 6, background: "#0b7b8b" },
    { code: 7, background: "#146da6" },
    { code: 8, background: "#414996" },
    { code: 9, background: "#7e3a77" },
    { code: 10, background: "#943d71" },
    { code: 11, background: "#4f5666" },
];
var NameInitialsBubble = styled(Box)({
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
});
var getColorClassName = function (number) {
    var colorRule = colorRules.find(function (rule) { return rule.code == number; });
    return colorRule ? colorRule.background : "#4f5666";
};
var UpTo12Sha = function (input) { return __awaiter(void 0, void 0, void 0, function () {
    var textAsBuffer, hashBuffer, hashArray, fourCombos, threeCombos, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                textAsBuffer = new TextEncoder().encode(input);
                return [4 /*yield*/, window.crypto.subtle.digest("SHA-256", textAsBuffer)];
            case 1:
                hashBuffer = _a.sent();
                hashArray = Array.from(new Uint8Array(hashBuffer));
                fourCombos = hashArray[0] % 4;
                threeCombos = (hashArray[1] + (hashArray[2] << 8) + (hashArray[3] << 16)) % 3;
                return [2 /*return*/, threeCombos * 4 + fourCombos];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, NaN];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var NameColoredCircle = function (_a) {
    var data = _a.data;
    var _b = useState(-1), colorCode = _b[0], setColorCode = _b[1];
    var handleEightfoldUserNavigation = useEightfold().handleEightfoldUserNavigation;
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, UpTo12Sha("".concat(data === null || data === void 0 ? void 0 : data.firstName, " ").concat(data === null || data === void 0 ? void 0 : data.lastName))];
                    case 1:
                        result = _a.sent();
                        setColorCode(isNaN(result) ? -1 : result);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, []);
    return (_jsxs(Box, __assign({ sx: { display: "flex" }, "data-testid": "NameColoredCircle" }, { children: [_jsx(Box, { children: _jsxs(NameInitialsBubble, __assign({ sx: {
                        backgroundColor: colorCode == null ? "#4f5666" : getColorClassName(colorCode),
                    } }, { children: [data.firstName.charAt(0) || "", data.lastName.charAt(0) || ""] })) }), _jsxs(Box, __assign({ onClick: function () { return handleEightfoldUserNavigation({ ef_id: data.efId }); }, sx: {
                    cursor: "pointer",
                } }, { children: [_jsxs(LeaderName, { children: [data.firstName, " ", data.lastName] }), _jsx(LeaderRole, { children: data.roleName })] }))] })));
};
