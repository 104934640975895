var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, styled, useTheme } from "@mui/material";
import React, { useState } from "react";
import { BpCheckbox } from "../../hd-ui-kit/HDCheckBox/HDCheckBox";
import { Button, ButtonVariant } from "@eightfold.ai/octuple";
export var PopupFilter = function (_a) {
    var doFiltering = _a.doFiltering, startValues = _a.startValues, sx = _a.sx;
    var theme = useTheme();
    var _b = useState(startValues.pool), poolValue = _b[0], setPoolValue = _b[1];
    var _c = useState(startValues.plan), planValue = _c[0], setPlanValue = _c[1];
    var PopupContainer = styled("div")({
        display: "flex",
        flexDirection: "column",
        borderRadius: "16px",
        padding: "8px",
        boxShadow: "0px 2px 8px 0px #0F141F29",
        position: "absolute",
        width: "280px",
        height: "161px",
        background: theme.palette.background.default,
    });
    var CheckboxContainer = styled(Box)({
        display: "flex",
    });
    var OuterButtonContainer = styled(Box)({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
    });
    var ButtonContainer = styled(Box)({
        display: "flex",
        justifyContent: "space-between",
    });
    var Label = styled(Box)({
        zIndex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    });
    return (_jsxs(PopupContainer, __assign({ sx: sx }, { children: [_jsxs(CheckboxContainer, { children: [_jsx(BpCheckbox, { value: poolValue, onChange: function () {
                            setPoolValue(function (value) { return !value; });
                        } }), _jsx(Label, { children: "Role Plan" })] }), _jsxs(CheckboxContainer, { children: [_jsx(BpCheckbox, { value: planValue, onChange: function () {
                            setPlanValue(function (value) { return !value; });
                        } }), _jsx(Label, { children: "Position Plan" })] }), _jsx(Divider, { sx: { background: "#BEC2CA" } }), _jsx(OuterButtonContainer, { children: _jsxs(ButtonContainer, { children: [_jsx(Box, __assign({ sx: {
                                width: "65px",
                                height: "36px",
                            } }, { children: _jsx(Button, { text: "Reset", variant: ButtonVariant.Neutral, onClick: function () {
                                    setPlanValue(false);
                                    setPoolValue(false);
                                } }) })), _jsx(Box, __assign({ sx: {
                                width: "47px",
                                height: "36px",
                            } }, { children: _jsx(Button, { text: "OK", variant: ButtonVariant.Primary, onClick: function () {
                                    doFiltering({ plan: planValue, pool: poolValue });
                                } }) }))] }) })] })));
};
