var dayInMilliseconds = 24 * 60 * 60 * 1000;
export var getDisplayableTimestamp = function (timestamp) {
    var parsed = new Date(timestamp);
    var now = new Date();
    if (parsed == "Invalid Date") {
        return "";
    }
    var timed = new Date(timestamp);
    timed.setMinutes(timed.getMinutes() - timed.getTimezoneOffset());
    return (timed.toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    }) +
        (now.getTime() - parsed.getTime() >= dayInMilliseconds
            ? " - ".concat(timed.toLocaleDateString())
            : ""));
};
