var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var Component = function () { return (_jsxs("svg", __assign({ width: "26", height: "25", viewBox: "0 0 26 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M12.1318 3.01943C12.5156 2.34761 13.4843 2.34761 13.8682 3.01943L24.1451 21.0039C24.526 21.6705 24.0446 22.5 23.2768 22.5L2.72318 22.5C1.95536 22.5 1.47399 21.6705 1.85494 21.0039L12.1318 3.01943Z", stroke: "#C02195", "stroke-width": "1.5" }), _jsx("path", { d: "M13 8.5V11.5V16.5", stroke: "#C02195", "stroke-width": "1.5" }), _jsx("path", { d: "M13 18.5L13 19.5", stroke: "#C02195", "stroke-width": "1.5" })] }))); };
export default Component;
