var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Tooltip as OctupleTooltip, TooltipTheme } from "@eightfold.ai/octuple";
import styles from "./Tooltip.module.scss";
export var TooltipPlacement;
(function (TooltipPlacement) {
    TooltipPlacement["Top"] = "top";
    TooltipPlacement["Bottom"] = "bottom";
    TooltipPlacement["Left"] = "left";
    TooltipPlacement["Right"] = "right";
})(TooltipPlacement || (TooltipPlacement = {}));
var Tooltip = function (props) {
    return (_jsx("div", __assign({ "data-testid": "tooltip", className: props.parentClassName }, { children: _jsx(OctupleTooltip, __assign({ classNames: "".concat(styles.tooltip, " ").concat(props.className), animate: true, trigger: "hover", content: props.content, placement: props.placement || TooltipPlacement.Bottom, theme: TooltipTheme.dark, wrapperClassNames: styles.tooltipWrapper }, { children: props.children })) })));
};
export default Tooltip;
