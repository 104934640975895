// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gZHMi_rjMteRQ3vMymC_ {
  font-family: "Avenir Medium", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;
}
.gZHMi_rjMteRQ3vMymC_ p {
  margin: 0;
  font-family: "Avenir Book", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/routes/RequiredClientId/RequiredClientId.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;AACF;AACE;EACE,SAAA;EACA,sCAAA;AACJ","sourcesContent":[".clientErrorContainer {\n  font-family: \"Avenir Medium\", sans-serif;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin: 40px 0;\n\n  p {\n    margin: 0;\n    font-family: \"Avenir Book\", sans-serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientErrorContainer": `gZHMi_rjMteRQ3vMymC_`
};
export default ___CSS_LOADER_EXPORT___;
