import { useGetPermissions } from "./core";
export var PermissionResource;
(function (PermissionResource) {
    PermissionResource["ORG_DASHBOARD_UI"] = "ORG_DASHBOARD_UI";
    PermissionResource["ORG_DASHBOARD_API"] = "ORG_DASHBOARD_API";
    PermissionResource["PLATFORM_API"] = "PLATFORM_API";
})(PermissionResource || (PermissionResource = {}));
export var PermissionResourceType;
(function (PermissionResourceType) {
    PermissionResourceType["Application"] = "APPLICATION";
})(PermissionResourceType || (PermissionResourceType = {}));
export var hasPermissionExist = function (resourceName) {
    var permissions = useGetPermissions();
    return (Array.isArray(permissions) &&
        permissions.some(function (permission) { return (permission === null || permission === void 0 ? void 0 : permission.resourceName) === resourceName; }));
};
