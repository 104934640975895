var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useGetLeaderFiltersQuery } from "../../store/api/core";
import { getFilters, updateFilters } from "../../providers/FilterProvider";
import Dropdown from "../Dropdown/Dropdown";
import { Box } from "@mui/material";
import { useClient } from "../../hooks/client";
export var FilterResetEventName = "FilterResetEventName";
export var MultiSelectFilter = function (_a) {
    var _b, _c, _d, _e;
    var label = _a.label, entity = _a.entity, filterEntity = _a.filterEntity, sx = _a.sx, useOptionsQuery = _a.useOptionsQuery, filterRereader = _a.filterRereader;
    var _f = useState(), initial = _f[0], setInitial = _f[1];
    var client = useClient();
    var clientId;
    var filters = getFilters();
    if (filters.clientId) {
        clientId = filters.clientId;
    }
    else {
        clientId = client.clientId;
    }
    var optionsResponse = (useOptionsQuery || useGetLeaderFiltersQuery)({
        clientId: clientId,
        filters: filters,
    });
    var options = [];
    if ((_c = (_b = optionsResponse === null || optionsResponse === void 0 ? void 0 : optionsResponse.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[entity]) {
        options = (_e = (_d = optionsResponse === null || optionsResponse === void 0 ? void 0 : optionsResponse.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e[entity];
    }
    var getSelected = function () {
        var _a;
        if (((_a = getFilters()) === null || _a === void 0 ? void 0 : _a[filterEntity ? filterEntity : entity]) != null) {
            var filters_1 = getFilters()[filterEntity ? filterEntity : entity];
            var optionsByIndex_1 = options.map(function (option) { return option === null || option === void 0 ? void 0 : option.value; });
            var selected = filters_1
                .map(function (filter) { return optionsByIndex_1.indexOf(filter); })
                .filter(function (value) { return value >= 0; });
            return selected;
        }
    };
    var updateSelected = function () {
        setInitial(getSelected() || []);
    };
    useEffect(function () {
        if (filterRereader) {
            filterRereader.current = updateSelected;
        }
    }, [filterRereader]);
    useEffect(function () {
        window.addEventListener(FilterResetEventName, updateSelected);
        return function () {
            window.removeEventListener(FilterResetEventName, updateSelected);
        };
    }, []);
    var updateFiltersLocally = function (optionIndexes) {
        var _a;
        var selected = optionIndexes.map(function (index) { return options[index].value; });
        updateFilters((_a = {}, _a[filterEntity ? filterEntity : entity] = selected, _a));
    };
    useEffect(function () {
        setInitial(getSelected());
    }, [JSON.stringify(options), JSON.stringify(getSelected())]);
    return (_jsx(Box, __assign({ component: "span", sx: sx || {} }, { children: _jsx(Dropdown, { "data-testid": "".concat(entity, "-filter"), options: options, label: label, initial: initial == null ? getSelected() : initial, multiple: true, onChangeMultiple: updateFiltersLocally, loading: optionsResponse.isLoading }, "filter-".concat(entity)) })));
};
