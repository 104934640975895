import { useState, useEffect } from "react";
var getWindowDimensions = function () {
    var width = window.innerWidth, height = window.innerHeight;
    return {
        width: width,
        height: height,
    };
};
export var isOverflowed = function (child, parent) {
    var inner = child.getBoundingClientRect();
    var outer = parent.getBoundingClientRect();
    return (inner.left < outer.left ||
        inner.right > outer.right ||
        inner.bottom > outer.bottom ||
        inner.top < outer.top);
};
var useWindowDimensions = function () {
    var _a = useState(getWindowDimensions()), windowDimensions = _a[0], setWindowDimensions = _a[1];
    useEffect(function () {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return function () { return window.removeEventListener("resize", handleResize); };
    }, []);
    return windowDimensions;
};
export default useWindowDimensions;
